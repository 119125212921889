import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

/**
 * Runs a function once, when the component mounts.
 * @param child - a function that will be called once
 * @param [data] - This is the data that you want to check if it's empty or not.
 * @param [params=null] - This is the parameter that you want to pass to the child function.
 * @returns Nothing.
 */
export const useRunOneTime = (child, data = [], params = null) => {
  data = data || [];

  useEffect(() => {
    // if (data.length === 0)
    child(params);
  }, []);

  return;
};

/**
 * It's a function that takes in an id and a function as parameters, and then it displays a modal that
 * asks the user if they want to delete something. If they click yes, then it calls the function that
 * was passed in as a parameter.
 * @param id - the id of the item to be deleted
 * @param func - This is the function that will be called when the user clicks the "Yes, delete it!"
 * button.
 */
export const DeleteModal = (id, func) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      // console.log("deletemodal", id);
      func(id);
    }
  });
};

export const DisposeModal = (cb) => {
  Swal.fire({
    title: "Are you sure?",
    // text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Reopen",
  }).then((result) => {
    if (result.isConfirmed) {
      cb();
    }
  });
};

export const TextModal = (text) => {
  Swal.fire({
    text: text,
  });
};

export const HtmlModal = (data) => {
  Swal.fire({
    html: data,
  });
};

export const Notify = (type, message) => {
  type(message);
};

export const PopUpAlert = (message) => {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: message,
  });
};

export const deleteMsg = (msg) => {
  Swal.fire("Deleted!", `${msg}`, "success");
};

export const ShowErrorAlert = (message) => {
  Swal.fire({
    // icon: "error",
    title: "Oops...",
    text: message,
  });
};

export const ShowSuccessAlert = (message) => {
  Swal.fire({
    // icon: "success",
    title: "Success...",
    text: message,
  });
};

/**
 * It takes the current date, formats it to YYYY-MM-DD, and returns it.
 * @returns A string in the format of YYYY-MM-DD
 */
export const getTodayDate = () => {
  let d = new Date();
  let month = d.getMonth() < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  let date = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  let today = `${d.getFullYear()}-${month}-${date}`;
  return today;
};

/**
 * It takes a string, splits it into an array of words, then returns the last word in the array, in
 * lowercase
 * @param str - The string to be split.
 * @returns The last word in the string, in lowercase.
 */
export const splitString = (str) => {
  return str.split(" ").at(-1).toLowerCase();
};

/**
 * It takes a string, and returns a substring of the first 10 characters.
 * @param str - The string you want to get the date from.
 * @returns A string.
 */
export const getDatefromTimeStamp = (str) => {
  return str.slice(0, 10);
};

/**
 * It takes a string, reverses it, and returns the first 10 characters of the reversed string.
 * @param str - a string that is a timestamp
 * @returns A string.
 */
export const getDatefromTimeStampReversed = (str) => {
  let date = dateFormate(str.slice(0, 10));
  // let timeStamp = str.split(" ")[1];
  return `${date}`;
};

/**
 * It takes a string, splits it into an array, joins the array, splits it again, joins it again, trims
 * it, makes it lowercase, splits it again, and then returns the first or second element of the array.
 * @param str - the string to be formatted
 * @returns the first word of the string.
 */
export const removeUnderscore = (str) => {
  let typeArr = str
    .split("_")
    .join(" ")
    .split("-")
    .join(" ")
    .trim()
    .toLowerCase()
    .split(" ");

  if (typeArr.length === 2) return typeArr[0];
  else return typeArr[1];
};

/**
 * It returns a function that, when invoked, will wait for a specified amount of time before invoking
 * the original function
 * @param func - The function that will be executed after the delay.
 * @param delay - The delay in milliseconds.
 * @returns A function that takes in a function and a delay.
 */
export const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

/**
 * It returns the serial number of the current row based on the current page and the index of the row.
 * @param index - the index of the current row in the table
 * @param currentPage - The current page number
 * @returns The serial number of the item in the list.
 */
export const getSerialNumber = (index, currentPage) => {
  return (currentPage - 1) * 10 + index + 1;
};

/**
 * It takes a string, splits it into an array, reverses the order of the array, joins the array back
 * into a string, and returns the string.
 * @param str - The string to be formatted.
 * @returns A function that takes a string and returns a string.
 */
export const dateFormate = (str) => {
  return str.split("-").reverse().join("-");
};

/**
 * It creates an array of length `length` and then maps each element to a random color
 * @param length - The number of colors you want to generate.
 * @returns An array of random colors.
 */
export const getRandomColor = (length) => {
  return [...Array(length)].map(
    () => "#" + Math.random().toString(16).substr(-6)
  );
};

/**
 * It takes a string as an argument and returns a string
 * @param type - "dates"
 * @returns A function that takes a parameter and returns a string.
 */
export const getUrlByType = (type) => {
  // let a = ["document", "importantdate", "remarks", "stage", "dispose", "edit"];
  switch (type) {
    case "dates":
      return "importantdate";

    case "attachments":
      return "document";

    case "remarks":
      return "remarks";

    case "assigns":
      return "assign";

    default:
      return "assign";
  }
};

export const checkDateType = (arr) => {
  const presentType = ["critical date", "hearing date"];
  const filterarr = arr.map((type) => type.date_type_name);

  const result = presentType.filter(
    (item) => !filterarr.find((element) => element.toLowerCase() === item)
  );
  return result.length === 0 ? true : false;
};

export const checkAvailableDateType = (dateType, AllData) => {
  return dateType?.filter(
    (item) => !AllData?.find((element) => element?.date_type === item?.id)
  );
};

export const isReadNotification = (arr = []) => {
  return arr.reduce((sum, next) => sum || next.status, false);
};
