import React, { useEffect, useState } from "react";
import Button from "../../../Components/Button";
import InputField from "../../../Components/InputField";
import Dropdown from "../../../Components/Dropdown";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { formValidataion, initialobj } from "../addons/file";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import {
  casesList,
  courtList,
  petitionList,
  PetitionTypeAction,
} from "../../../Redux/Actions/globalUsage";
import { useRunOneTime } from "../../../Components/Notification";

const CreatePetition = () => {
  // Local States
  const [formData, setformData] = useState(initialobj);
  const [FieldError, setFieldError] = useState(initialobj);
  const [Focus, setFocus] = useState(false);

  // Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { createpetition } = bindActionCreators(petitionList, dispatch);

  const { fetchCasesList, fetchCourtApi, fetchPetitionType } =
    bindActionCreators(
      { ...courtList, ...casesList, ...PetitionTypeAction },
      dispatch
    );
  const { filterIsloading } = useSelector((state) => state.petitionLoader);

  // FOR GETTING DROPDOWN DATA FROM STORE
  const {
    cases: { caseslist },
    courtState: { courtlist },
    typeState: { petitionTypeData },
  } = useSelector((state) => state);

  const ChangeOfInput = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  const goBack = () => {
    navigate("/dashboard/petitionlist");
  };

  const inputFieldProps = {
    onchange: ChangeOfInput,
    className:
      "p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full",
  };

  const dropdownFieldProps = {
    HandleChange: ChangeOfInput,
    ClassName:
      "p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full",
  };

  const handleCreate = () => {
    setFieldError(formValidataion(formData));
    setFocus(true);
    if (Object.keys(formValidataion(formData)).length === 0) {
      createpetition(formData, navigate);
    }
  };

  useEffect(() => {
    if (Focus) {
      if (Object.keys(formValidataion(formData)).length !== null)
        setFieldError(formValidataion(formData));
    }
  }, [Focus, formData]);

  useRunOneTime(() => {
    fetchCasesList();
    fetchCourtApi();
    fetchPetitionType();
  });

  return (
    <div className="flex justify-center items-start py-2 bg-white/75 min-h-[calc(100vh-4rem)]">
      <div className="w-[95%] md:w-[60%] border border-gray-300 shadow-lg rounded-t-md rounded-b-md overflow-hidden">
        <div className="bg-gray-300 px-4 py-2 flex justify-between items-center">
          <h1 className="text-base  font-semibold text-[#296aff] ">
            New Petition
          </h1>
          <BsArrowLeftCircleFill
            className="text-[#296aff] text-2xl cursor-pointer"
            onClick={goBack}
          />
        </div>
        <div className="h-full bg-white">
          <div className="w-full grid lg:grid-cols-2 grid-cols-1 items-center gap-x-8 px-6 py-4">
            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                Petition Type
                <span className="text-red-600 font-semibold pl-1">*</span>
              </label>
              <Dropdown
                DefaultValue={"--Select Type--"}
                SetValue={formData.type}
                setName={"type"}
                {...dropdownFieldProps}
              >
                {petitionTypeData?.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Dropdown>
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {FieldError.type}
              </p>
            </div>

            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                Court<span className="text-red-600 font-semibold pl-1">*</span>
              </label>
              <Dropdown
                DefaultValue={"--Select Court--"}
                SetValue={formData.court_id}
                setName={"court_id"}
                {...dropdownFieldProps}
              >
                {courtlist?.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Dropdown>
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {FieldError.court_id}
              </p>
            </div>

            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                Case Type
                <span className="text-red-600 font-semibold pl-1">*</span>
              </label>
              <Dropdown
                DefaultValue={"--Select Case--"}
                SetValue={formData.case_id}
                setName={"case_id"}
                {...dropdownFieldProps}
              >
                {caseslist?.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Dropdown>
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {FieldError.case_id}
              </p>
            </div>

            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                Court Number
              </label>
              <InputField
                type={"text"}
                {...inputFieldProps}
                value={formData.court_num}
                name="court_num"
                placeholder="Enter Court No."
              />
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {FieldError.court_num}
              </p>
            </div>

            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                Stamp Number
                <span className="text-red-600 font-semibold pl-1">*</span>
              </label>
              <InputField
                type={"text"}
                {...inputFieldProps}
                value={formData.stamp_no}
                name="stamp_no"
                placeholder="Enter Stamp No.."
              />
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {FieldError.stamp_no}
              </p>
            </div>

            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                Petition Number
              </label>
              <InputField
                type={"text"}
                {...inputFieldProps}
                value={formData.num}
                name="num"
                placeholder="Enter Petition No."
              />
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {FieldError.num}
              </p>
            </div>

            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                Parent Petition Number
              </label>
              <InputField
                type={"text"}
                {...inputFieldProps}
                value={formData.par_num}
                name="par_num"
                placeholder="Enter Parent Petition No."
              />
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {FieldError.par_num}
              </p>
            </div>

            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium flex gap-1 pb-1">
                Petitioner Name{" "}
                <span className="text-red-600 font-semibold pl-1">*</span>
              </label>
              <InputField
                type={"text"}
                {...inputFieldProps}
                value={formData.name}
                name="name"
                placeholder="Enter  Petition Name"
              />
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {FieldError.name}
              </p>
            </div>

            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium flex gap-1 pb-1">
                Petitioner Advocate Name{" "}
                <span className="text-red-600 font-semibold pl-1">*</span>
              </label>
              <InputField
                type={"text"}
                {...inputFieldProps}
                value={formData.adv_name}
                name="adv_name"
                placeholder="Enter Petitioner Advocate Name"
              />
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {FieldError.adv_name}
              </p>
            </div>

            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium flex gap-1 pb-1">
                Petition Section Act{" "}
                <span className="text-red-600 font-semibold pl-1">*</span>
              </label>
              <InputField
                type={"text"}
                {...inputFieldProps}
                value={formData.sec_act}
                name="sec_act"
                placeholder="Enter Petition Section Act"
              />
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1 ">
                {FieldError.sec_act}
              </p>
            </div>
            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium flex gap-1 pb-1">
                Description
              </label>
              <textarea
                className="resize-none h-16 p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full"
                placeholder="Enter Description"
                value={formData.dsc}
                onChange={ChangeOfInput}
                name="dsc"
              ></textarea>
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {FieldError.dsc}
              </p>
            </div>
          </div>
          <div className="w-full px-6 py-2 flex md:justify-end gap-4">
            <Button
              type={"button"}
              text={"Create"}
              onclick={handleCreate}
              isloading={filterIsloading}
              className={
                "w-full flex justify-center items-center outline-none md:w-[10%] p-2 text-white min-w-fit font-medium rounded-md shadow-md bg-[#298aff] "
              }
            />

            <Button
              type={"button"}
              text={"Cancel"}
              onclick={goBack}
              className={
                "w-full outline-none md:w-[10%] bg-[#fa5151] p-2 min-w-fit text-white rounded-md shadow-md"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePetition;
