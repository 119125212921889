import { ActionTypes } from "../ActionCreater/ActionType";

const InitialObj = {
  isloading: false,
  roleData: [],
};

const ButtonLoader = {
  isloading: false,
};

export const RoleTypeReducer = (state = InitialObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_ROLE_TYPE_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.READ_ROLE_TYPE_LIST:
      return {
        ...state,
        isloading: false,
        roleData: payload,
      };

    default:
      return state;
  }
};

export const RoleTypeLoader = (state = ButtonLoader, { type }) => {
  switch (type) {
    case ActionTypes.POST_ROLE_TYPE_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.POST_ROLE_TYPE_SUCCESS:
      return {
        ...state,
        isloading: false,
      };

    default:
      return state;
  }
};
