import axios from "axios";
import axiosRetry from "axios-retry";
import { arrayofLocalstorage } from "../Redux/Actions/loginAction";

const { REACT_APP_BASEURL } = process.env;

const axiosInst = axios.create({
  baseURL: REACT_APP_BASEURL,
  headers: {
    "Content-Type": "application/json",
    Accept: " application/json",
  },
});

//sending credentials http only cookies stored in browser
// axiosInst.defaults.withCredentials = false;

// Intercepts each API request and adds Auth header
axiosInst.interceptors.request.use(async (request) => {
  const cookie = document.cookie;
  const token = cookie.split("=")[1];

  if (!cookie.length) {
    window.location = "/";
    arrayofLocalstorage?.map((item) => localStorage.removeItem(item));
  } else request.headers["Authorization"] = `Bearer ${token}`;

  // const jwtToken = localStorage.getItem("token");

  // if (jwtToken) {
  //   request.headers["Authorization"] = `Bearer ${jwtToken}`;
  // }
  return request;
});
axiosRetry(axiosInst, { retries: 3 });

async function callApi(url, method, data, config, headers) {
  try {
    // console.log(data);
    const options = {
      method,
      url,
      data: data,
      headers: headers,
      mode: "cors",
      config,
    };

    const response = await axiosInst(options);
    // console.log(options);
    return response.data;
  } catch (error) {
    // console.log("Error Details url, method, headers, body, error", {
    //   url,
    //   method,
    //   data,
    //   headers,
    //   error,
    //   config,
    // });
    throw error;
  }
}

export const GET = (path, headers = {}) => callApi(path, "GET", headers);
export const POST = (path, data, config = {}, headers = {}) =>
  callApi(path, "POST", data, config, headers);
export const PUT = (path, body, headers = {}) =>
  callApi(path, "PUT", body, headers);
export const DELETE = (path, body, headers = {}) =>
  callApi(path, "DELETE", body, headers);

export default axiosInst;
