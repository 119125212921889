import { ActionTypes } from "../ActionCreater/ActionType";

const InitialObj = {
  isloading: false,
  regionData: [],
};

const Loader = {
  isloading: false,
};

export const region = (state = InitialObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_REGION_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.READ_REGION_LIST:
      return {
        ...state,
        isloading: false,
        regionData: payload,
      };

    default:
      return state;
  }
};

export const RegionLoader = (state = Loader, { type }) => {
  switch (type) {
    case ActionTypes.POST_REGION_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.POST_REGION_SUCCESS:
      return {
        ...state,
        isloading: false,
      };

    default:
      return state;
  }
};
