import { combineReducers } from "redux";
import { AttachmentLoader, AttachmentReducer } from "./attachmentReducer";
import { ButtonLoader, CasesList } from "./casesListReducer";
import { caseStageLoader, CaseStageReducer } from "./caseStageReducer";
import { ButtonLoaderReducer, CourtListReducer } from "./courtListReducer";
import { loginReducer } from "./loginReducer";
import {
  filterBtn,
  petition,
  PetitionAssignedUser,
  petitionAttachment,
  petitionDate,
  PetitionLogReducer,
  petitionRemark,
  PetitionRoleReducer,
  PetitionStageredu,
  QuickAssignReducer,
  singlePetition,
  todayPetitionData,
  UnassignedList,
} from "./petitionReducer";
import { NotificationState } from "./notificationSetupReducer";
import { PetitionTypeReducer, TypeBtnLoader } from "./petitionTypeReducer";
import { region, RegionLoader } from "./regionReducer";
import { RoleTypeLoader, RoleTypeReducer } from "./roleTypeReducer";
import { UserLoader, UserReducer, WardReducer } from "./userListReducer";
import { BtnloaderReducer, DateTypeReducer } from "./dateTypeReducer";
import { notificationData } from "./notificationReducer";
import { forgotPassReducer } from "./forgotpasswordReducer";
import { dashboardCount } from "./dashCountReducer";
import { SearchReducer } from "./SearchReducer";
import { DisposeReducer } from "./DisposeReducer";
import { DropReducer } from "./DropReducer";
import { ReportReducer } from "./ReportReducer";
import { CheckListLoader, checkListReducer } from "./checkListReducer";
import { DetailCheckListReducer, DetailReducer } from "./DetailReducer";

const Reducers = combineReducers({
  loginState: loginReducer,
  forgotState: forgotPassReducer,

  userState: UserReducer,
  userBtnLoader: UserLoader,
  wardsState: WardReducer,

  cases: CasesList,
  buttonLoader: ButtonLoader,

  courtState: CourtListReducer,
  courtLoader: ButtonLoaderReducer,

  caseStageState: CaseStageReducer,
  stageLoader: caseStageLoader,

  dateTypeState: DateTypeReducer,
  dateTypeLoader: BtnloaderReducer,

  roleState: RoleTypeReducer,
  roleLoader: RoleTypeLoader,

  checkListLoader: CheckListLoader,
  checkListState: checkListReducer,

  attachmentState: AttachmentReducer,
  attachLoader: AttachmentLoader,

  regionState: region,
  regionLoader: RegionLoader,

  stickyNoteState: DetailReducer,
  checkedLists: DetailCheckListReducer,

  petitionState: petition,
  singlePetitionState: singlePetition,
  todayPetition: todayPetitionData,
  petitionLoader: filterBtn,
  petitionRemark: petitionRemark,
  p_attachment: petitionAttachment,
  p_date: petitionDate,
  quickAssign: QuickAssignReducer,
  QuickAssign: UnassignedList,
  petitionRoleState: PetitionRoleReducer,

  PetitionAssignedUserState: PetitionAssignedUser,

  petitionLog: PetitionLogReducer,

  typeState: PetitionTypeReducer,
  typeLoader: TypeBtnLoader,

  notificationState: NotificationState,

  notifyData: notificationData,

  // DashboardCounts
  dashboardCountState: dashboardCount,

  PetitionSearchState: SearchReducer,

  stageDataState: PetitionStageredu,

  DisposeState: DisposeReducer,

  DropDataState: DropReducer,

  // Reports
  ReportData: ReportReducer,
});

export default Reducers;
