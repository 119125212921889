import { ActionTypes } from "../ActionCreater/ActionType";

const DropObj = {
  loading: false,
  data: [],
};

export const DropReducer = (state = DropObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.DROP_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DROP_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
      };

    default:
      return state;
  }
};
