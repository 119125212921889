import { FaCalendarAlt, FaHourglassStart } from "react-icons/fa";

export const FilterObj = {
  case_stages: "",
  case: "",
  court: "",
  type: "",
  date_created_from: "",
  date_created_to: "",
};

export function DropDownValidate(object) {
  let Error = "";

  if (
    !Object.keys(object).length ||
    (object?.case_stages.length === 0 &&
      object?.case.length === 0 &&
      object?.court.length === 0 &&
      object?.court.length === 0 &&
      object?.type.length === 0 &&
      object?.date_created_from.length === 0 &&
      object?.date_created_to.length === 0)
  ) {
    Error = "Please Select one DropDown";
  }

  return Error;
}

export const nav_item = [
  { title: "Drill down", route: "index" },
  { title: "Pending Dashboard", route: "pendingdashboard" },
  { title: "Dispose Dashboard", route: "dispose" },
  { title: "Alert", route: "alert" },
];

export const cardData = [
  {
    id: 1,
    count: 0,
    title: "Today entered petition count",
    icon: <FaHourglassStart className="text-4xl" />,
    style: "bg-[#396AFF] hover:bg-blue-600",
  },
  {
    id: 2,
    count: 0,
    title: "All petitions count",
    icon: <FaCalendarAlt className="text-4xl" />,
    style: "bg-[#DF54DB] hover:bg-[#ce20f5]",
  },
  {
    id: 3,
    count: 0,
    title: "Pending petitions count",
    icon: <FaHourglassStart className="text-4xl" />,
    style: "bg-[#FF5AA3] hover:bg-pink-500",
  },
  {
    id: 4,
    count: 0,
    title: "Dispose petitions count",
    icon: <FaHourglassStart className="text-4xl" />,
    style: "bg-[#01E023] hover:bg-green-500",
  },
];
