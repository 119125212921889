import React, { useEffect, useState } from "react";
import PieChart from "../common/PieChart";
import { FaArrowCircleDown } from "react-icons/fa";
import Dropdown from "../../../../Components/Dropdown";
import BarChart from "../common/BarChart";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { Reports } from "../../../../Redux/Actions/globalUsage";
import CardComponent from "../common/CardComponent";

const Pendingdashboard = () => {
  const [chartData, setChartData] = useState({ label: [], data: [] });
  const [tableData, settableData] = useState([]);
  const inititalformData = {
    value: "CourtType",
  };
  const [formData, setformData] = useState(inititalformData);

  const dispatch = useDispatch();
  const { chartDataAction } = bindActionCreators(Reports, dispatch);

  const ChangeOfInput = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  const dropdownFieldProps = {
    HandleChange: ChangeOfInput,
    ClassName:
      "p-[0.6rem] outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full",
  };

  const {
    ReportData: { chartsApiData },
  } = useSelector((state) => state);

  useEffect(() => {
    chartDataAction(formData);

    // eslint-disable-next-line
  }, [formData]);

  useEffect(() => {
    const fetchPrices = () => {
      const data = chartsApiData;
      setChartData((prev) => ({
        ...prev,
        label: data.map((item) => item?.name),
        data: data.map((item) => item?.petitions_count),
      }));
    };
    if (chartsApiData !== null) fetchPrices();
  }, [chartsApiData]);

  useEffect(() => {
    const data = [tableData];
    setChartData((prev) => ({
      ...prev,
      label: data.map((item) => item?.name),
      data: data.map((item) => item?.petitions_count),
    }));
  }, [tableData]);

  return (
    <div className="lg:p-5 py-3 px-2">
      {/* CARD SECTION */}
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-2">
        <CardComponent setterFunction={settableData} />
      </div>

      {/* FILTER SECTION */}
      <div className="py-5 flex w-full  gap-5">
        <div className="flex flex-col w-full lg:w-1/2">
          <Dropdown
            DefaultValue={"--Select Filter--"}
            SetValue={formData.value}
            setName={"value"}
            {...dropdownFieldProps}
          >
            <option value={"CourtType"}>Court Type</option>
            <option value={"CaseType"}>Case Type</option>
            <option value={"PetitionType"}>PetitionType</option>
            {/* <option value={"Status"}>Petition Status</option> */}
          </Dropdown>
        </div>
      </div>

      {/* CHART CONTAINER */}
      <div className="grid lg:grid-cols-6 grid-cols-1 gap-5">
        {/* PIE Chart SECTION */}
        <div className="bg-white rounded-lg w-full shadow-lg lg:col-span-2">
          {/* HEADING SECTION */}
          <div className="rounded-t-lg bg-slate-100 border-b-2 border-slate-500">
            <h2 className="flex items-center gap-2 p-3">
              <FaArrowCircleDown className="text-blue-500" />
              Data in Pie chart
            </h2>
          </div>

          {/* CONSTENT SECTION */}
          <div className="pb-3 flex justify-center items-center">
            <PieChart data={chartData} />
          </div>
        </div>

        {/* BAR Chart SECTION */}
        <div className="bg-white rounded-lg w-full shadow-lg lg:col-span-4">
          {/* HEADING SECTION */}
          <div className="rounded-t-lg bg-slate-100 border-b-2 border-slate-500">
            <h2 className="flex items-center gap-2 p-3">
              <FaArrowCircleDown className="text-blue-500" />
              Data in bar chart
            </h2>
          </div>

          {/* CONSTENT SECTION */}
          <div className="pb-3 flex justify-center items-center">
            <BarChart data={chartData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pendingdashboard;
