import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = ({ allowedRoles, allowedParentRoles }) => {
  const location = useLocation();

  const Roles = [localStorage.getItem("role")];
  const parent_role = [localStorage.getItem("parent_role") || 0];

  return Roles?.find((role) => allowedRoles?.includes(role)) ||
    parent_role?.find((role) => allowedParentRoles?.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate to="denied" state={{ from: location }} replace />
  );
};

export default ProtectedRoutes;
