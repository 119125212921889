import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import CustomStyles, {
  paginationComponentOptions,
} from "../../../../Components/datatable";
import Dropdown from "../../../../Components/Dropdown";
import InputField from "../../../../Components/InputField";
import Loader2 from "../../../../Components/Loader2";
import {
  dateFormate,
  getSerialNumber,
  HtmlModal,
  removeUnderscore,
  splitString,
} from "../../../../Components/Notification";
import { petitionList } from "../../../../Redux/Actions/globalUsage";
import visibleIcon from "../../../../Assets/visible.png";
import { useState } from "react";

const PetitionLogs = () => {
  const [filterLog, setfilterLog] = useState("");
  const [currentPage, setcurrentPage] = useState(1);

  const { id } = useParams();
  const dispatch = useDispatch();
  const { loadPetitionLog } = bindActionCreators(petitionList, dispatch);

  const { data } = useSelector((state) => state.petitionLog);

  const { filterIsloading } = useSelector((state) => state.petitionLoader);

  const PetitionLogCols = [
    {
      name: "Sr no.",
      selector: (_, i) => getSerialNumber(i, currentPage),
      width: "90px",
      sortable: true,
    },
    {
      name: "User Name",
      selector: (row) => row.user_id_name,
      width: "15rem",
      sortable: true,
    },
    {
      name: "Type",
      cell: (row) => typeUi(removeUnderscore(row.log_type)),
      width: "15rem",
      sortable: true,
    },
    {
      name: "Description",
      cell: (row) => (
        <>
          <div
            className="w-full px-2 text-justify"
            dangerouslySetInnerHTML={{
              __html: row.log_details,
            }}
          ></div>
        </>
      ),
      // width: "926px",
      wrap: false,
      sortable: true,
    },
    {
      name: "Created On",
      selector: (row) => dateFormate(row.log_date),
      width: "15rem",
      sortable: true,
    },
  ];

  const typeUi = (log_type) => {
    return (
      <div
        className={` ${
          log_type === "assigns"
            ? "bg-green-500"
            : log_type === "petitions"
            ? "bg-red-500"
            : log_type === "dates"
            ? "bg-orange-500"
            : log_type === "remarks"
            ? "bg-purple-500"
            : "bg-blue-500"
        } p-2 min-w-[8rem] text-white uppercase  rounded-md`}
      >
        <p className="text-center">{log_type}</p>
      </div>
    );
  };

  // const LogsUI = (data) => {
  //   return data?.length < 80 ? (
  //     <>
  //       <div
  //         className="w-full px-2 text-justify"
  //         dangerouslySetInnerHTML={{
  //           __html: data.substring(0, 80),
  //         }}
  //       />
  //     </>
  //   ) : (
  //     <>
  //       <div className="flex lg:w-1/2 justify-center">
  //         <div
  //           dangerouslySetInnerHTML={{
  //             __html: data.substring(0, 80),
  //           }}
  //         />
  //         <span>...</span>
  //       </div>
  //       <div className="pl-2">
  //         {data?.length < 80 ? null : (
  //           <div
  //             className="bg-[#296aff] p-1 rounded-full outline-none cursor-pointer"
  //             onClick={() => {
  //               ExpandData(data);
  //             }}
  //           >
  //             <img src={visibleIcon} alt="" />
  //           </div>
  //         )}
  //       </div>
  //     </>
  //   );
  // };

  // const ExpandData = (data) => {
  //   HtmlModal(data);
  // };

  useEffect(() => {
    loadPetitionLog(id);
  }, []);

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  return (
    <div
      className="px-4 pt-5 overflow-hidden bg-white/75"
      style={{ height: `calc(100vh - 10.5rem)` }}
    >
      <div className="w-full p-2">
        <h2 className="font-bold text-2xl">Petition Log List</h2>
      </div>
      <div className="w-full p-2">
        {filterIsloading ? (
          <div className="flex justify-center items-center">
            <Loader2 />
          </div>
        ) : (
          <DataTable
            columns={PetitionLogCols}
            customStyles={CustomStyles}
            paginationComponentOptions={paginationComponentOptions}
            data={
              filterLog.length === 0
                ? data
                : data.filter(
                    (item) => removeUnderscore(item.log_type) === filterLog
                  )
            }
            pagination
            onChangePage={handlePageChange}
            fixedHeader
            fixedHeaderScrollHeight="42vh"
            subHeader
            subHeaderComponent={
              <>
                <div className="w-full flex lg:flex-row flex-col gap-2 justify-between py-2">
                  <Dropdown
                    ClassName="focus:outline-none border py-2 capitalize px-4 border-gray-500 text-gray-500 w-auto rounded-md"
                    DefaultValue={"--Select Filter--"}
                    HandleChange={(e) => setfilterLog(e.target.value)}
                  >
                    <option value={""}>All</option>
                    <option>assigns</option>
                    <option>petitions</option>
                    <option>attachments</option>
                    <option>remarks</option>
                    <option>dates</option>
                  </Dropdown>
                  {/* <InputField
                    className={
                      "w-auto outline-none border border-gray-500 rounded-md p-2 focus:outline-none text-gray-500"
                    }
                    placeholder={"Search..."}
                  /> */}
                </div>
              </>
            }
          />
        )}
      </div>
    </div>
  );
};

export default PetitionLogs;
