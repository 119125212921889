import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlinePlus } from "react-icons/ai";
import { MdModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import ActiveComponents from "../../../../Components/ActiveComponents";
import Button from "../../../../Components/Button";
import CustomStyles, {
  paginationComponentOptions,
} from "../../../../Components/datatable";
import Dropdown from "../../../../Components/Dropdown";
import Loader2 from "../../../../Components/Loader2";
import Modal from "../../../../Components/modal/Modal";
import { getSerialNumber } from "../../../../Components/Notification";
import {
  caseStageList,
  petitionList,
} from "../../../../Redux/Actions/globalUsage";
import { initialStage, stageValidataion } from "../../addons/file";

const Stage = () => {
  const [isOpen, setisOpen] = useState(false);
  const [formData, setformData] = useState(initialStage);
  const [formError, setformError] = useState({});
  const [focus, setfocus] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [stage_id, setstage_id] = useState("");
  const [editModal, seteditModal] = useState(false);

  const { id } = useParams();

  const dispatch = useDispatch();

  const { fetchCaseStage } = bindActionCreators(caseStageList, dispatch);

  const {
    caseStageState: { caseStageData },
    quickAssign: { loading },
  } = useSelector((state) => state);

  const { CreateStage, loadStage, UpdateStage } = bindActionCreators(
    petitionList,
    dispatch
  );

  const { data: singlePetitionData } = useSelector(
    (state) => state.singlePetitionState
  );

  const { data } = useSelector((state) => state.stageDataState);

  //COLUMNS FOR THE REMARKS TABLE
  const column = [
    {
      name: "Sr no.",
      selector: (row, i) => getSerialNumber(i, currentPage),
      sortable: true,
    },
    {
      name: "Petition Stage",
      selector: (row) => row.case_stage_name,
      sortable: true,
    },
    {
      name: "Remarks",
      selector: (row) => row.remark,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => ActionUI(row),
    },
  ];

  // FUNCTION TO DISPLAY  EDIT AND DELETE BUTTON IN DATA TABLE
  const ActionUI = (row) => {
    return (
      <>
        <div className="flex gap-x-2">
          <ActiveComponents active={singlePetitionData?.active}>
            <button
              onClick={() => {
                setstage_id(row.id);
                seteditModal(true);
              }}
              type={"button"}
              className="p-2 bg-blue-500 rounded-full text-white"
            >
              <MdModeEdit title="Edit Assigned Petition" className="w-4 h-4" />
            </button>
          </ActiveComponents>
        </div>
      </>
    );
  };

  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
      petition_id: id,
    });
  };

  const ChangeOfInput = (e) => {
    const { value } = e.target;
    setformData({ ...formData, case_stage_type: value });
  };

  const dropdownFieldProps = {
    HandleChange: ChangeOfInput,
    ClassName:
      "p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full",
  };

  const handleSubmit = () => {
    setformError(stageValidataion(formData));
    setfocus(true);

    if (Object.keys(stageValidataion(formData)).length === 0) {
      CreateStage(formData, () => {
        loadStage(id);
        setfocus(false);
        setformData(initialStage);
        setisOpen(false);
      });
    }
  };

  const handleEdit = () => {
    setformError(stageValidataion(formData));
    setfocus(true);

    if (Object.keys(stageValidataion(formData)).length === 0) {
      UpdateStage(formData, stage_id, () => {
        loadStage(id);
        setfocus(false);
        setformData(initialStage);
        seteditModal(false);
      });
    }
  };
  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    if (editModal) {
      let singleData = data.filter((item) => item.id === stage_id);
      console.log("stage", singleData);
      setformData(singleData[0]);
    } else {
      setformData(initialStage);
    }
  }, [editModal]);

  useEffect(() => {
    if (focus) {
      if (Object.keys(stageValidataion(formData)).length !== null)
        setformError(stageValidataion(formData));
    }
  }, [focus, formData]);

  useEffect(() => {
    fetchCaseStage();
    loadStage(id);
  }, []);

  return (
    <div className="flex flex-col items-start py-2 bg-white/75 min-h-[calc(100vh-10.5rem)]">
      {/* CREATE MODAL */}
      <Modal isOpen={isOpen} setisOpen={setisOpen}>
        <div className=" w-full h-full space-y-5">
          <h3 className="font-bold text-xl rounded-t-md w-full bg-gray-300 text-[#296aff]  p-3 ">
            Add Stage
          </h3>

          <div className="flex flex-col p-5">
            <label className="text-sm text-gray-800 font-medium block pb-1">
              Petition Stage
              <span className="text-red-600 font-semibold pl-1">*</span>
            </label>
            <Dropdown
              DefaultValue={"--Select Stage--"}
              setName={"case_stage_type"}
              {...dropdownFieldProps}
            >
              {caseStageData?.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </Dropdown>
            <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
              {formError.case_stage_type}
            </p>
          </div>

          <div className="flex flex-col p-5">
            <label className=" text-gray-800 font-medium text-base flex gap-1 pb-1">
              Stage Remarks
              <span className="text-red-600 font-semibold pl-1">*</span>
            </label>
            <textarea
              className="resize-none h-24 p-2 outline-none border border-gray-500 rounded-sm text-gray-500  text-base w-full"
              placeholder="Enter Remarks"
              value={formData.remark}
              onChange={handleChange}
              name="remark"
            ></textarea>
            <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
              {formError.remark}
            </p>
          </div>

          <div className="flex justify-end px-5 py-3">
            <Button
              type={"button"}
              text={"Submit"}
              isloading={loading}
              onclick={handleSubmit}
              className={
                "w-full flex justify-center items-center outline-none md:w-[20%] p-2 text-white min-w-fit font-medium rounded-md shadow-md bg-[#298aff] "
              }
            />
          </div>
        </div>
      </Modal>
      {/* Update MODAL */}
      <Modal isOpen={editModal} setisOpen={seteditModal}>
        <div className=" w-full h-full space-y-5">
          <h3 className="font-bold text-xl rounded-t-md w-full bg-gray-300 text-[#296aff]  p-3 ">
            Update Stage
          </h3>

          <div className="flex flex-col p-5">
            <label className="text-sm text-gray-800 font-medium block pb-1">
              Petition Stage
              <span className="text-red-600 font-semibold pl-1">*</span>
            </label>
            <Dropdown
              DefaultValue={"--Select Stage--"}
              setName={"case_stage_type"}
              SetValue={formData.case_stage_type}
              {...dropdownFieldProps}
            >
              {caseStageData?.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </Dropdown>
            <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
              {formError.case_stage_type}
            </p>
          </div>

          <div className="flex flex-col p-5">
            <label className=" text-gray-800 font-medium text-base flex gap-1 pb-1">
              Stage Remarks
              <span className="text-red-600 font-semibold pl-1">*</span>
            </label>
            <textarea
              className="resize-none h-24 p-2 outline-none border border-gray-500 rounded-sm text-gray-500  text-base w-full"
              placeholder="Enter Remarks"
              value={formData.remark}
              onChange={handleChange}
              name="remark"
            ></textarea>
            <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
              {formError.remark}
            </p>
          </div>

          <div className="flex justify-end px-5 py-3">
            <Button
              type={"button"}
              text={"Submit"}
              isloading={loading}
              onclick={handleEdit}
              className={
                "w-full flex justify-center items-center outline-none md:w-[20%] p-2 text-white min-w-fit font-medium rounded-md shadow-md bg-[#298aff] "
              }
            />
          </div>
        </div>
      </Modal>
      {/* HEADER FOR THE REMARKS */}
      <div className="flex justify-between w-full p-5">
        <h2 className="font-bold text-2xl">Petition Stage</h2>
        {/* <ActiveComponents active={singlePetitionData?.active}>
          <button
            onClick={() => {
              setisOpen(true);
            }}
            className="outline-none capitalize flex items-center text-white bg-gradient-to-r from-[#1f9fff] to-[#1f4fdf] rounded-[10px] py-2 px-4"
          >
            <AiOutlinePlus className=" text-xl" /> Add
          </button>
        </ActiveComponents> */}
      </div>

      <div className="w-full p-5">
        {false ? (
          <div className="flex justify-center items-center ">
            <Loader2 />
          </div>
        ) : (
          <DataTable
            columns={column}
            customStyles={CustomStyles}
            data={data}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            fixedHeader
            fixedHeaderScrollHeight="50vh"
            onChangePage={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default Stage;
