import { ActionTypes } from "../ActionCreater/ActionType";

const InitialObj = {
  isloading: false,
  attachmentData: [],
};

const ButtonLoader = {
  isloader: false,
};

export const AttachmentReducer = (state = InitialObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_ATTACHMENT_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.READ_ATTACHMENT:
      return {
        ...state,
        isloading: false,
        attachmentData: payload,
      };

    default:
      return state;
  }
};

export const AttachmentLoader = (state = ButtonLoader, { type }) => {
  switch (type) {
    case ActionTypes.POST_ATTACHMENT_REQUEST:
      return {
        ...state,
        isloader: true,
      };

    case ActionTypes.POST_ATTACHMENT_SUCCESS:
      return {
        ...state,
        isloader: false,
      };

    default:
      return state;
  }
};
