import {
  ShowErrorAlert,
  ShowSuccessAlert,
} from "../../Components/Notification";
import { DELETE, GET, POST, PUT } from "../../Utils/Helper";
import { ActionTypes } from "../ActionCreater/ActionType";

// function to send fetch request
const fetchRequest = () => {
  return {
    type: ActionTypes.FETCH_ATTACHMENT_REQUEST,
  };
};

// function to pass data after successfull request
const fetchSuccess = (data) => {
  return {
    type: ActionTypes.READ_ATTACHMENT,
    payload: data,
  };
};

// function to set Button Loader to True
const postRequest = () => {
  return {
    type: ActionTypes.POST_ATTACHMENT_REQUEST,
  };
};

// function to set Button Loader to False
const postSuccess = () => {
  return {
    type: ActionTypes.POST_ATTACHMENT_SUCCESS,
  };
};

// Thunk function to fetch Attachments
export const fetchAttachments = () => {
  return async (dispatch) => {
    dispatch(fetchRequest());
    await GET("superAdmin/attachmentType")
      .then((res) => {
        dispatch(fetchSuccess(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};

// function to Create new Attachment Type
export const PostAttachment = (obj, navigate) => {
  return async (dispatch) => {
    dispatch(postRequest());
    await POST("superAdmin/attachmentType/create", obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchAttachments());
          ShowSuccessAlert(res.message);
          navigate("/dashboard/attachments");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => {
        dispatch(postSuccess());
      });
  };
};

// function to SingleRead Attachment
export const SingleReadAttachment = (id, updateMethod) => {
  return async (dispatch) => {
    dispatch(postRequest());
    await GET(`superAdmin/attachmentType/show/${id}`)
      .then((res) => {
        updateMethod(res.data);
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.message)))
      .finally(() => dispatch(postSuccess()));
  };
};

// function to update Attachment Data
export const UpdateAttachmentData = (id, obj, navigate) => {
  return async (dispatch) => {
    dispatch(postRequest());
    await PUT(`superAdmin/attachmentType/update/${id}`, obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchAttachments());
          ShowSuccessAlert(res.message);
          navigate("/dashboard/attachments");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => {
        dispatch(postSuccess());
      });
  };
};

// function to Delete Attachment Data
export const DeleteAttachmentData = (id) => {
  return async (dispatch) => {
    await DELETE(`superAdmin/attachmentType/delete/${id}`)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchAttachments());
          ShowSuccessAlert(res.message);
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};
