import React from "react";

const Card = ({ count, icon, title, style, HandleClick }) => {
  return (
    <div
      className={`${style} flex justify-between items-center rounded-md px-3 py-5 text-white cursor-pointer`}
    >
      <div onClick={HandleClick}>
        <div className="text-2xl font-bold gap-1">
          <h2>{count}</h2>
        </div>
        <p>{title}</p>
      </div>
      {icon}
    </div>
  );
};

export default Card;
