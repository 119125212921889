import {
  ShowErrorAlert,
  ShowSuccessAlert,
} from "../../Components/Notification";
import { DELETE, GET, POST, PUT } from "../../Utils/Helper";
import { ActionTypes } from "../ActionCreater/ActionType";

// function to send fetch request
const fetchRequest = () => {
  return {
    type: ActionTypes.FETCH_CASE_REQUEST,
  };
};

// function to pass data after successfull fetching
const fetchSuccess = (casesarray) => {
  return {
    type: ActionTypes.READ_CASE_LIST,
    payload: casesarray,
  };
};

// function to set button loader to true
const postRequest = () => {
  return {
    type: ActionTypes.POST_CASE_REQUEST,
  };
};

// function to set Button Loader to false
const postSuccess = () => {
  return {
    type: ActionTypes.POST_CASE_SUCCESS,
  };
};

// Thunk function to fetch Cases List
export const fetchCasesList = () => {
  return async (dispatch) => {
    dispatch(fetchRequest());
    await GET("superAdmin/caseType")
      .then((res) => {
        dispatch(fetchSuccess(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};

// Function to fetch Single Cases Data
export const fetchSingleRead = (id, setupdateState) => {
  return async (dispatch) => {
    dispatch(postRequest());
    await GET(`superAdmin/caseType/show/${id}`)
      .then((res) => {
        setupdateState(res.data);
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.message)))
      .finally(() => dispatch(postSuccess()));
  };
};

// Function to Update Cases Data
export const UpdateSingleCase = (id, obj, navigate) => {
  return async (dispatch) => {
    dispatch(postRequest());
    await PUT(`superAdmin/caseType/update/${id}`, obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchCasesList());
          ShowSuccessAlert(res.message);
          navigate(localStorage.getItem("path_name"));
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => dispatch(postSuccess()));
  };
};

// Function to Create Case Data
export const postCaseList = (obj, navigate) => {
  return async (dispatch) => {
    dispatch(postRequest());
    await POST("superAdmin/caseType/create", obj)
      .then((res) => {
        if (res.statusCode === 200) {
          ShowSuccessAlert(res.message);
          dispatch(fetchCasesList());
          navigate("/dashboard/caselist");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => {
        dispatch(postSuccess());
      });
  };
};

// Function To Delete Case Data
export const deleteCaseList = (id) => {
  return async (dispatch) => {
    await DELETE(`superAdmin/caseType/delete/${id}`)
      .then((res) => {
        if (res.statusCode === 200) {
          ShowSuccessAlert(res.message);
          dispatch(fetchCasesList());
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};
