import { ActionTypes } from "../ActionCreater/ActionType";
import { GET, POST } from "../../Utils/Helper";
import {
  ShowErrorAlert,
  ShowSuccessAlert,
} from "../../Components/Notification";

const fetchNotifyDataRequest = () => {
  return {
    type: ActionTypes.NOTIFY_DATA_REQUEST,
  };
};

const fetchNotifyDataSuccess = (data) => {
  return {
    type: ActionTypes.NOTIFY_DATA_SUCCESS,
    payload: data,
  };
};

export const notifyData = (obj) => {
  return async (dispatch) => {
    dispatch(fetchNotifyDataRequest());
    await POST(`notification/showNotification`, obj)
      .then((res) => {
        dispatch(fetchNotifyDataSuccess(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};

export const notifyCount = (updateMethod) => {
  return async (dispatch) => {
    await GET(`notification/showNotificationCounter`)
      .then((res) => {
        updateMethod(res.data);
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};

export const markAsRead = (id) => {
  return async () => {
    await POST(`notification/updateNotificationStatus/${id}`)
      .then((res) => {})
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};

export const markAsAllRead = (userid, cb) => {
  return async () => {
    await POST(`notification/markAllAsRead/${userid}`)
      .then((res) => {
        // updateMethod(res);
        ShowSuccessAlert(res?.message);
        cb();
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      );
  };
};
