import {
  ShowErrorAlert,
  ShowSuccessAlert,
} from "../../Components/Notification";
import { DELETE, GET, POST, PUT } from "../../Utils/Helper";
import { ActionTypes } from "../ActionCreater/ActionType";

// function to dispatch request
const fetchRequest = () => {
  return {
    type: ActionTypes.FETCH_ROLE_TYPE_REQUEST,
  };
};

// function to dispatch data after success
const fetchSuccess = (data) => {
  return {
    type: ActionTypes.READ_ROLE_TYPE_LIST,
    payload: data,
  };
};

// function to set Button Loader to true
const PostRequest = () => {
  return {
    type: ActionTypes.POST_ROLE_TYPE_REQUEST,
  };
};

// function to set Button Loader to false
const PostSuccess = () => {
  return {
    type: ActionTypes.POST_ROLE_TYPE_SUCCESS,
  };
};

// Thunk function to read all Role Type Data
export const fetchRoleType = () => {
  return async (dispatch) => {
    dispatch(fetchRequest());
    await GET("superAdmin/roleType")
      .then((res) => {
        dispatch(fetchSuccess(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};

// function to post role type
export const PostRoleType = (obj, navigate) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await POST("superAdmin/roleType/create", obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchRoleType());
          ShowSuccessAlert(res.message);
          navigate("/dashboard/roletypes");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => {
        dispatch(PostSuccess());
      });
  };
};

// function to Single Read Role Data
export const SingleReadRole = (id, updateMethod) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await GET(`superAdmin/roleType/show/${id}`)
      .then((res) => {
        updateMethod(res);
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.message)))
      .finally(() => {
        dispatch(PostSuccess());
      });
  };
};

// function to update Role Type
export const UpdateRoleData = (id, obj, navigate) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await PUT(`superAdmin/roleType/update/${id}`, obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchRoleType());
          ShowSuccessAlert(res.message);
          navigate("/dashboard/roletypes");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => {
        dispatch(PostSuccess());
      });
  };
};

// function to delete Role Data
export const DeleteRoleData = (id) => {
  return async (dispatch) => {
    await DELETE(`superAdmin/roleType/delete/${id}`)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchRoleType());
          ShowSuccessAlert(res.message);
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};
