import React from "react";
import Button from "../../../../Components/Button";
import { useState } from "react";
import StickyNote from "./details/StickyNote";
import DetailCheckList from "./details/DetailCheckList";
import Modal from "../../../../Components/modal/Modal";
import CreateSticky from "./details/CreateSticky";
import { useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";

const Details = () => {
  const [IsOpen, setIsOpen] = useState(false);

  const {
    singlePetitionState: {
      data: { name, adv_name },
    },
  } = useSelector((state) => state);

  return (
    <>
      {/* Add Note Modal */}
      <Modal isOpen={IsOpen} setisOpen={setIsOpen}>
        <CreateSticky setIsOpen={setIsOpen} />
      </Modal>
      <div className="bg-[#EDF1F5] h-[calc(100vh-10.7rem)] px-3 py-1 overflow-y-auto">
        <div className="bg-white p-2 rounded-md lg:rounded-full flex justify-between">
          <div className="flex items-center lg:gap-20 gap-3 text-gray-800 font-normal lg:px-3 lg:text-base text-sm">
            <p className="capitalize tracking-tight">
              Petitionor Name:
              <span className="font-bold lowercase">{name}</span>
            </p>
            <p className="capitalize tracking-tight">
              Advocate Name:
              <span className="font-bold lowercase">{adv_name}</span>
            </p>
          </div>
        </div>
        <div className="h-custBox overflow-x-hidden py-2 px-2 border border-gray-500 rounded-md">
          {<DetailCheckList />}
        </div>
        <div className="bg-white px-2 py-1 rounded-md lg:rounded-full flex justify-between items-center">
          <h1 className="px-3 font-semibold">Add Sticky Note</h1>
          <Button
            className={
              "p-2 transition-all duration-150 ease-in-out hover:bg-blue-700 bg-blue-500 lg:rounded-full rounded-md text-white font-medium capitalize"
            }
            onclick={() => setIsOpen(true)}
          >
            <AiOutlinePlus className="w-5 h-5" />
          </Button>
        </div>
        <div className="h-custBox overflow-x-hidden py-4 px-2 border border-gray-500 rounded-md">
          {<StickyNote />}
        </div>
      </div>
    </>
  );
};

export default Details;
