import { ActionTypes } from "../ActionCreater/ActionType";

const initialnotifyData = {
  isLoading: false,
  data: [],
};

export const notificationData = (
  state = initialnotifyData,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.NOTIFY_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.NOTIFY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
      };

    default:
      return state;
  }
};
