import React, { useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { loginAction } from "../../Redux/Actions/globalUsage";
import {
  AiOutlineEyeInvisible,
  AiOutlineEye,
  AiFillLock,
} from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import InputField from "../../Components/InputField";
import Button from "../../Components/Button";
import { NullValidate, UserInitialObj } from "./addon/auth";
import logo from "../../Assets/logo2.webp";
import { useCookies } from "react-cookie";

function Login() {
  const [ChangeIco, setChangeIco] = useState(false);
  const [userObj, setUserObj] = useState(UserInitialObj);
  const [FieldError, setFieldError] = useState({});
  const [Focus, setFocus] = useState(false);
  const [rememberMe, setrememberMe] = useState(false);
  const [cookies, setCookie] = useCookies();

  const rememberMeCheck = useRef();

  let navigate = useNavigate();
  const dispatch = useDispatch(); // using Dispatch Hook from redux
  const token = Object.keys(cookies);

  // bindActionCreators Method is used to wrap the redux actions with dispatch method
  const { LoginRequest } = bindActionCreators(loginAction, dispatch);

  // Using useSelector Hook from redux to get the state
  const { isLoading } = useSelector((state) => state.loginState);

  // Handling The Change of Input
  const ChangeOfInput = (e) => {
    const { name, value } = e.target;
    setUserObj({ ...userObj, [name]: value });
  };

  // This are inputField Props
  const inputFieldProps = {
    name: "email",
    type: "text",
    onchange: ChangeOfInput,
    className:
      "p-2 outline-none focus:ring-2 focus:ring-blue-300 border border-gray-500 rounded-sm text-gray-500 text-base w-full",
  };
  const passwordField = {
    name: "password",
    type: `${ChangeIco ? "text" : "password"}`,
    onchange: ChangeOfInput,
    className:
      "p-2 outline-none focus:ring-2 focus:ring-blue-300 border border-gray-500 rounded-sm text-gray-500 text-base w-full",
  };

  // Function to Change Icon of password for viewing purpose
  const IconChangeHandle = () => {
    setChangeIco(true);
    setTimeout(() => setChangeIco(false), 1000);
  };

  // Function for Handling Login
  const LoginClick = () => {
    setFieldError(NullValidate(userObj));
    setFocus(true);
    if (Object.keys(NullValidate(userObj)).length === 0) {
      LoginRequest(userObj, navigate, setCookie);
    }
  };

  // using useEffect Hook to Show inputField Errors on runtime after button Click
  useEffect(() => {
    if (Focus) {
      if (Object.keys(NullValidate(userObj)).length !== null)
        setFieldError(NullValidate(userObj));
      if (rememberMe) {
        localStorage.setItem("rememberMe", JSON.stringify(userObj.email));
      }
    }
    // eslint-disable-next-line
  }, [Focus, userObj]);

  useEffect(() => {
    if (localStorage.getItem("rememberMe") !== null || undefined) {
      let credentials = JSON.parse(localStorage.getItem("rememberMe"));
      setUserObj({ ...userObj, email: credentials });
      rememberMeCheck.current.checked = true;
      setrememberMe(rememberMeCheck.current.checked);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (token.length !== 0) {
      navigate("/dashboard/homepage");
    }
  }, [token.length, navigate]);

  return (
    <>
      <div
        className="flex justify-center lg:justify-end items-center bg-login-img bg-left object-scale-down bg-cover"
        style={{ minHeight: "100vh" }}
      >
        <div className="w-[85%] md:w-[30rem] rounded-md lg:rounded-none flex flex-col justify-center lg:h-screen bg-white ">
          <div className=" rounded-t-md p-3">
            <h1 className="text-2xl text-black font-medium text-center uppercase">
              user Login
            </h1>
          </div>
          <div className="flex flex-col  justify-center">
            <div className="flex flex-col justify-center items-center p-5 text-black ">
              <img className="w-48 h-40  ml-10" src={logo} alt="" />
              <h2 className="text-lg font-semibold min-w-full text-center tracking-widest">
                VVMC
              </h2>
            </div>
            <div className="pt-0 px-5 font-medium">
              <div className="flex">
                <div className="bg-blue-600 text-white p-3 text-xl">
                  <FaUserAlt />
                </div>
                <InputField
                  value={userObj.email}
                  {...inputFieldProps}
                  placeholder={"Username or Email Address"}
                />
              </div>

              <p className="text-sm font-semibold text-red-400 py-1 h-6">
                {FieldError.email}
              </p>
            </div>
            <div className="px-5 pt-5 relative font-medium ">
              <label
                className="absolute top-[50%] right-10 -translate-y-[50%] cursor-pointer"
                onClick={() => IconChangeHandle()}
              >
                {ChangeIco ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </label>
              <div className="flex">
                <div className="bg-blue-600 text-white p-3 text-xl">
                  <AiFillLock />
                </div>
                <InputField
                  value={userObj.password}
                  {...passwordField}
                  placeholder={"Password"}
                />
              </div>

              <p className="text-sm font-semibold text-red-400 py-1 h-6">
                {FieldError.password}
              </p>
            </div>
            <div className="w-full flex justify-between text-black lg:text-base text-sm px-5 mb-5 mt-7 font-medium ">
              <label className="cursor-pointer flex items-center">
                <input
                  ref={rememberMeCheck}
                  onChange={(e) => {
                    setrememberMe(e.target.checked);
                  }}
                  type={"checkbox"}
                  className="mx-2"
                />
                Remember me
              </label>
              <Link to={"/forgotpassword"} className="cursor-pointer ">
                Forgot Password?
              </Link>
            </div>
            <div className="px-5 pt-2 pb-5 w-full">
              <Button
                isloading={isLoading}
                className={
                  "bg-blue-600 w-[inherit] p-2 text-white rounded-md shadow-md outline-none flex justify-center hover:bg-blue-700"
                }
                onclick={LoginClick}
                text={"Sign In"}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
