import { ActionTypes } from "../ActionCreater/ActionType";

const DisposeObj = {
  isloading: false,
  disposeData: [],
};

export const DisposeReducer = (state = DisposeObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.DISPOSE_PETITION_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.DISPOSE_PETITION_SUCCESS:
      return {
        ...state,
        isloading: false,
        disposeData: payload,
      };

    default:
      return state;
  }
};
