import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlinePlus } from "react-icons/ai";
import { MdModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import ActiveComponents from "../../../../Components/ActiveComponents";
import { ROLES } from "../../../../Components/addons/roleArray";
import Button from "../../../../Components/Button";
import CustomStyles, {
  paginationComponentOptions,
} from "../../../../Components/datatable";
import Dropdown from "../../../../Components/Dropdown";
import InputField from "../../../../Components/InputField";
import Loader2 from "../../../../Components/Loader2";
import Modal from "../../../../Components/modal/Modal";
import {
  checkAvailableDateType,
  checkDateType,
  dateFormate,
  getDatefromTimeStamp,
  getSerialNumber,
  getTodayDate,
} from "../../../../Components/Notification";
import ProtectedComponent from "../../../../Components/ProtectedComponent";
import {
  DateTypeAction,
  petitionList,
} from "../../../../Redux/Actions/globalUsage";
import { DateNullValidate, initialDate } from "../../addons/file";

const ImportantDates = () => {
  // Local States
  const [isOpen, setisOpen] = useState(false);
  const [formData, setformData] = useState(initialDate);
  const [formError, setformError] = useState({});
  const [focus, setfocus] = useState(false);
  const [todayDate, settodayDate] = useState([]);
  const [Id, setId] = useState("");
  const [EditModal, setEditModal] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);

  // Variables
  const dispatch = useDispatch();
  const { id } = useParams();

  const column = [
    {
      name: "Sr no.",
      selector: (_, i) => getSerialNumber(i, currentPage),
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) =>
        row?.from_user_id_name ? row?.from_user_id_name : "N/A",
      sortable: true,
    },
    {
      name: "Date Type",
      selector: (row) => PillUI(row?.date_type_name?.toLowerCase()),
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => dateFormate(row.date),
      sortable: true,
    },
    {
      name: "Remark",
      selector: (row) =>
        row.dsc !== null && row.dsc.length > 0 ? row.dsc : "N/A",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => ActionUI(row),
    },
  ];

  const { data: singlePetitionData } = useSelector(
    (state) => state.singlePetitionState
  );

  const ModalColumn = [
    {
      name: "Sr.no.",
      selector: (_, i) => getSerialNumber(i, currentPage),
    },
    {
      name: "Date Type",
      selector: (row) => PillUI(row.date_type_name?.toLowerCase()),
    },
    {
      name: "Date",
      selector: (row) => dateFormate(row.date),
    },
    {
      name: "Remark",
      selector: (row) => row.dsc,
    },
  ];

  const {
    loadPetitionDate,
    createPetitionDate,
    fetchDateTypeData,
    updatePetitionDate,
  } = bindActionCreators({ ...petitionList, ...DateTypeAction }, dispatch);

  const { data, isLoading } = useSelector((state) => state.p_date);
  const { dateTypeData } = useSelector((state) => state.dateTypeState);
  const { filterIsloading } = useSelector((state) => state.petitionLoader);

  const PillUI = (name) => {
    return (
      <>
        <div
          className={`${
            name === "hearing date" ? "bg-red-500" : "bg-orange-500"
          } p-2 min-w-[8rem] rounded-md text-white capitalize`}
        >
          <p className="text-sm font-semibold text-center">
            {name ? name : "No Data"}
          </p>
        </div>
      </>
    );
  };

  const ChangeOfInput = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value, petition_id: id });
  };

  const inputFieldProps = {
    onchange: ChangeOfInput,
    className:
      "p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full",
  };

  const dropdownFieldProps = {
    HandleChange: ChangeOfInput,
    ClassName:
      "p-[0.6rem] outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full",
  };

  const handleSubmit = () => {
    setformError(DateNullValidate(formData));
    setfocus(true);
    if (Object.keys(DateNullValidate(formData)).length === 0) {
      createPetitionDate(formData, id, setisOpen);
      setformData(initialDate);
      setfocus(false);
    }
  };

  const handleEdit = () => {
    setformError(DateNullValidate(formData));
    setfocus(true);
    if (Object.keys(DateNullValidate(formData)).length === 0) {
      updatePetitionDate(formData, Id, id, setisOpen);
      setformData(initialDate);
      setfocus(false);
      setEditModal(false);
    }
  };

  // FUNCTION TO DISPLAY  EDIT AND DELETE BUTTON IN DATA TABLE
  const ActionUI = (row) => {
    return (
      <>
        <div className="flex gap-x-2">
          <ActiveComponents active={singlePetitionData?.active}>
            <button
              onClick={() => {
                setId(row.id);
                setEditModal(true);
              }}
              type={"button"}
              className="p-2 bg-blue-500 rounded-full text-white"
            >
              <MdModeEdit className="w-4 h-4" />
            </button>
          </ActiveComponents>
        </div>
      </>
    );
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    if (EditModal) {
      let singleData = data.filter((item) => item.id === Id);
      setformData(singleData[0]);
    } else {
      setformData(initialDate);
    }
  }, [EditModal]);

  useEffect(() => {
    const filteredDate = data.filter(
      (item) => getDatefromTimeStamp(item.created_at) === getTodayDate()
    );
    settodayDate(filteredDate);
  }, [data]);

  useEffect(() => {
    loadPetitionDate(id);

    if (dateTypeData.length === 0) {
      fetchDateTypeData();
    }
  }, []);

  useEffect(() => {
    if (focus) {
      if (Object.keys(DateNullValidate(formData)).length !== null)
        setformError(DateNullValidate(formData));
    }
  }, [focus, formData]);

  return (
    <>
      {/* CREATE MODAL */}
      <Modal isOpen={isOpen} setisOpen={setisOpen}>
        <div className="w-full h-full space-y-5">
          <h3 className="font-bold text-xl rounded-t-md w-full bg-gray-300 text-[#296aff]  p-3 ">
            Add Petition Date
          </h3>

          <div className="grid lg:grid-cols-2 grid-cols-1 gap-3 px-5">
            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                Date Type
                <span className="text-red-600 font-semibold pl-1">*</span>
              </label>
              <Dropdown
                DefaultValue={"--Select Date Type--"}
                SetValue={formData.date_type}
                setName={"date_type"}
                {...dropdownFieldProps}
              >
                {checkAvailableDateType(dateTypeData, data).map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Dropdown>

              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {formError.date_type}
              </p>
            </div>
            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                Date
                <span className="text-red-600 font-semibold pl-1">*</span>
              </label>
              <InputField
                type={"date"}
                {...inputFieldProps}
                value={formData.date}
                name="date"
                placeholder="Enter Court No."
              />
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {formError.date}
              </p>
            </div>
            <div className="flex flex-col lg:col-span-2">
              <label className=" text-gray-800 font-medium text-sm flex gap-1 pb-1">
                Petition Date Remarks
              </label>
              <textarea
                className="resize-none h-10 p-2 outline-none border border-gray-500 rounded-sm text-gray-500  text-sm w-full"
                placeholder="Enter Remarks"
                value={formData.dsc}
                onChange={ChangeOfInput}
                name="dsc"
              ></textarea>
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {formError.dsc}
              </p>
            </div>
          </div>

          <div className="flex justify-end px-5">
            <Button
              type={"button"}
              text={"Submit"}
              isloading={filterIsloading}
              onclick={handleSubmit}
              className={
                "w-full flex justify-center items-center outline-none md:w-[20%] p-2 text-white min-w-fit font-medium rounded-md shadow-md bg-[#298aff] "
              }
            />
          </div>

          <div className="px-5 py-5">
            <h4 className="font-bold text-xl">Today Date List</h4>
            <DataTable
              columns={ModalColumn}
              customStyles={CustomStyles}
              data={todayDate}
              fixedHeader
              fixedHeaderScrollHeight="35vh"
              pagination
              onChangePage={handlePageChange}
              paginationComponentOptions={paginationComponentOptions}
            />
          </div>
        </div>
      </Modal>
      {/* UPDATE MODAL */}
      <Modal isOpen={EditModal} setisOpen={setEditModal}>
        <div className="w-full h-full space-y-5">
          <h3 className="font-bold text-xl rounded-t-md w-full bg-gray-300 text-[#296aff]  p-3 ">
            Update Petition Date
          </h3>

          <div className="grid lg:grid-cols-2 grid-cols-1 gap-3 px-5">
            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                Date Type
                <span className="text-red-600 font-semibold pl-1">*</span>
              </label>
              <Dropdown
                DefaultValue={"--Select Date Type--"}
                SetValue={formData.date_type}
                setName={"date_type"}
                {...dropdownFieldProps}
              >
                {dateTypeData
                  ?.filter((item) =>
                    [formData]?.find(
                      (element) => element?.date_type === item?.id
                    )
                  )
                  ?.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Dropdown>

              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {formError.date_type}
              </p>
            </div>
            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                Date
                <span className="text-red-600 font-semibold pl-1">*</span>
              </label>
              <InputField
                type={"date"}
                {...inputFieldProps}
                value={formData.date}
                name="date"
                placeholder="Enter Court No."
              />
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {formError.date}
              </p>
            </div>
            <div className="flex flex-col lg:col-span-2">
              <label className=" text-gray-800 font-medium text-sm flex gap-1 pb-1">
                Petition Date Remarks
              </label>
              <textarea
                className="resize-none h-10 p-2 outline-none border border-gray-500 rounded-sm text-gray-500  text-sm w-full"
                placeholder="Enter Remarks"
                value={formData.dsc}
                onChange={ChangeOfInput}
                name="dsc"
              ></textarea>
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {formError.dsc}
              </p>
            </div>
          </div>

          <div className="flex justify-end px-5">
            <Button
              type={"button"}
              text={"Submit"}
              isloading={filterIsloading}
              onclick={handleEdit}
              className={
                "w-full flex justify-center items-center outline-none md:w-[20%] p-2 text-white min-w-fit font-medium rounded-md shadow-md bg-[#298aff] "
              }
            />
          </div>

          <div className="px-5 py-5">
            <h4 className="font-bold text-xl">Today Date List</h4>
            <DataTable
              columns={ModalColumn}
              customStyles={CustomStyles}
              data={todayDate}
              fixedHeader
              fixedHeaderScrollHeight="35vh"
              pagination
              onChangePage={handlePageChange}
              paginationComponentOptions={paginationComponentOptions}
            />
          </div>
        </div>
      </Modal>
      <div className="flex flex-col items-start py-2 bg-white/75 min-h-[calc(100vh-10.5rem)]">
        {/* HEADER FOR THE REMARKS */}
        <div className="flex justify-between w-full p-5">
          <h2 className="font-bold text-2xl">Date List</h2>
          <ProtectedComponent
            allowedRoles={[
              ROLES.super_admin,
              ROLES.admin,
              ROLES.legal_dept,
              ROLES.sub,
              ROLES.ward,
              ROLES.dept,
              ROLES.lawyer,
            ]}
          >
            <ActiveComponents active={singlePetitionData?.active}>
              {!checkDateType(data) && (
                <button
                  onClick={() => {
                    setisOpen(true);
                  }}
                  className="outline-none capitalize flex items-center text-white bg-gradient-to-r from-[#1f9fff] to-[#1f4fdf] rounded-[10px] py-2 px-4"
                >
                  <AiOutlinePlus className=" text-xl" /> Add Date
                </button>
              )}
            </ActiveComponents>
          </ProtectedComponent>
        </div>
        <div className="w-full p-5">
          {isLoading ? (
            <div className="flex justify-center items-center ">
              <Loader2 />
            </div>
          ) : (
            <DataTable
              columns={column}
              customStyles={CustomStyles}
              paginationComponentOptions={paginationComponentOptions}
              data={data}
              fixedHeader
              fixedHeaderScrollHeight="50vh"
              pagination
              onChangePage={handlePageChange}
            />
          )}
        </div>
      </div>
      .
    </>
  );
};

export default ImportantDates;
