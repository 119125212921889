import {
  ShowErrorAlert,
  ShowSuccessAlert,
} from "../../Components/Notification";
import { DELETE, GET, POST, PUT } from "../../Utils/Helper";
import { ActionTypes } from "../ActionCreater/ActionType";

// function to request for case stage
const fetchRequest = () => {
  return {
    type: ActionTypes.FETCH_CASE_STAGE_REQUEST,
  };
};

// function to pass case stage data as payload
const fetchSuccess = (data) => {
  return {
    type: ActionTypes.READ_CASE_STAGE,
    payload: data,
  };
};

// function to set Button Loader to true
const PostRequest = () => {
  return {
    type: ActionTypes.POST_CASE_STAGE_REQUEST,
  };
};

// function to set Button Loader to false
const PostSuccess = () => {
  return {
    type: ActionTypes.POST_CASE_STAGE_SUCCESS,
  };
};

// Thunk function to fetch case Stage Data
export const fetchCaseStage = () => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await GET("superAdmin/caseStage")
      .then((res) => {
        dispatch(fetchSuccess(res.data));
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.message)))
      .finally(() => {
        dispatch(PostSuccess());
      });
  };
};

// Thunk function to create case stage
export const PostCaseStage = (obj, navigate) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await POST("superAdmin/caseStage/create", obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchCaseStage());
          ShowSuccessAlert(res.message);
          navigate("/dashboard/casestage");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => {
        dispatch(PostSuccess());
      });
  };
};

// Thunk function to Single Read Case Stage Data
export const SingleReadCaseStage = (id, updateMethod) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await GET(`superAdmin/caseStage/show/${id}`)
      .then((res) => {
        updateMethod(res.data);
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.message)))
      .finally(() => {
        dispatch(PostSuccess());
      });
  };
};

// Thunk function to update Case Stage Data
export const UpdateCaseStage = (id, obj, navigate) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await PUT(`superAdmin/caseStage/update/${id}`, obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchCaseStage());
          ShowSuccessAlert(res.message);
          navigate("/dashboard/casestage");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => {
        dispatch(PostSuccess());
      });
  };
};

// Thunk function to Delete Case Stage Data
export const DeleteCaseStage = (id) => {
  return async (dispatch) => {
    await DELETE(`superAdmin/caseStage/delete/${id}`)
      .then((res) => {
        if (res.statusCode === 200) {
          ShowSuccessAlert(res.message);
          dispatch(fetchCaseStage());
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};
