import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../Components/Button";
import InputField from "../../../../Components/InputField";
import { DeptObj, NullValidate } from "../addons/file";
import { BsArrowLeftCircleFill } from "react-icons/bs";

function CreateDept() {
  const [createState, setCreateState] = useState(DeptObj);
  const [FieldError, setFieldError] = useState({});
  const [Focus, setFocus] = useState(false);

  let navigate = useNavigate();

  // Function to Navigate Back to location
  const GoBack = () => {
    if (localStorage.getItem("path_name") === null || undefined) {
      navigate("/dashboard");
    } else navigate(localStorage.getItem("path_name"));
  };

  // Function To Handle Change of InputField
  const HandleChangeInput = (e) => {
    const { value } = e.target;
    setCreateState({ ...createState, dept_name: value });
  };

  // Props For InputFIeld
  const InputFieldProps = {
    className:
      "p-2 outline-none border border-gray-500 placeholder:text-gray-500 placeholder:capitalize rounded-sm text-gray-500 text-sm w-full",
    onchange: HandleChangeInput,
  };

  const HandleCreateCourt = () => {
    setFieldError(NullValidate(createState));
    setFocus(true);
    if (Object.keys(NullValidate(createState)).length === 0) {
      PostCreateRequest();
    }
  };

  const PostCreateRequest = async () => {
    navigate("/courtlist");
  };

  useEffect(() => {
    if (Focus) {
      if (Object.keys(NullValidate(createState)).length !== null)
        setFieldError(NullValidate(createState));
    }
  }, [Focus, createState]);

  return (
    <div
      className="w-full overflow-hidden flex justify-center items-start pt-5 bg-white/75"
      style={{
        height: `calc(100vh - 4rem)`,
      }}
    >
      <div className="w-[95%] md:w-1/2  border border-gray-300 rounded-t-md overflow-hidden">
        <div className="p-2 bg-gray-300 font-semibold text-base text-[#174bff] w-full flex justify-between items-center">
          <h1 className="capitalize">new department registration</h1>
          <BsArrowLeftCircleFill
            className="text-[#296aff] text-2xl cursor-pointer"
            onClick={GoBack}
          />
        </div>
        <div className="bg-white h-full p-2">
          <div className="w-full grid gap-4 p-2">
            <div className="flex flex-col gap-1">
              <label className="capitalize text-sm text-gray-800 font-medium">
                department name
              </label>
              <InputField
                {...InputFieldProps}
                name="remark"
                value={createState.dept_name}
                onBlur={() => setFocus(true)}
                placeholder={"enter department name"}
              />
              <p className="text-sm font-bold h-5 text-red-500">
                {FieldError.dept_name}
              </p>
            </div>
            <div className="w-full flex gap-4 justify-end">
              <Button
                type={"button"}
                text={"create"}
                className={
                  "w-full outline-none bg-[#298aff] p-2 text-white rounded-md shadow-md min-w-fit md:w-[10%] font-medium capitalize"
                }
                onclick={HandleCreateCourt}
              />
              <Button
                type={"button"}
                text={"cancel"}
                className={
                  "w-full outline-none bg-[#fa5151] p-2 text-white rounded-md shadow-md min-w-fit md:w-[10%] font-medium capitalize"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateDept;
