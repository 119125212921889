import React from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import CustomStyles, {
  paginationComponentOptions,
} from "../../../Components/datatable";
import { CheckLists } from "../../../Redux/Actions/globalUsage";
import { MdModeEdit, MdDeleteForever } from "react-icons/md";
import { DeleteModal, getSerialNumber } from "../../../Components/Notification";
import Loader2 from "../../../Components/Loader2";
import { useState } from "react";

const CheckList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setcurrentPage] = useState(1);

  const { FetchCheckListData, DeleteCheckList } = bindActionCreators(
    CheckLists,
    dispatch
  );

  const {
    checkListState: { data, isloading },
  } = useSelector((state) => state);

  const CheckListCols = [
    {
      name: "Sr No.",
      selector: (_, i) => getSerialNumber(i, currentPage),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => ActionUI(row),
    },
  ];

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  // Action Ui
  const ActionUI = (row) => {
    return (
      <>
        <div className="flex gap-x-2">
          <Link
            to={`edit/${row.id}`}
            type={"button"}
            className="p-2 bg-[#296aff] rounded-full text-white"
          >
            <MdModeEdit className="w-4 h-4" />
          </Link>
          <div
            className="p-2 bg-[#ff2025] rounded-full text-white cursor-pointer"
            onClick={() => HandleDeleteRequest(row.id)}
          >
            <MdDeleteForever className="w-4 h-4" />
          </div>
        </div>
      </>
    );
  };

  const HandleDeleteRequest = (Id) => {
    DeleteModal(Id, DeleteRequest);
  };

  const DeleteRequest = (id) => {
    DeleteCheckList(id, () => {
      FetchCheckListData();
    });
  };

  const HandleCreateCheckList = () => {
    navigate("/dashboard/createCheckList");
  };

  useEffect(() => {
    FetchCheckListData();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className="px-4 pt-5 overflow-hidden bg-white/75"
        style={{ height: `calc(100vh - 4rem)` }}
      >
        <div className="w-full flex justify-between items-center pb-5">
          <h1 className="text-3xl font-semibold capitalize">check list</h1>
          <button
            className="outline-none flex items-center text-white bg-gradient-to-r from-[#1f9fff] to-[#1f4fdf] rounded-[10px] py-2 px-4"
            onClick={HandleCreateCheckList}
          >
            <img
              className="mr-2 h-4"
              src="https://img.icons8.com/android/20/ffffff/plus.png"
              alt=""
            />
            Create Check List
          </button>
        </div>
        <div>
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="70vh"
            pagination
            columns={CheckListCols}
            data={data}
            progressPending={isloading}
            progressComponent={<Loader2 />}
            customStyles={CustomStyles}
            onChangePage={handlePageChange}
            paginationComponentOptions={paginationComponentOptions}
          />
        </div>
      </div>
    </>
  );
};

export default CheckList;
