import { getTodayDate } from "../../../../../../Components/Notification";

export const NoteObj = {
  description: "",
  date: "",
};

export const CheckListObj = {
  checklist_id: [],
  user_id: "",
  petition_id: "",
};

export const checkObjType = {
  CHECK_ID_DATA: "CHECK_ID_DATA",
};

export const CheckListDataReducer = (state, { type, payload }) => {
  switch (type) {
    case checkObjType.CHECK_ID_DATA:
      const { arr, id, user_Id } = payload;
      return {
        ...state,
        checklist_id: arr,
        user_id: user_Id,
        petition_id: id,
      };

    default:
      return state;
  }
};

export function NullValidate(obj) {
  let Error = {};

  if (obj.description.length === 0)
    Error.description = "Description is required";
  else if (obj.description.length > 500)
    Error.description = "Description Length should be less 500";

  if (obj.date.length === 0) Error.date = "Date Field is required";
  else if (new Date(obj.date) > new Date("2100-01-01"))
    Error.date = "Date Invalid";
  else if (obj.date < getTodayDate())
    Error.date = "Date should be higher or equal to todays date";

  return Error;
}
