import React from "react";
import { ImBin } from "react-icons/im";
import { HiPencil } from "react-icons/hi";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { DetailsAction } from "../../../../../Redux/Actions/globalUsage";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader2 from "../../../../../Components/Loader2";
import { useState } from "react";
import Modal from "../../../../../Components/modal/Modal";
import UpdateSticky from "./UpdateSticky";
import { DeleteModal } from "../../../../../Components/Notification";

const StickyNote = () => {
  const [IsOpen2, setIsOpen2] = useState(false);
  const [SingleId, setSingleId] = useState(null);

  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    stickyNoteState: { data, loading },
  } = useSelector((state) => state);

  const { stickyNotesData, DeleteStickyNote } = bindActionCreators(
    DetailsAction,
    dispatch
  );

  const HandleDeleteRequest = (Id) => {
    DeleteStickyNote(Id, () => stickyNotesData(id));
  };

  useEffect(() => {
    stickyNotesData(id);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* Update Note Modal */}
      <Modal isOpen={IsOpen2} setisOpen={setIsOpen2}>
        <UpdateSticky setIsOpen2={setIsOpen2} SingleId={SingleId} />
      </Modal>
      {loading ? (
        <div className="w-full h-full flex justify-center">
          <Loader2 />
        </div>
      ) : (
        <div className="grid lg:grid-cols-3 gap-6">
          {data?.map((item) => (
            <div className="px-8 py-4 relative bg-white rounded-xl shadow-md flex flex-col gap-2">
              <div className="font-semibold">
                <h1 className="text-lg">Created On:</h1>
                <p className="text-sm font-medium">{item.date}</p>
                <div className="absolute w-1 h-12 rounded-tr-md rounded-br-md bg-red-500 top-4 left-0"></div>
              </div>
              <div className="text-justify tracking-tight">
                <p className="text-gray-700 font-semibold h-medium overflow-x-hidden thisScroll">
                  {item?.description}
                </p>
              </div>
              <div className="flex gap-4 items-center">
                <HiPencil
                  onClick={() => {
                    setIsOpen2(true);
                    setSingleId(item?.id);
                  }}
                  className="w-5 h-5 text-blue-500 cursor-pointer hover:scale-125 transition-all duration-150 ease-linear"
                />
                <ImBin
                  onClick={() => {
                    DeleteModal(item?.id, HandleDeleteRequest);
                  }}
                  className="w-4 h-4 text-red-500 cursor-pointer hover:scale-125 transition-all duration-150 ease-linear"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default StickyNote;
