import { ActionTypes } from "../ActionCreater/ActionType";

const InitialObj = {
  isloading: false,
  dateTypeData: [],
};

const BtnLoader = {
  loading: false,
};

// reducer for fetching and storing data in store
export const DateTypeReducer = (state = InitialObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.DATE_TYPE_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.DATE_TYPE_SUCCESS:
      return {
        ...state,
        isloading: false,
        dateTypeData: payload,
      };

    default:
      return state;
  }
};

// reducer for setting loader to true or false
export const BtnloaderReducer = (state = BtnLoader, { type }) => {
  switch (type) {
    case ActionTypes.DATE_TYPE_LOADER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DATE_TYPE_LOADER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
