import React from "react";
import Loader from "./Loader";

function Button({ className, onclick, type, children, text, isloading }) {
  return (
    <button
      className={`${className} ${isloading && "cursor-not-allowed"}`}
      disabled={isloading}
      onClick={onclick}
      type={type}
    >
      {children}
      {isloading ? <Loader /> : text}
    </button>
  );
}

export default Button;
