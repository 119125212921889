import { ActionTypes } from "../ActionCreater/ActionType";

const initialObj = {
  isLoading: false,
  data: [],
};

const initialSinglePetition = {
  data: [],
};

const todayPetition = {
  isLoading: false,
  data: [],
};

const ButtonLoader = {
  filterIsloading: false,
};

// INITIAL VALUE FOR THE PETITION REMARKS
const pRemarksinitial = {
  isLoading: false,
  data: [],
};

const p_attachment = {
  isLoading: false,
  data: [],
};

const p_date = {
  isLoading: false,
  data: [],
};

const quickAssignLoader = {
  loading: false,
};

const initialquickAssign = {
  data: [],
};

const initialPetitionLog = {
  data: [],
};

const initialAssignedUser = {
  assigned_users: [],
  users: [],
};

const petitionRoleObj = {
  loading: false,
  data: [],
};

const initialStage = {
  data: [],
};

export const petition = (state = initialObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_PETITION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.READ_PETITION_LIST:
      return {
        ...state,
        isLoading: false,
        data: payload,
      };

    default:
      return state;
  }
};

export const singlePetition = (
  state = initialSinglePetition,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SINGLE_PETITION:
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
};

export const UnassignedList = (
  state = initialquickAssign,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.UNASSIGNED_PETITION_LIST:
      return {
        ...state,
        data: payload,
      };

    default:
      return state;
  }
};

export const todayPetitionData = (state = todayPetition, { type, payload }) => {
  switch (type) {
    case ActionTypes.TODAY_PETITION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.TODAY_PETITION_LIST:
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    case ActionTypes.TODAY_PETITION_LOADER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export const filterBtn = (state = ButtonLoader, { type }) => {
  switch (type) {
    case ActionTypes.PETITION_LOADER_REQUEST:
      return {
        ...state,
        filterIsloading: true,
      };

    case ActionTypes.PETITION_LOADER_SUCCESS:
      return {
        ...state,
        filterIsloading: false,
      };

    default:
      return state;
  }
};

//REDUCER FOR THE PETITION REMARKS
export const petitionRemark = (state = pRemarksinitial, { type, payload }) => {
  switch (type) {
    case ActionTypes.PETITION_REMARK_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.PETITION_REMARK_DATA:
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    default:
      return state;
  }
};

//REDUCER FOR THE PETITION Attachments
export const petitionAttachment = (state = p_attachment, { type, payload }) => {
  switch (type) {
    case ActionTypes.PETITION_ATTACHMENT_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.PETITION_ATTACHMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    default:
      return state;
  }
};
//REDUCER FOR THE PETITION Date
export const petitionDate = (state = p_date, { type, payload }) => {
  switch (type) {
    case ActionTypes.PETITION_DATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.PETITION_DATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    default:
      return state;
  }
};

export const QuickAssignReducer = (state = quickAssignLoader, { type }) => {
  switch (type) {
    case ActionTypes.QUICK_ASSIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.QUICK_ASSIGN_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export const PetitionLogReducer = (
  state = initialPetitionLog,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.PETITION_LOG_SUCCESS:
      return {
        ...state,
        data: payload,
      };

    default:
      return state;
  }
};

export const PetitionAssignedUser = (
  state = initialAssignedUser,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.PETITION_ASSIGN_USER_DATA:
      return {
        ...state,
        assigned_users: payload.assigned,
        users: payload.users,
      };

    case ActionTypes.PETITION_ASSIGN_EMPTY:
      return {
        ...state,
        assigned_users: [],
        users: [],
      };

    default:
      return state;
  }
};

export const PetitionStageredu = (state = initialStage, { type, payload }) => {
  switch (type) {
    case ActionTypes.PETITION_STAGE_DATA:
      return {
        ...state,
        data: payload,
      };

    default:
      return state;
  }
};

export const PetitionRoleReducer = (
  state = petitionRoleObj,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.PETITION_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.PETITION_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
      };

    default:
      return state;
  }
};
