import { ActionTypes } from "../ActionCreater/ActionType";
import { GET } from "../../Utils/Helper";
import { ShowErrorAlert } from "../../Components/Notification";
import axios from "axios";

const { REACT_APP_BASEURL } = process.env;

export const loaderRequest = () => {
  return {
    type: ActionTypes.LOGIN_LOADER_REQUEST,
  };
};
export const loaderSuccess = () => {
  return {
    type: ActionTypes.LOGIN_LOADER_SUCCESS,
  };
};

export const LoginRequest = (data, navigate, setCookie) => {
  let date = new Date();
  // let currentOffset = date.getTimezoneOffset();
  // let ISTOffset = 330;
  // let IST_Time = new Date(date.getTime() + (ISTOffset + currentOffset) * 60000);

  // let Time = IST_Time.getTime();

  // IST_Time.setTime(Time + 3600 * 1000);

  let time = date.getTime();
  date.setTime(time + 24 * 3600 * 1000);

  return async (dispatch) => {
    try {
      dispatch(loaderRequest());

      const res = await axios.post(`${REACT_APP_BASEURL}auth/login`, data);

      if (res.data.statusCode === 200) {
        const loginResponse = res.data.data;
        const { forgot_otp, email_confirmed, dsc, ...rest } = loginResponse;
        const { token } = res.data.data;

        setCookie("token", token, { path: "/", expires: date });

        if (Object.entries(rest).length !== 0) {
          Object.entries(rest).map((item) => {
            return localStorage.setItem(`${item[0]}`, `${item[1]}`);
          });
        }
        navigate("/dashboard/homepage");
      }
    } catch (error) {
      ShowErrorAlert(error.response.data.message);
    } finally {
      dispatch(loaderSuccess());
    }
  };
};

export const arrayofLocalstorage = [
  "active",
  "mobile",
  "user_name",
  "id",
  "dsc",
  "post_name",
  "email_confirmed",
  "email",
  "display_name",
  "token",
  "role",
  "parent_role",
  "created_by",
];

export const LogoutRequest = (token, navigate, removeCookie) => {
  return async () => {
    await GET(`auth/logout?token=${token}`)
      .then((res) => {
        if (res.statusCode === 200) {
          removeCookie("token", { path: "/" });
          arrayofLocalstorage?.map((item) => localStorage.removeItem(item));
          navigate("/");
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};
