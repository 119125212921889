export const ActionTypes = {
  LOGIN_ACTION: "LOGIN_ACTION",
  LOGOUT_ACTION_REQUEST: "LOGOUT_ACTION_REQUEST",
  LOGIN_LOADER_REQUEST: "LOGIN_LOADER_REQUEST",
  LOGIN_LOADER_SUCCESS: "LOGIN_LOADER_SUCCESS",

  POST_USER_REQUEST: "POST_USER_REQUEST",
  POST_USER_SUCCESS: "POST_USER_SUCCESS",
  FETCH_WARDS_REQUEST: "FETCH_WARDS_REQUEST",
  FETCH_WARDS_SUCCESS: "FETCH_WARDS_SUCCESS",
  FETCH_USER_REQUEST: "FETCH_USER_REQUEST",
  FETCH_USER_LIST: "FETCH_USER_LIST",

  MASTER_CHECKLIST_LOADER: "MASTER_CHECKLIST_LOADER",
  MASTER_CHECKLIST_LOADER_SUCCESS: "MASTER_CHECKLIST_LOADER_SUCCESS",
  MASTER_CHECKLIST_REQUEST: "MASTER_CHECKLIST_REQUEST",
  MASTER_CHECKLIST_RESPONSE: "MASTER_CHECKLIST_RESPONSE",

  DETAIL_STICKY_LOADER: "DETAIL_STICKY_LOADER",
  DETAIL_STICKY_LOADER_SUCCESS: "DETAIL_STICKY_LOADER_SUCCESS",
  STICKY_NOTES_DATA_RESPONSE: "STICKY_NOTES_DATA_RESPONSE",
  DETAIL_CHECKLIST_LOADER: "DETAIL_CHECKLIST_LOADER",
  DETAIL_CHECKLIST_LOADER_SUCCESS: "DETAIL_CHECKLIST_LOADER_SUCCESS",
  CHECKED_LIST: "CHECKED_LIST",

  POST_CASE_REQUEST: "POST_CASE_REQUEST",
  POST_CASE_SUCCESS: "POST_CASE_SUCCESS",
  FETCH_CASE_REQUEST: "FETCH_CASE_REQUEST",
  READ_CASE_LIST: "READ_CASE_LIST",

  POST_COURT_REQUEST: "POST_COURT_REQUEST",
  POST_COURT_SUCCESS: "POST_COURT_SUCCESS",
  FETCH_COURT_REQUEST: "FETCH_COURT_REQUEST",
  READ_COURT_LIST: "READ_COURT_LIST",

  READ_DEPARTMENT_LIST: "READ_DEPARTMENT_LIST",

  POST_PETITION_TYPE_REQUEST: "POST_PETITION_REQUEST",
  POST_PETITION_TYPE_SUCCESS: "POST_PETITION_SUCCESS",
  FETCH_PETITION_TYPE_REQUEST: "FETCH_PETITION_TYPE_REQUEST",
  FETCH_PETITION_TYPE_SUCCESS: "FETCH_PETITION_TYPE_SUCCESS",

  POST_CASE_STAGE_REQUEST: "POST_CASE_STAGE_REQUEST",
  POST_CASE_STAGE_SUCCESS: "POST_CASE_STAGE_SUCCESS",
  FETCH_CASE_STAGE_REQUEST: "FETCH_CASE_STAGE_REQUEST",
  READ_CASE_STAGE: "READ_CASE_STAGE",

  DATE_TYPE_REQUEST: "DATE_TYPE_REQUEST",
  DATE_TYPE_SUCCESS: "DATE_TYPE_SUCCESS",
  DATE_TYPE_LOADER_REQUEST: "DATE_TYPE_LOADER_REQUEST",
  DATE_TYPE_LOADER_SUCCESS: "DATE_TYPE_LOADER_SUCCESS",

  POST_ROLE_TYPE_REQUEST: "POST_ROLE_TYPE_REQUEST",
  POST_ROLE_TYPE_SUCCESS: "POST_ROLE_TYPE_SUCCESS",
  FETCH_ROLE_TYPE_REQUEST: "FETCH_ROLE_TYPE_REQUEST",
  READ_ROLE_TYPE_LIST: "READ_ROLE_TYPE_LIST",

  PETITION_ROLE_REQUEST: "PETITION_ROLE_REQUEST",
  PETITION_ROLE_SUCCESS: "PETITION_ROLE_SUCCESS",

  POST_ATTACHMENT_REQUEST: "POST_ATTACHMENT_REQUEST",
  POST_ATTACHMENT_SUCCESS: "POST_ATTACHMENT_SUCCESS",
  FETCH_ATTACHMENT_REQUEST: "FETCH_ATTACHMENT_REQUEST",
  READ_ATTACHMENT: "READ_ATTACHMENT",

  POST_REGION_REQUEST: "POST_REGION_REQUEST",
  POST_REGION_SUCCESS: "POST_REGION_SUCCESS",
  FETCH_REGION_REQUEST: "FETCH_REGION_REQUEST",
  READ_REGION_LIST: "READ_REGION_LIST",

  FETCH_PETITION_REQUEST: "FETCH_PETITION_REQUEST",
  READ_PETITION_LIST: "READ_PETITION_LIST",
  TODAY_PETITION_REQUEST: "TODAY_PETITION_REQUEST",
  TODAY_PETITION_LIST: "TODAY_PETITION_LIST",
  TODAY_PETITION_LOADER_SUCCESS: "TODAY_PETITION_LOADER_SUCCESS",
  PETITION_LOADER_REQUEST: "PETITION_LOADER_REQUEST",
  PETITION_LOADER_SUCCESS: "PETITION_LOADER_SUCCESS",
  SINGLE_PETITION: "SINGLE_PETITION",

  UNASSIGNED_PETITION_LIST: "UNASSIGNED_PETITION_LIST",

  QUICK_ASSIGN_REQUEST: "QUICK_ASSIGN_REQUEST",
  QUICK_ASSIGN_SUCCESS: "QUICK_ASSIGN_SUCCESS",

  PETITION_ASSIGN_EMPTY: "PETITION_ASSIGN_EMPTY",
  PETITION_ASSIGN_USER_DATA: "PETITION_ASSIGN_USER_DATA",

  PETITION_ATTACHMENT_LOADER: "PETITION_ATTACHMENT_LOADER",
  PETITION_ATTACHMENT_SUCCESS: "PETITION_ATTACHMENT_SUCCESS",

  PETITION_REMARK_LOADER: "PETITION_REMARK_LOADER",
  PETITION_REMARK_DATA: "PETITION_REMARK_DATA",

  PETITION_DATE_REQUEST: "PETITION_DATE_REQUEST",
  PETITION_DATE_SUCCESS: "PETITION_DATE_SUCCESS",

  PETITION_LOG_SUCCESS: "PETITION_LOG_SUCCESS",

  PETITION_STAGE_DATA: "PETITION_STAGE_DATA",

  NOTIFICATION_SETUP_REQUEST: "NOTIFICATION_SETUP_REQUEST",
  NOTIFICATION_SETUP_SUCCESS: "NOTIFICATION_SETUP_SUCCESS",
  UPDATE_NOTIFICATION_REQUEST: "UPDATE_NOTIFICATION_REQUEST",
  UPDATE_NOTIFICATION_SUCCESS: "UPDATE_NOTIFICATION_SUCCESS",

  NOTIFY_DATA_REQUEST: "NOTIFY_COUNT_REQUEST",
  NOTIFY_DATA_SUCCESS: "NOTIFY_DATA_SUCCESS",

  FORGOT_PASS_LOADER_REQUEST: "FORGOT_PASS_LOADER_REQUEST",
  FORGOT_PASS_LOADER_SUCCESS: "FORGOT_PASS_LOADER_SUCCESS",

  DASH_COUNT_REQUEST: "DASH_COUNT_REQUEST",
  DASH_COUNT_SUCCESS: "DASH_COUNT_SUCCESS",

  SEARCH_ACTION_REQUEST: "SEARCH_ACTION_REQUEST",
  SEARCH_ACTION_SUCCESS: "SEARCH_ACTION_SUCCESS",

  DISPOSE_PETITION_REQUEST: "DISPOSE_PETITION_REQUEST",
  DISPOSE_PETITION_SUCCESS: "DISPOSE_PETITION_SUCCESS",

  REPORT_REQUEST: "REPORT_REQUEST",
  REPORT_SUCCESS: "REPORT_SUCCESS",
  FILTER_REPORT_REQUEST: "FILTER_REPORT_REQUEST",
  FILTER_REPORT_SUCCESS: "FILTER_REPORT_SUCCESS",
  INPUT_FILTER_DATA: "INPUT_FILTER_DATA",
  CHART_REPORT_DATA: "CHART_REPORT_DATA",

  DROP_DATA_REQUEST: "DROP_DATA_REQUEST",
  DROP_DATA_SUCCESS: "DROP_DATA_SUCCESS",
};
