import { ActionTypes } from "../ActionCreater/ActionType";

const InitialObj = {
  isloading: false,
  petitionTypeData: [],
};

const ButtonLoader = {
  BtnLoader: false,
};

export const PetitionTypeReducer = (state = InitialObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_PETITION_TYPE_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.FETCH_PETITION_TYPE_SUCCESS:
      return {
        ...state,
        isloading: false,
        petitionTypeData: payload,
      };

    default:
      return state;
  }
};

export const TypeBtnLoader = (state = ButtonLoader, { type }) => {
  switch (type) {
    case ActionTypes.POST_PETITION_TYPE_REQUEST:
      return {
        ...state,
        BtnLoader: true,
      };

    case ActionTypes.POST_PETITION_TYPE_SUCCESS:
      return {
        ...state,
        BtnLoader: false,
      };

    default:
      return state;
  }
};
