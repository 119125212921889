import { ActionTypes } from "../ActionCreater/ActionType";

const initiforgotpass = {
  isLoading: false,
};

export const forgotPassReducer = (state = initiforgotpass, { type }) => {
  switch (type) {
    case ActionTypes.FORGOT_PASS_LOADER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FORGOT_PASS_LOADER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
