import React from "react";

function Dropdown({
  HandleChange,
  DefaultValue,
  SetValue,
  setName,
  children,
  ClassName,
  onBlur,
  dropdownRef,
}) {
  return (
    <select
      ref={dropdownRef}
      className={`${ClassName}`}
      name={setName}
      value={SetValue}
      onChange={HandleChange}
      onBlur={onBlur}
    >
      <option value="" disabled selected>
        {DefaultValue}
      </option>

      {children}
    </select>
  );
}

export default Dropdown;
