const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

export function NullValidate(object) {
  let Error = {};

  if (object.email.length === 0) Error.email = "Email Field is Required";
  else if (!regex.test(object.email))
    Error.email = "This is not a valid format";

  if (object.password.length === 0)
    Error.password = "Password Field is Required";
  else if (object.password.length < 6)
    Error.password = "Length should be minimum 6";

  return Error;
}

export const UserInitialObj = {
  email: "",
  password: "",
};

// forgot password section

export const initialform = {
  to: "",
  otp: "",
  password: "",
  cnfpassword: "",
};

export const emailValidation = (obj) => {
  let error = "";

  if (obj.to.length === 0) error = "Email field is  Required!";
  else if (!regex.test(obj.to)) error = "This is not a valid format !";

  return error;
};

export const otpValidation = (obj) => {
  let error = "";

  // const otpregx = "/[0-9]{6}/";

  if (obj.otp.length === 0) error = "OTP field is  Required!";
  // else if (!otpregx.test(obj.to)) error = "This is not a valid format !";

  return error;
};

export const passwordValidation = (obj) => {
  let error = {};

  if (obj.password.length === 0)
    error.password = "Password fields is Required !";
  else if (obj.password.length < 6)
    error.password = "Password length must be greater or equal to 6 digit";

  if (obj.cnfpassword.length === 0)
    error.cnfpassword = "Confirm Password is Required !";
  else if (obj.password !== obj.cnfpassword)
    error.cnfpassword = "Password and Confirm Password doesn't match !";

  return error;
};
