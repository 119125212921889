import React from "react";

function InputField({
  type,
  name,
  onchange,
  placeholder,
  value,
  id,
  className,
  onBlur,
  max,
}) {
  return (
    <input
      className={className}
      type={type}
      name={name}
      onChange={onchange}
      placeholder={placeholder}
      value={value}
      required="required"
      autoComplete="off"
      id={id}
      onBlur={onBlur}
      min="0"
      maxLength={max}
    />
  );
}

export default InputField;
