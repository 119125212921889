import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CustomStyles, {
  paginationComponentOptions,
} from "../../Components/datatable";
import { MdModeEdit, MdDeleteForever } from "react-icons/md";
import { DeleteModal, getSerialNumber } from "../../Components/Notification";
import { bindActionCreators } from "redux";
import { usersList } from "../../Redux/Actions/globalUsage";
import Dropdown from "../../Components/Dropdown";
import { getUserListByRole } from "./addons/Validations";

function AdminUserList() {
  const [currentPage, setcurrentPage] = useState(1);
  const [Role, setRole] = useState(1);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userListRef = useRef();

  const { DeleteUser, fetchUsersData } = bindActionCreators(
    usersList,
    dispatch
  );

  // User Columns
  const UserCols = [
    {
      name: "Sr No.",
      selector: (_, i) => getSerialNumber(i, currentPage),
      width: "5rem",
    },
    {
      name: "Name",
      selector: (row) => row.user_name,
    },
    {
      name: "Display Name",
      selector: (row) => row.display_name,
    },
    {
      name: "Post Name",
      selector: (row) => row.post_name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "15rem",
    },
    {
      name: "Description",
      selector: (row) => row.dsc,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      width: "10rem",
    },
    {
      name: "Actions",
      cell: (row) => ActionUI(row.id),
      width: "10rem",
    },
  ];

  const ActionUI = (id) => {
    return (
      <>
        <div className="flex gap-x-2">
          <Link
            to={`edit/${id}`}
            type={"button"}
            className="p-2 bg-[#296aff] rounded-full text-white"
          >
            <MdModeEdit className="w-4 h-4" />
          </Link>
          <div
            className="p-2 bg-[#ff2025] rounded-full text-white cursor-pointer"
            onClick={() => HandleDeleteRequest(id)}
          >
            <MdDeleteForever className="w-4 h-4" />
          </div>
        </div>
      </>
    );
  };

  const HandleDeleteRequest = (id) => {
    DeleteModal(id, DeleteRequest);
  };

  const DeleteRequest = (id) => {
    DeleteUser(id);
  };

  const HandleUsersChange = (event) => {
    const { value } = event.target;

    setRole(parseInt(value));
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  // getting UsersList State from a hook
  const { userData } = useSelector((state) => state.userState);

  const CreateUser = () => {
    navigate("/dashboard/add");
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  return (
    <>
      <div
        className="px-4 pt-5 overflow-hidden bg-white/75"
        style={{ height: `calc(100vh - 4rem)` }}
      >
        <div className="flex justify-between items-center pb-5">
          <h1 className="font-semibold text-3xl">User List</h1>
          <button
            className="outline-none flex items-center text-white bg-gradient-to-r from-[#1f9fff] to-[#1f4fdf] rounded-[10px] py-2 px-4"
            onClick={CreateUser}
          >
            <img
              className="mr-2 h-4"
              src="https://img.icons8.com/android/20/ffffff/plus.png"
              alt=""
            />
            Create User
          </button>
        </div>

        <div className="bg-white">
          <DataTable
            columns={UserCols}
            data={userData?.filter((items) => {
              if (Role === 1) {
                return items;
              } else {
                return items.role === Role;
              }
            })}
            fixedHeader
            fixedHeaderScrollHeight="60vh"
            subHeader
            subHeaderComponent={
              <>
                <div className="w-full flex justify-start py-3">
                  <div className="w-full lg:w-auto">
                    <Dropdown
                      dropdownRef={userListRef}
                      ClassName="focus:outline-none border w-full py-2 px-4 border-black rounded-md"
                      DefaultValue={"--Select User Filter--"}
                      SetValue={Role}
                      HandleChange={HandleUsersChange}
                    >
                      {getUserListByRole(
                        localStorage.getItem("role") || 0
                      )?.map((item) => (
                        <option value={item.role} key={item.role}>
                          {item.name}
                        </option>
                      ))}
                    </Dropdown>
                  </div>
                </div>
              </>
            }
            pagination
            paginationComponentOptions={paginationComponentOptions}
            onChangePage={handlePageChange}
            customStyles={CustomStyles}
          />
        </div>
      </div>
    </>
  );
}

export default AdminUserList;
