import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../Components/Button";
import InputField from "../../../Components/InputField";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import Dropdown from "../../../Components/Dropdown";
import { NullValidate, UserObj } from "../addons/Validations";
import { bindActionCreators } from "redux";
import { roleTypeList, usersList } from "../../../Redux/Actions/globalUsage";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";

function AdminDash() {
  // Local States
  const [CreateUserObj, setCreateUserObj] = useState(UserObj);
  const [FieldError, setFieldError] = useState({});
  const [Focus, setFocus] = useState(false);

  // Variables
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");

  // Binding Action Creator Method used to wrap dispatch function with Actions
  const { PostUserData, fetchRoleType, fetchWardsData } = bindActionCreators(
    { ...usersList, ...roleTypeList },
    dispatch
  );

  // using useSelector Hook to get State from Store
  const {
    roleState: { roleData },
    wardsState: { wardsData },
    userBtnLoader: { btnLoading },
  } = useSelector((state) => state);

  const ChangeOfInput = (e) => {
    const { name, value } = e.target;
    setCreateUserObj({ ...CreateUserObj, [name]: value });
  };

  const CreateUser = () => {
    setFieldError(NullValidate(CreateUserObj));
    setFocus(true);
    if (Object.keys(NullValidate(CreateUserObj)).length === 0) {
      PostUserData(CreateUserObj, navigate);
    }
  };

  const GoBack = () => {
    navigate("/dashboard/readuser");
  };

  const SelectedValues = (e) => {
    let temp = [];

    e.filter((item, index) => {
      temp[index] = item.id;
      setCreateUserObj((prev) => ({ ...prev, ward_id: temp }));
      return temp;
    });
  };

  const RemovedValues = (e) => {
    let temp = [];

    e.filter((item, index) => {
      temp[index] = item.id;
      setCreateUserObj((prev) => ({
        ...prev,
        ward_id: temp,
      }));
      return temp;
    });

    e.length === 0 && setCreateUserObj((prev) => ({ ...prev, ward_id: temp }));
  };

  const inputFieldProps = {
    type: "text",
    onchange: ChangeOfInput,
    className:
      "p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full",
  };

  const dropdownFieldProps = {
    HandleChange: ChangeOfInput,
    ClassName:
      "p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full",
  };

  useEffect(() => {
    if (Focus) {
      if (Object.keys(NullValidate(CreateUserObj)).length !== null)
        setFieldError(NullValidate(CreateUserObj));
    }
  }, [Focus, CreateUserObj]);

  // useRunOneTime(fetchRoleType, roleData);

  // useRunOneTime(fetchWardsData, wardsData);

  useEffect(() => {
    if (wardsData.length === 0) fetchWardsData();
    if (roleData.length === 0) fetchRoleType();
  }, []);

  return (
    <>
      <div
        className="flex justify-center items-start py-2 overflow-auto bg-white/75"
        style={{ height: `calc(100vh - 4rem)` }}
      >
        <div className="w-[95%] md:w-[60%] border border-gray-300 shadow-lg rounded-t-md rounded-b-md ">
          <div className="bg-gray-300 px-4 py-2 flex justify-between items-center">
            <h1 className="text-base  font-semibold text-[#296aff] ">
              New User Registration
            </h1>
            <BsArrowLeftCircleFill
              className="text-[#296aff] text-2xl cursor-pointer"
              onClick={GoBack}
            />
          </div>
          <div className="h-full bg-white">
            <div className="w-full grid md:grid-cols-2 grid-cols-1 items-center gap-x-8 px-6 py-4">
              <div className="flex flex-col">
                <label className="text-sm text-gray-800 font-medium block pb-1">
                  User Role
                  <span className="text-red-600 font-semibold pl-1">*</span>
                </label>
                <Dropdown
                  DefaultValue={"--Select Roles--"}
                  SetValue={CreateUserObj.role}
                  setName={"role"}
                  {...dropdownFieldProps}
                >
                  {role === "1" || role === "0" ? (
                    roleData?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))
                  ) : (
                    <option value="6">officer</option>
                  )}
                </Dropdown>
                <p className="h-6 text-sm text-red-500 font-semibold py-1">
                  {FieldError.role}
                </p>
              </div>
              <div className="flex flex-col">
                <label className="text-sm text-gray-800 font-medium block pb-1">
                  User Name
                  <span className="text-red-600 font-semibold pl-1">*</span>
                </label>
                <InputField
                  {...inputFieldProps}
                  value={CreateUserObj.user_name}
                  name="user_name"
                  placeholder="Enter User Name"
                />
                <p className="h-6 text-sm text-red-500 font-semibold py-1">
                  {FieldError.user_name}
                </p>
              </div>
              <div className="flex flex-col">
                <label className="text-sm text-gray-800 font-medium block pb-1">
                  Post Name
                  <span className="text-red-600 font-semibold pl-1">*</span>
                </label>
                <InputField
                  {...inputFieldProps}
                  value={CreateUserObj.post_name}
                  name="post_name"
                  placeholder="Enter Post Name"
                />
                <p className="h-6 text-sm text-red-500 font-semibold py-1">
                  {FieldError.post_name}
                </p>
              </div>
              <div className="flex flex-col">
                <label className="text-sm text-gray-800 font-medium block pb-1">
                  Display Name
                  <span className="text-red-600 font-semibold pl-1">*</span>
                </label>
                <InputField
                  {...inputFieldProps}
                  value={CreateUserObj.display_name}
                  name="display_name"
                  placeholder="Enter Display Name"
                />
                <p className="h-6 text-sm text-red-500 font-semibold py-1">
                  {FieldError.display_name}
                </p>
              </div>
              <div className="flex flex-col">
                <label className="text-sm text-gray-800 font-medium block pb-1">
                  Email Address
                  <span className="text-red-600 font-semibold pl-1">*</span>
                </label>
                <InputField
                  {...inputFieldProps}
                  value={CreateUserObj.email}
                  name="email"
                  placeholder="Enter Email"
                />
                <p className="h-6 text-sm text-red-500 font-semibold py-1">
                  {FieldError.email}
                </p>
              </div>
              <div className="flex flex-col">
                <label className="text-sm text-gray-800 font-medium block pb-1">
                  Mobile Number
                  <span className="text-red-600 font-semibold pl-1">*</span>
                </label>
                <InputField
                  type={"text"}
                  value={CreateUserObj.mobile}
                  name="mobile"
                  onchange={inputFieldProps.onchange}
                  className={`${inputFieldProps.className}`}
                  placeholder="Enter Number"
                  max={"10"}
                />
                <p className="h-6 text-sm text-red-500 font-semibold py-1">
                  {FieldError.mobile}
                </p>
              </div>
              <div className="flex flex-col">
                <label className="text-sm text-gray-800 font-medium block pb-1">
                  Password
                  <span className="text-red-600 font-semibold pl-1">*</span>
                </label>
                <InputField
                  type={"password"}
                  value={CreateUserObj.password}
                  name="password"
                  onchange={inputFieldProps.onchange}
                  className={`${inputFieldProps.className}`}
                  placeholder="Password"
                />
                <p className="h-6 text-sm text-red-500 font-semibold py-1">
                  {FieldError.password}
                </p>
              </div>
              <div className="flex flex-col">
                <label className="text-sm text-gray-800 font-medium block pb-1">
                  Confirm Password
                  <span className="text-red-600 font-semibold pl-1">*</span>
                </label>
                <InputField
                  type={"password"}
                  value={CreateUserObj.password_confirmation}
                  name="password_confirmation"
                  onchange={inputFieldProps.onchange}
                  className={`${inputFieldProps.className}`}
                  placeholder="Confirm Password"
                />
                <p className="h-6 text-sm text-red-500 font-semibold py-1">
                  {FieldError.password_confirmation}
                </p>
              </div>

              {parseInt(CreateUserObj.role) === 5 && (
                <div className="flex flex-col md:col-span-2">
                  <label className="text-sm text-gray-800 font-medium block pb-1">
                    Wards
                    <span className="text-red-600 font-semibold pl-1">*</span>
                  </label>
                  <Multiselect
                    options={wardsData}
                    onSelect={SelectedValues}
                    onRemove={RemovedValues}
                    displayValue={"display_name"}
                    className="w-full border border-gray-500 pb-1 px-2 rounded-sm"
                    showArrow={true}
                    style={{
                      searchBox: {
                        border: "none",
                      },
                    }}
                  />
                  <p className="h-6 text-sm text-red-500 font-semibold py-1">
                    {FieldError.wards}
                  </p>
                </div>
              )}

              <div className="flex flex-col md:col-span-2">
                <label className="text-sm text-gray-800 font-medium block pb-1 outline-none">
                  Description
                  <span className="text-red-600 font-semibold pl-1">*</span>
                </label>
                <textarea
                  value={CreateUserObj.dsc}
                  name="dsc"
                  onChange={inputFieldProps.onchange}
                  className={` ${inputFieldProps.className}`}
                  placeholder="Please Enter some Description"
                />
                <p className="h-6 text-sm text-red-500 font-semibold py-1">
                  {FieldError.dsc}
                </p>
              </div>
            </div>
            <div className="w-full px-6 py-2 flex md:justify-end gap-4">
              <Button
                type={"button"}
                text={"Register"}
                isloading={btnLoading}
                onclick={CreateUser}
                className={
                  "w-full flex justify-center h-fit outline-none md:w-[15%] p-2 text-white min-w-[15%] font-medium rounded-md shadow-md bg-[#298aff]"
                }
              />
              <Button
                type={"button"}
                text={"Cancel"}
                onclick={GoBack}
                className={
                  "w-full outline-none md:w-[15%] bg-[#fa5151] p-2 min-w-fit text-white rounded-md shadow-md"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminDash;
