import { ActionTypes } from "../ActionCreater/ActionType";

const InitialObj = {
  isloading: false,
  caseslist: [],
};

const CaseButtonLoader = {
  isloading: false,
};

export const CasesList = (state = InitialObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_CASE_REQUEST:
      return { ...state, isloading: true };

    case ActionTypes.READ_CASE_LIST:
      return { ...state, isloading: false, caseslist: payload };

    default:
      return state;
  }
};

export const ButtonLoader = (state = CaseButtonLoader, { type }) => {
  switch (type) {
    case ActionTypes.POST_CASE_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.POST_CASE_SUCCESS:
      return {
        ...state,
        isloading: false,
      };

    default:
      return state;
  }
};
