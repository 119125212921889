import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { petitionList } from "../../../../Redux/Actions/globalUsage";
import DropDown from "../../../../Components/Dropdown";
import {
  DisposeDrop,
  DisposeNullValidate,
  DisposeObj,
} from "../../addons/file";
import InputField from "../../../../Components/InputField";
import Button from "../../../../Components/Button";
import Modal from "../../../../Components/modal/Modal";
import { AiOutlinePlus } from "react-icons/ai";
import DataTable from "react-data-table-component";
import CustomStyles, {
  paginationComponentOptions,
} from "../../../../Components/datatable";
import Loader2 from "../../../../Components/Loader2";
import { FaFolderOpen } from "react-icons/fa";
import {
  DisposeModal,
  getSerialNumber,
} from "../../../../Components/Notification";
import ActiveComponents from "../../../../Components/ActiveComponents";

const Dispose = () => {
  const [DisposeData, setDisposeData] = useState(DisposeObj);
  const [IsOpen, setIsOpen] = useState(false);
  const [FormError, setFormError] = useState({});
  const [focus, setFocus] = useState(false);
  const [showUploadBox, setShowUploadBox] = useState(false);
  const [UploadData, setUploadData] = useState({ percent: 0 });
  const [currentPage, setcurrentPage] = useState(1);

  const { id } = useParams();
  const fileRef = useRef();
  const dispatch = useDispatch();
  const {
    DisposeData: DisposeAction,
    DisposePetition,
    reopenPetition,
  } = bindActionCreators(petitionList, dispatch);
  const {
    DisposeState: { isloading, disposeData },
    petitionLoader: { filterIsloading },
  } = useSelector((state) => state);

  const { fetchSingleRead } = bindActionCreators({ ...petitionList }, dispatch);

  const { data: singlePetitionData } = useSelector(
    (state) => state.singlePetitionState
  );

  const DisposeCols = [
    {
      name: "Sr No.",
      selector: (_, i) => getSerialNumber(i, currentPage),
      width: "10rem",
    },
    {
      name: "User Name",
      selector: (row) => row.user_name,
    },
    {
      name: "Dispose Type",
      selector: (row) => (row.type === 1 ? "Close" : "Contempt Compliance"),
    },
    {
      name: "Date",
      selector: (row) => row.date || "N/A",
    },
    {
      name: "Remark",
      selector: (row) => row.remark || "N/A",
    },
    // {
    //   name: "Actions",
    //   cell: (row) => ActionUI(row),
    //   width: "10rem",
    // },
  ];

  // const ActionUI = (row) => {
  //   console.log(row);
  //   return (
  //     <>
  //       <div className="flex gap-x-2">
  //         <div
  //           onClick={() => {
  //             DisposeModal(() => {
  //               reopenPetition(row.id, () => {
  //                 DisposeAction(id);
  //               });
  //             });
  //           }}
  //           className="p-2 bg-[#296aff] rounded-full text-white cursor-pointer"
  //         >
  //           <FaFolderOpen className="w-4 h-4" title="reopen" />
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  const ChangeOfInput = (e) => {
    const { name, value } = e.target;
    setDisposeData({ ...DisposeData, [name]: value, petition_id: id });
  };

  const HandleFile = (e) => {
    const { files } = e.target;
    const file = files[0];
    if (file) {
      setDisposeData({ ...DisposeData, file: file });
    }
  };

  const dropdownFieldProps = {
    HandleChange: ChangeOfInput,
    ClassName:
      "p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full",
  };

  const DateFieldProps = {
    onChange: ChangeOfInput,
    className:
      "p-2 border border-gray-500 outline-none text-gray-500 text-sm rounded-sm",
  };

  const inputFieldProps = {
    onchange: ChangeOfInput,
    className:
      "p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full",
  };

  const HandleDispose = () => {
    setFormError(DisposeNullValidate(DisposeData));
    setFocus(true);

    let fd = new FormData();

    Object.entries(DisposeData).map((item) => {
      fd.append(item[0], item[1]);
    });

    if (Object.keys(DisposeNullValidate(DisposeData)).length === 0) {
      setShowUploadBox(true);
      DisposePetition(
        fd,
        () => {
          DisposeAction(id);
          setIsOpen(false);
          fetchSingleRead(id);
        },
        setUploadData
      );
    }
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    DisposeAction(id);
  }, []);

  useEffect(() => {
    if (focus) {
      if (Object.keys(DisposeNullValidate(DisposeData)).length !== null)
        setFormError(DisposeNullValidate(DisposeData));
    }
  }, [focus, DisposeData]);

  return (
    <div
      className="px-4 pt-5 w-full overflow-hidden bg-white/75"
      style={{ height: `calc(100vh - 10.5rem)` }}
    >
      {/* Dispose Modal */}
      <Modal isOpen={IsOpen} setisOpen={setIsOpen}>
        <div className="w-full rounded-t-md overflow-hidden">
          <div className="p-2 bg-gray-300 font-semibold text-base text-[#174bff] w-full flex justify-between items-center">
            <h1 className="capitalize">dispose petition</h1>
          </div>

          <div className="bg-white p-2 h-full">
            <div className="flex flex-col px-5 pt-2">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                Type
                <span className="text-red-600 font-semibold pl-1">*</span>
              </label>
              <DropDown
                setName={"type"}
                DefaultValue={"--Select Type--"}
                SetValue={DisposeData.type}
                {...dropdownFieldProps}
              >
                {DisposeDrop?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </DropDown>
              <p className="h-6 text-sm text-red-500 font-semibold py-1">
                {FormError.type}
              </p>
            </div>
            <div className="flex flex-col px-5 pt-2">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                Attachment Type
                <span className="text-red-600 font-semibold pl-1">*</span>
              </label>
              <DropDown
                setName={"attachment_type"}
                SetValue={DisposeData.attachment_type}
                DefaultValue={"--Select Attachment Type--"}
                {...dropdownFieldProps}
              >
                <option value="8">Dispose</option>
              </DropDown>
              <p className="h-6 text-sm text-red-500 font-semibold py-1">
                {FormError.attachment_type}
              </p>
            </div>
            {DisposeData?.type === "2" && (
              <div className="flex flex-col px-5 py-2">
                <label className="text-sm text-gray-800 font-medium block pb-1">
                  Date
                  <span className="text-red-600 font-semibold pl-1">*</span>
                </label>
                <input
                  type="date"
                  name="date"
                  value={DisposeData.date}
                  {...DateFieldProps}
                />
                <p className="h-6 text-sm text-red-500 font-semibold py-1">
                  {FormError.date}
                </p>
              </div>
            )}
            <div className="flex flex-col px-5 py-2">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                Attachment
                <span className="text-red-600 font-semibold pl-1">*</span>
              </label>
              <input
                type="file"
                ref={fileRef}
                accept={"application/pdf"}
                onChange={HandleFile}
                name="attachment"
                className="p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full"
              />
              <p className="h-6 text-sm text-red-500 font-semibold py-1">
                {FormError.file}
              </p>
              {showUploadBox && (
                <>
                  <div className="w-full bg-blue-400 rounded-md p-2 mt-2">
                    <div className="text-white font-semibold flex justify-between">
                      <p>
                        {UploadData.percent === 100
                          ? "File Uploaded Successfully"
                          : UploadData.percent === 0
                          ? `${
                              DisposeData.file ? DisposeData.file.name : "N/A"
                            }`
                          : "Uploading..."}
                      </p>
                      <p>{UploadData.percent}%</p>
                    </div>
                    <div className="w-full h-2 mt-2 bg-white rounded-sm">
                      <div
                        className={`h-full bg-blue-500 rounded-sm`}
                        style={{ width: `${UploadData.percent}%` }}
                      ></div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col px-5 py-2">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                Remark
              </label>
              <InputField
                name={"remark"}
                value={DisposeData.remark}
                placeholder={"Please Enter a remark"}
                {...inputFieldProps}
              />
            </div>
          </div>
          <div className="px-7 py-4 flex justify-end">
            <Button
              type={"button"}
              text={"Submit"}
              isloading={filterIsloading}
              onclick={HandleDispose}
              className={
                "w-full flex justify-center items-center outline-none md:w-[20%] p-2 text-white min-w-fit font-medium rounded-md shadow-md bg-[#298aff] "
              }
            />
          </div>
        </div>
      </Modal>
      {/* DataTable */}
      <div>
        <div className="flex items-center justify-between pb-5">
          <h1 className="capitalize font-bold text-2xl">disposed petition</h1>
          <div>
            {!singlePetitionData?.active && (
              <button
                onClick={() => {
                  DisposeModal(() => {
                    reopenPetition(id, () => {
                      DisposeAction(id);
                      fetchSingleRead(id);
                    });
                  });
                }}
                className="p-2 bg-[#296aff] rounded-md flex  items-center gap-2 text-white cursor-pointer"
              >
                <FaFolderOpen className="w-4 h-4" title="reopen" /> Reopen
              </button>
            )}
            <ActiveComponents active={singlePetitionData?.active}>
              <button
                onClick={() => {
                  setIsOpen(true);
                  setDisposeData(DisposeObj);
                  setShowUploadBox(false);
                  setFocus(false);
                  setUploadData({ percent: 0 });
                }}
                className="outline-none capitalize flex items-center text-white bg-gradient-to-r from-[#1f9fff] to-[#1f4fdf] rounded-[10px] py-2 px-4"
              >
                <AiOutlinePlus className=" text-xl" />
                Dispose
              </button>
            </ActiveComponents>
          </div>
        </div>
        {isloading ? (
          <div className="w-full flex justify-center">
            <Loader2 />
          </div>
        ) : (
          <div>
            <DataTable
              columns={DisposeCols}
              data={disposeData}
              paginationComponentOptions={paginationComponentOptions}
              customStyles={CustomStyles}
              pagination
              onChangePage={handlePageChange}
              fixedHeader
              fixedHeaderScrollHeight="46vh"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dispose;
