import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import MainLayout from "./Components/MainLayout";
import Login from "./Screens/Login/Login";
import Panels from "./Screens/Panels/Panels";
import AdminDash from "./Screens/Admin Dashboard/Crud/AdminDash";
import AdminUserList from "./Screens/Admin Dashboard/AdminUserList";
import Updateuser from "./Screens/Admin Dashboard/Crud/Updateuser";
import CaseList from "./Screens/masters/case list/CaseList";
import CreateCase from "./Screens/masters/case list/crud/CreateCase";
import CourtList from "./Screens/masters/court list/CourtList";
import CreateCourt from "./Screens/masters/court list/crud/CreateCourt";
import DepartmentList from "./Screens/masters/department list/DepartmentList";
import CreateDept from "./Screens/masters/department list/crud/CreateDept";
import RegionList from "./Screens/masters/region list/RegionList";
import CreateRegion from "./Screens/masters/region list/crud/CreateRegion";
import NotificationSetup from "./Screens/masters/notification/NotificationSetup";
import HomePage from "./Screens/homepage/HomePage";
import UpdateCase from "./Screens/masters/case list/crud/UpdateCase";
import PageNotFound from "./Components/PageNotFound";
import Petition from "./Screens/petition/Petition";
import CreatePetition from "./Screens/petition/crud/CreatePetition";
import UpdateCourt from "./Screens/masters/court list/crud/UpdateCourt";
import UpdatePetition from "./Screens/petition/crud/common/UpdatePetition";
import UpdateRegion from "./Screens/masters/region list/crud/UpdateRegion";
import CaseStage from "./Screens/masters/case stage/CaseStage";
import CreateStage from "./Screens/masters/case stage/crud/CreateStage";
import UpdateStage from "./Screens/masters/case stage/crud/UpdateStage";
import AddRole from "./Screens/masters/role Type/crud/AddRole";
import RoleType from "./Screens/masters/role Type/RoleType";
import UpdateRole from "./Screens/masters/role Type/crud/UpdateRole";
import Attachment from "./Screens/masters/attachments/Attachment";
import AddAttachment from "./Screens/masters/attachments/crud/AddAttachment";
import UpdateAttachment from "./Screens/masters/attachments/crud/UpdateAttachment";
// import ViewReport from "./Screens/extra/ViewReport";
import PetitionType from "./Screens/masters/petition type/PetitionType";
import AddPetitionType from "./Screens/masters/petition type/crud/AddPetitionType";
import UpdatePetitionType from "./Screens/masters/petition type/crud/UpdatePetitionType";
import EditPetition from "./Screens/petition/crud/EditPetition";
import Remark from "./Screens/petition/crud/common/Remark";
import PetitionLogs from "./Screens/petition/crud/common/PetitionLogs";
import Dispose from "./Screens/petition/crud/common/Dispose";
import Assign from "./Screens/petition/crud/common/Assign";
import Documents from "./Screens/petition/crud/common/Documents";
import ImportantDates from "./Screens/petition/crud/common/ImportantDates";
import DateType from "./Screens/masters/date type/DateType";
import AddDateType from "./Screens/masters/date type/crud/AddDateType";
import UpdateDateType from "./Screens/masters/date type/crud/UpdateDateType";
import ForgotPassword from "./Screens/Login/ForgotPassword";
import NotificationCounts from "./Screens/notification counts/NotificationCounts";
import Denied from "./Components/Denied";
import ProtectedRoutes from "./Components/ProtectedRoutes";
import Stage from "./Screens/petition/crud/common/Stage";
import Report from "./Screens/extra/msi report/Report";
import DrillDown from "./Screens/extra/msi report/Tabs/DrillDown";
import Pendingdashboard from "./Screens/extra/msi report/Tabs/Pendingdashboard";
import Alert from "./Screens/extra/msi report/Tabs/Alert";
import Test from "./Test";
import Details from "./Screens/petition/crud/common/Details";
import StickyNote from "./Screens/petition/crud/common/details/StickyNote";
import CheckList from "./Screens/masters/checklist/CheckList";
import CreateCheckList from "./Screens/masters/checklist/crud/CreateCheckList";
import UpdateCheckList from "./Screens/masters/checklist/crud/UpdateCheckList";

function App() {
  const ROLES = {
    super_admin: "0",
    admin: "1",
    legal_dept: "2",
    dept: "3",
    ward: "4",
    lawyer: "5",
    sub: "6",
    parent_role: "2",
  };

  return (
    <>
      <ToastContainer position="top-right" />

      <Routes>
        {/* Public Routes */}
        <Route index path="/" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />

        {/* Protected Routes */}
        <Route element={<MainLayout />}>
          <Route path="/dashboard" element={<Panels />}>
            <Route
              element={
                <ProtectedRoutes
                  allowedRoles={[
                    ROLES.super_admin,
                    ROLES.admin,
                    ROLES.legal_dept,
                    ROLES.dept,
                    ROLES.ward,
                    ROLES.lawyer,
                    ROLES.sub,
                  ]}
                />
              }
            >
              <Route index path="homepage" element={<HomePage />} />
            </Route>

            <Route path="petitionlist" element={<Petition />} />
            <Route
              element={
                <ProtectedRoutes
                  allowedRoles={[
                    ROLES.super_admin,
                    ROLES.admin,
                    ROLES.legal_dept,
                    ROLES.sub,
                  ]}
                />
              }
            >
              <Route path="createpetition" element={<CreatePetition />} />
            </Route>
            <Route
              element={
                <ProtectedRoutes
                  allowedRoles={[
                    ROLES.super_admin,
                    ROLES.admin,
                    ROLES.legal_dept,
                    ROLES.dept,
                    ROLES.sub,
                    ROLES.ward,
                    ROLES.lawyer,
                  ]}
                />
              }
            >
              <Route element={<EditPetition />}>
                <Route
                  path="petitionlist/edit/:id"
                  element={<UpdatePetition />}
                />
                <Route path="petitionlist/details/:id" element={<Details />} />

                <Route
                  element={
                    <ProtectedRoutes
                      allowedRoles={[
                        ROLES.super_admin,
                        ROLES.admin,
                        ROLES.legal_dept,
                      ]}
                      allowedParentRoles={[ROLES.parent_role]}
                    />
                  }
                >
                  {/* EDIT PETTITION WAS HERE */}
                </Route>

                <Route path="petitionlist/remarks/:id" element={<Remark />} />
                <Route path="petitionlist/stage/:id" element={<Stage />} />

                <Route
                  path="petitionlist/logs/:id"
                  element={<PetitionLogs />}
                />
                <Route path="petitionlist/dispose/:id" element={<Dispose />} />
                <Route
                  element={
                    <ProtectedRoutes
                      allowedRoles={[
                        ROLES.super_admin,
                        ROLES.admin,
                        ROLES.legal_dept,
                        ROLES.dept,
                      ]}
                    />
                  }
                ></Route>
                <Route path="petitionlist/assign/:id" element={<Assign />} />
                <Route
                  path="petitionlist/document/:id"
                  element={<Documents />}
                />
                <Route
                  path="petitionlist/importantdate/:id"
                  element={<ImportantDates />}
                />
              </Route>
            </Route>

            <Route
              element={
                <ProtectedRoutes
                  allowedRoles={[
                    ROLES.super_admin,
                    ROLES.admin,
                    ROLES.legal_dept,
                    ROLES.dept,
                    ROLES.ward,
                    ROLES.lawyer,
                  ]}
                />
              }
            >
              <Route path="readuser" element={<AdminUserList />} />
              <Route path="add" element={<AdminDash />} />
              <Route path="readuser/edit/:id" element={<Updateuser />} />
            </Route>

            <Route
              element={
                <ProtectedRoutes
                  allowedRoles={[
                    ROLES.super_admin,
                    ROLES.admin,
                    ROLES.legal_dept,
                  ]}
                />
              }
            >
              <Route path="caselist" element={<CaseList />} />
              <Route path="createcaselist" element={<CreateCase />} />
              <Route path="caselist/edit/:id" element={<UpdateCase />} />

              <Route path="petitiontype" element={<PetitionType />} />
              <Route path="petitioncreate" element={<AddPetitionType />} />
              <Route
                path="petitiontype/edit/:id"
                element={<UpdatePetitionType />}
              />

              <Route path="casestage" element={<CaseStage />} />
              <Route path="createstage" element={<CreateStage />} />
              <Route path="casestage/edit/:id" element={<UpdateStage />} />

              <Route path="datetype" element={<DateType />} />
              <Route path="createdatetype" element={<AddDateType />} />
              <Route path="datetype/edit/:id" element={<UpdateDateType />} />

              <Route path="roletypes" element={<RoleType />} />
              <Route path="createrole" element={<AddRole />} />
              <Route path="roletypes/edit/:id" element={<UpdateRole />} />

              <Route path="attachments" element={<Attachment />} />
              <Route path="createattachment" element={<AddAttachment />} />
              <Route
                path="attachments/edit/:id"
                element={<UpdateAttachment />}
              />

              <Route path="courtlist" element={<CourtList />} />
              <Route path="createcourt" element={<CreateCourt />} />
              <Route path="courtlist/edit/:id" element={<UpdateCourt />} />

              <Route path="department" element={<DepartmentList />} />
              <Route path="createdepartment" element={<CreateDept />} />

              <Route path="regionlist" element={<RegionList />} />
              <Route path="createregion" element={<CreateRegion />} />
              <Route path="regionlist/edit/:id" element={<UpdateRegion />} />

              <Route path="createCheckList" element={<CreateCheckList />} />
              <Route path="checklist/edit/:id" element={<UpdateCheckList />} />
              <Route path="checklist" element={<CheckList />} />

              <Route path="notification" element={<NotificationSetup />} />
            </Route>

            <Route
              element={
                <ProtectedRoutes
                  allowedRoles={[
                    ROLES.super_admin,
                    ROLES.admin,
                    ROLES.legal_dept,
                    ROLES.dept,
                    ROLES.ward,
                    ROLES.lawyer,
                    ROLES.sub,
                  ]}
                />
              }
            >
              {/* <Route path="report" element={<ViewReport />} /> */}
              <Route path="msireport" element={<Report />}>
                <Route index path="index" element={<DrillDown />} />
                <Route path="pendingdashboard" element={<Pendingdashboard />} />
                <Route path="dispose" element={<Pendingdashboard />} />
                <Route path="alert" element={<Alert />} />
              </Route>
            </Route>

            <Route
              element={
                <ProtectedRoutes
                  allowedRoles={[
                    ROLES.super_admin,
                    ROLES.admin,
                    ROLES.legal_dept,
                    ROLES.dept,
                    ROLES.ward,
                    ROLES.lawyer,
                    ROLES.sub,
                  ]}
                />
              }
            >
              <Route
                path="notificationCounts"
                element={<NotificationCounts />}
              />
            </Route>

            <Route path="denied" element={<Denied />} />
          </Route>
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
