import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import InputField from "../../../../Components/InputField";
import Button from "../../../../Components/Button";
import { dateObj, NullValidate } from "../addon/file";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { DateTypeAction } from "../../../../Redux/Actions/globalUsage";
import { useParams } from "react-router-dom";

function UpdateDateType() {
  // Local States
  const [UpdateState, setUpdateState] = useState(dateObj);
  const [FieldError, setFieldError] = useState({});
  const [Focus, setFocus] = useState(false);

  // Variables
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { UpdateDateType, SingleReadDateType } = bindActionCreators(
    DateTypeAction,
    dispatch
  );

  const { loading } = useSelector((state) => state.dateTypeLoader);

  const GoBack = () => {
    navigate("/dashboard/datetype");
  };

  const HandleChangeInput = (e) => {
    const { name, value } = e.target;
    setUpdateState({ ...UpdateState, [name]: value });
  };

  // Props For InputFIeld
  const InputFieldProps = {
    className:
      "p-2 outline-none border border-gray-500 placeholder:text-gray-500 placeholder:capitalize rounded-sm text-gray-500 text-sm w-full",
    onchange: HandleChangeInput,
  };

  const HandleCreateDateType = () => {
    setFieldError(NullValidate(UpdateState));
    setFocus(true);
    if (Object.keys(NullValidate(UpdateState)).length === 0) {
      UpdateDateType(id, UpdateState, navigate);
    }
  };

  useEffect(() => {
    if (Focus) {
      if (Object.keys(NullValidate(UpdateState)).length !== null)
        setFieldError(NullValidate(UpdateState));
    }
  }, [Focus, UpdateState]);

  useEffect(() => {
    SingleReadDateType(id, setUpdateState);

    return () => {
      setUpdateState(dateObj);
    };
  }, [id]);

  return (
    <div
      className="w-full overflow-hidden flex justify-center items-start pt-5 bg-white/75"
      style={{
        height: `calc(100vh - 4rem)`,
      }}
    >
      <div className="w-[95%] md:w-1/2  border border-gray-300 rounded-t-md overflow-hidden">
        <div className="p-2 bg-gray-300 font-semibold text-base text-[#174bff] w-full flex justify-between items-center">
          <h1 className="capitalize">update date type</h1>
          <BsArrowLeftCircleFill
            className="text-[#296aff] text-2xl cursor-pointer"
            onClick={GoBack}
          />
        </div>
        <div className="bg-white h-full p-2">
          <div className="w-full grid gap-4 p-2">
            <div className="flex flex-col gap-1">
              <label className="capitalize text-sm text-gray-800 font-medium">
                date type name
              </label>
              <InputField
                {...InputFieldProps}
                name="name"
                value={UpdateState.name}
                placeholder={"enter date type name"}
              />
              <p className="text-sm font-bold h-5 text-red-500">
                {FieldError.name}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <label className="capitalize text-sm text-gray-800 font-medium">
                remark
              </label>
              <InputField
                {...InputFieldProps}
                name="remark"
                value={UpdateState.remark}
                placeholder={"enter remark"}
              />
              <p className="text-sm font-bold h-5 text-red-500">
                {FieldError.remark}
              </p>
            </div>
            <div className="w-full flex gap-4 justify-end">
              <Button
                type={"button"}
                text={"update"}
                isloading={loading}
                className={
                  "w-full flex justify-center h-fit outline-none bg-[#298aff] p-2 text-white rounded-md shadow-md min-w-[15%] md:w-[15%] font-medium capitalize"
                }
                onclick={HandleCreateDateType}
              />
              <Button
                type={"button"}
                text={"cancel"}
                onclick={GoBack}
                className={
                  "w-full outline-none bg-[#fa5151] p-2 text-white rounded-md shadow-md min-w-fit md:w-[15%] font-medium capitalize"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateDateType;
