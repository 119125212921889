export const items = [
  {
    title: "petition",
    id: 1,
    subitem: [
      // {
      //   name: "new petition",
      //   routes: "dashboard/createpetition",
      // },
      {
        name: "petition list",
        routes: "dashboard/petitionlist",
      },
    ],
  },
  {
    title: "master",
    id: 2,
    subitem: [
      {
        name: "create case",
        routes: "dashboard/createcaselist",
      },
      {
        name: "show case list",
        routes: "dashboard/caselist",
      },
      {
        name: "create court",
        routes: "dashboard/createcourt",
      },
      {
        name: "show court list",
        routes: "dashboard/courtlist",
      },
      {
        name: "create petition type",
        routes: "dashboard/petitioncreate",
      },
      {
        name: "show petition type",
        routes: "dashboard/petitiontype",
      },
      {
        name: "create case stage",
        routes: "dashboard/createstage",
      },
      {
        name: "show case stage",
        routes: "dashboard/casestage",
      },
      {
        name: "create role",
        routes: "dashboard/createrole",
      },
      {
        name: "show role type",
        routes: "dashboard/roletypes",
      },
      {
        name: "create attachment",
        routes: "dashboard/createattachment",
      },
      {
        name: "show attachment",
        routes: "dashboard/attachments",
      },
      {
        name: "create date type",
        routes: "dashboard/createdatetype",
      },
      {
        name: "show date type",
        routes: "dashboard/datetype",
      },
      {
        name: "create checklist",
        routes: "dashboard/createCheckList",
      },
      {
        name: "show checklist",
        routes: "dashboard/checklist",
      },
      // {
      //   name: "create department",
      //   routes: "dashboard/createdepartment",
      // },
      // {
      //   name: "show department list",
      //   routes: "dashboard/department",
      // },
      {
        name: "create region",
        routes: "dashboard/createregion",
      },
      {
        name: "show region list",
        routes: "dashboard/regionlist",
      },
      {
        name: "notification setup",
        routes: "dashboard/notification",
      },
    ],
  },
  {
    title: "User",
    id: 3,
    subitem: [
      {
        name: "create user",
        routes: "dashboard/add",
      },
      {
        name: "users list",
        routes: "dashboard/readuser",
      },
    ],
  },
  {
    title: "Extra",
    id: 4,
    subitem: [
      // {
      //   name: "view report",
      //   routes: "dashboard/report",
      // },
      {
        name: "Report",
        routes: "dashboard/msireport/index",
      },
    ],
  },
];

export const NotAdminItems = [
  {
    title: "petition",
    id: 1,
    subitem: [
      // {
      //   name: "new petition",
      //   routes: "dashboard/createpetition",
      // },
      {
        name: "petition list",
        routes: "dashboard/petitionlist",
      },
    ],
  },
  {
    title: "User",
    id: 2,
    subitem: [
      {
        name: "create user",
        routes: "dashboard/add",
      },
      {
        name: "users list",
        routes: "dashboard/readuser",
      },
    ],
  },
  {
    title: "Extra",
    id: 3,
    subitem: [
      // {
      //   name: "view report",
      //   routes: "dashboard/report",
      // },
      {
        name: "Report",
        routes: "dashboard/msireport/index",
      },
    ],
  },
];

export const ForSubItems = [
  {
    title: "petition",
    subitem: [
      // {
      //   name: "new petition",
      //   routes: "dashboard/createpetition",
      // },
      {
        name: "petition list",
        routes: "dashboard/petitionlist",
      },
    ],
  },
  // {
  //   title: "User",
  //   subitem: [
  //     {
  //       name: "create user",
  //       routes: "dashboard/add",
  //     },
  //     {
  //       name: "users list",
  //       routes: "dashboard/readuser",
  //     },
  //   ],
  // },
  {
    title: "Extra",
    subitem: [
      // {
      //   name: "view report",
      //   routes: "dashboard/report",
      // },
      {
        name: "Report",
        routes: "dashboard/msireport/index",
      },
    ],
  },
];
