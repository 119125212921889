import {
  ShowErrorAlert,
  ShowSuccessAlert,
} from "../../Components/Notification";
import { GET, PUT } from "../../Utils/Helper";
import { ActionTypes } from "../ActionCreater/ActionType";

// function to send fetch request
const fetchRequest = () => {
  return {
    type: ActionTypes.NOTIFICATION_SETUP_REQUEST,
  };
};

// function after successfull fetching request
const fetchSuccess = () => {
  return {
    type: ActionTypes.NOTIFICATION_SETUP_SUCCESS,
  };
};

// function to set Btn loader to true
const UpdateRequest = () => {
  return {
    type: ActionTypes.UPDATE_NOTIFICATION_REQUEST,
  };
};

// function to set Btn loader to false
const UpdateSuccess = () => {
  return {
    type: ActionTypes.UPDATE_NOTIFICATION_SUCCESS,
  };
};

// Thunk function to fetch notification setup
export const FetchNotificationSetup = (setMethod) => {
  return async (dispatch) => {
    dispatch(fetchRequest());
    await GET("superAdmin/notificationSetup")
      .then((res) => {
        res?.data?.map((item) => {
          setMethod(item);
        });
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.message)))
      .finally(() => dispatch(fetchSuccess()));
  };
};

// Function to Update Notification Setup Data
export const UpdateNotificationSetup = (id, obj, navigate) => {
  return async (dispatch) => {
    dispatch(UpdateRequest());
    PUT(`superAdmin/notificationSetup/update/${id}`, obj)
      .then((res) => {
        if (res.statusCode === 200) {
          ShowSuccessAlert(res.message);
          navigate("/dashboard/caselist");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => dispatch(UpdateSuccess()));
  };
};
