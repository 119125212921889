import React, { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible, AiOutlineMail } from "react-icons/ai";
import { BsArrowLeft } from "react-icons/bs";
import { FiKey } from "react-icons/fi";
// import { VscPass } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import Button from "../../Components/Button";
import InputField from "../../Components/InputField";
import { ChangePassword } from "../../Redux/Actions/globalUsage";
import {
  emailValidation,
  initialform,
  otpValidation,
  passwordValidation,
} from "./addon/auth";

const ForgotPassword = () => {
  const [section, setsection] = useState(0);
  const [show, setshow] = useState({ password: false, cnfpassword: false });
  const [focus, setfocus] = useState(false);

  const [formData, setformData] = useState(initialform);
  const [emailError, setemailError] = useState("");

  const [otpError, setotpError] = useState("");

  const [passwordError, setpasswordError] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { sendEmail, verifyOTP, ResetPassword } = bindActionCreators(
    ChangePassword,
    dispatch
  );

  const { isLoading } = useSelector((state) => state.forgotState);

  const ChangeOfInput = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  const inputFieldProps = {
    onchange: ChangeOfInput,
    className:
      "p-2 outline-none border border-gray-400 rounded-sm text-gray-500 text-sm w-full",
  };

  const goBack = () => {
    return (
      <button
        disabled={isLoading}
        onClick={() => {
          navigate("/");
        }}
        className="flex justify-center items-center gap-2 font-semibold hover:text-blue-700"
      >
        <BsArrowLeft className="text-lg" />
        <span>Back to log in</span>
      </button>
    );
  };

  const getOtp = () => {
    setfocus(true);
    setemailError(emailValidation(formData));
    if (emailValidation(formData).length === 0) {
      sendEmail(formData, setsection);
      setfocus(false);
    }
  };

  const verify = () => {
    setfocus(true);
    setotpError(otpValidation(formData));
    if (otpValidation(formData).length === 0) {
      verifyOTP(formData, setsection);
      setfocus(false);
    }
  };

  const updatePassword = () => {
    setfocus(true);
    setpasswordError(passwordValidation(formData));
    if (Object.keys(passwordValidation(formData)).length === 0) {
      ResetPassword(formData, navigate);
      setfocus(false);
    }
  };

  useEffect(() => {
    if (focus) {
      if (emailValidation(formData).length >= 0) {
        setemailError(emailValidation(formData));
      }

      if (otpValidation(formData).length >= 0) {
        setotpError(otpValidation(formData));
      }

      if (Object.keys(passwordValidation(formData)).length !== null) {
        setpasswordError(passwordValidation(formData));
      }
    }
  }, [focus, formData]);

  return (
    <div className="bg-[#f1f1f1] w-full min-h-screen flex justify-center items-center">
      {/* FIRST SECTION */}
      {section === 0 && (
        <div className="formBox formBox-container">
          <div className="bg-blue-300 border-8 border-blue-200 rounded-full p-3 animate-bounce">
            <FiKey className="text-blue-600 text-3xl" />
          </div>
          <h3 className="font-bold text-xl lg:text-2xl">Forgot Password ?</h3>
          <p className="text-slate-500 text-center">
            No worries, we'll send you reset instructions.
          </p>
          <div className="flex flex-col w-[90%] lg:w-1/2">
            <label className="text-sm text-gray-800 font-medium block pb-1">
              Email
              <span className="text-red-600 font-semibold pl-1">*</span>
            </label>
            <InputField
              type={"text"}
              {...inputFieldProps}
              value={formData.to}
              name="to"
              placeholder="Enter your email"
            />
            <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
              {emailError}
            </p>
          </div>

          <Button
            type={"button"}
            text={"Send OTP"}
            onclick={getOtp}
            isloading={isLoading}
            className={
              "w-[90%] lg:w-1/2 flex justify-center items-center outline-none p-2 text-white min-w-fit font-medium rounded-md shadow-md hover:bg-blue-600 bg-[#298aff] "
            }
          />
          <Link
            to="/"
            className="flex justify-center items-center gap-2 font-semibold hover:text-blue-700"
          >
            <BsArrowLeft className="text-lg" />
            <span>Back to log in</span>
          </Link>
        </div>
      )}

      {/* SECOND SECTION */}
      {section === 1 && (
        <div className="formBox formBox-container">
          <div className="bg-blue-300 border-8 border-blue-200 rounded-full p-3 animate-bounce">
            <AiOutlineMail className="text-blue-600 text-3xl" />
          </div>
          <h3 className="font-bold text-xl lg:text-2xl">Check your email</h3>
          <p className="text-slate-500 text-center">
            We sent a OTP to {formData.to}
          </p>
          <div className="flex flex-col w-[90%] lg:w-1/2">
            <label className="text-sm text-gray-800 font-medium block pb-1">
              OTP
              <span className="text-red-600 font-semibold pl-1">*</span>
            </label>
            <InputField
              type={"number"}
              {...inputFieldProps}
              value={formData.otp}
              name="otp"
              placeholder="Enter your OTP"
            />
            <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
              {otpError}
            </p>
          </div>

          <Button
            type={"button"}
            text={"Submit"}
            onclick={verify}
            isloading={isLoading}
            className={
              "w-[90%] lg:w-1/2 flex justify-center items-center outline-none p-2 text-white min-w-fit font-medium rounded-md shadow-md hover:bg-blue-600 bg-[#298aff] "
            }
          />
          <div className="text-center">
            <span>Didn't receive the email ?</span>
            <button
              disabled={isLoading}
              onClick={() => {
                sendEmail(formData, setsection);
              }}
              className={` ${
                isLoading && "cursor-not-allowed"
              } text-blue-700 font-bold pl-2`}
            >
              Click to resend
            </button>
          </div>
          {goBack()}
        </div>
      )}

      {/* THIRD SECTION */}
      {section === 2 && (
        <div className="formBox formBox-container">
          <div className="bg-blue-300 border-8 border-blue-200 rounded-full p-3 animate-bounce">
            <FiKey className="text-blue-600 text-3xl" />
          </div>
          <h3 className="font-bold text-xl lg:text-2xl">Set new password</h3>
          <p className="text-slate-500 text-center">
            Your new password must be different to previously used passwords.
          </p>
          <div className="flex flex-col w-[90%] lg:w-1/2">
            <label className="text-sm text-gray-800 font-medium block pb-1">
              New Password
              <span className="text-red-600 font-semibold pl-1">*</span>
            </label>
            <div className=" border border-gray-400 bg-white flex items-center px-2 ">
              <InputField
                type={show.password ? "text" : "password"}
                onchange={ChangeOfInput}
                className={
                  "p-2 outline-none  rounded-sm text-gray-500 text-sm w-full"
                }
                value={formData.password}
                name="password"
                placeholder="Enter your New Password"
              />

              <div
                onClick={() => {
                  setshow({ ...show, password: !show.password });
                }}
                className="text-blue-500 text-xl cursor-pointer"
              >
                {show.password ? <AiFillEye /> : <AiFillEyeInvisible />}
              </div>
            </div>
            <p className=" capitalize  h-6 text-sm text-red-500 font-semibold pt-1 pb-7">
              {passwordError.password}
            </p>
          </div>

          <div className="flex flex-col w-[90%] lg:w-1/2">
            <label className="text-sm text-gray-800 font-medium block pb-1">
              Confirm Password
              <span className="text-red-600 font-semibold pl-1">*</span>
            </label>
            <div className="border border-gray-400 flex  items-center bg-white px-2">
              <InputField
                type={show.cnfpassword ? "text" : "password"}
                onchange={ChangeOfInput}
                className={
                  "p-2 outline-none  rounded-sm text-gray-500 text-sm w-full"
                }
                value={formData.cnfpassword}
                name="cnfpassword"
                placeholder="Enter your Confirm Password"
              />
              <div
                onClick={() => {
                  setshow({ ...show, cnfpassword: !show.cnfpassword });
                }}
                className="text-blue-500 text-xl cursor-pointer"
              >
                {show.cnfpassword ? <AiFillEye /> : <AiFillEyeInvisible />}
              </div>
            </div>
            <p className=" capitalize  h-6 text-sm text-red-500 font-semibold pt-1 pb-7">
              {passwordError.cnfpassword}
            </p>
          </div>

          <Button
            type={"button"}
            text={"Reset Password"}
            onclick={updatePassword}
            isloading={isLoading}
            className={
              "w-[90%] lg:w-1/2 flex justify-center items-center outline-none p-2 text-white min-w-fit font-medium rounded-md shadow-md hover:bg-blue-600 bg-[#298aff] "
            }
          />

          {goBack()}
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
