export const DeptObj = {
  dept_name: "",
};

export function NullValidate(object) {
  let Error = {};

  if (object.dept_name.length === 0)
    Error.dept_name = "Department Field is Required";

  return Error;
}
