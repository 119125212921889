import { ActionTypes } from "../ActionCreater/ActionType";

const InitialObj = {
  isloading: false,
  data: [],
};

const LoaderObj = {
  loading: false,
};

export const checkListReducer = (state = InitialObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.MASTER_CHECKLIST_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.MASTER_CHECKLIST_RESPONSE:
      return {
        ...state,
        isloading: false,
        data: payload,
      };

    default:
      return state;
  }
};

export const CheckListLoader = (state = LoaderObj, { type }) => {
  switch (type) {
    case ActionTypes.MASTER_CHECKLIST_LOADER:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.MASTER_CHECKLIST_LOADER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
