import { ActionTypes } from "../ActionCreater/ActionType";

const InitialObj = {
  isloading: false,
  caseStageData: [],
};

const ButtonLoader = {
  isloading: false,
};

export const CaseStageReducer = (state = InitialObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_CASE_STAGE_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.READ_CASE_STAGE:
      return {
        ...state,
        isloading: false,
        caseStageData: payload,
      };

    default:
      return state;
  }
};

export const caseStageLoader = (state = ButtonLoader, { type }) => {
  switch (type) {
    case ActionTypes.POST_CASE_STAGE_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.POST_CASE_STAGE_SUCCESS:
      return {
        ...state,
        isloading: false,
      };

    default:
      return state;
  }
};
