import { ActionTypes } from "../ActionCreater/ActionType";

const InitialObj = {
  isLoading: false,
};

export const loginReducer = (user = InitialObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.LOGIN_LOADER_REQUEST:
      return { ...user, isLoading: true };

    case ActionTypes.LOGIN_LOADER_SUCCESS:
      return { ...user, isLoading: false };

    default:
      return user;
  }
};
