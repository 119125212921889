import { ActionTypes } from "../ActionCreater/ActionType";
import { GET, POST } from "../../Utils/Helper";
import { ShowErrorAlert } from "../../Components/Notification";

const fetchReport = () => {
  return {
    type: ActionTypes.REPORT_REQUEST,
  };
};

const fetchReportSuccess = (data) => {
  return {
    type: ActionTypes.REPORT_SUCCESS,
    payload: data,
  };
};

const FilterReportRequest = () => {
  return {
    type: ActionTypes.FILTER_REPORT_REQUEST,
  };
};

const FilterReportSuccess = (data) => {
  return {
    type: ActionTypes.FILTER_REPORT_SUCCESS,
    payload: data,
  };
};

const chartData = (data) => {
  return {
    type: ActionTypes.CHART_REPORT_DATA,
    payload: data,
  };
};

const FilterInputData = (items) => {
  return {
    type: ActionTypes.INPUT_FILTER_DATA,
    payload: items,
  };
};

export const FetchCardsReportData = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchReport());
      const response = await GET("superAdmin/report/reportCountAndPetitions");

      if (response) {
        dispatch(fetchReportSuccess(response.data));
      }
    } catch (err) {
      ShowErrorAlert(err.response.data.message);
    }
  };
};

export const ReportFilteredList = (obj) => {
  return async (dispatch) => {
    try {
      dispatch(FilterReportRequest());
      const response = await POST("superAdmin/report/allReportFilter", obj);

      if (response) {
        dispatch(FilterReportSuccess(response.data));
      }
    } catch (error) {
      ShowErrorAlert(error.response.data.message);
    }
  };
};

export const GetFilteredItems = () => {
  return async (dispatch) => {
    try {
      const caseStageResponse = await GET("superAdmin/caseStage");
      const caseTypeResponse = await GET("superAdmin/caseType");
      const courtTypeResponse = await GET("superAdmin/courtType");
      const petitionTypeResponse = await GET("superAdmin/petitionType");

      const AllFilteredResponses = await Promise.all([
        caseStageResponse,
        caseTypeResponse,
        courtTypeResponse,
        petitionTypeResponse,
      ]);

      if (AllFilteredResponses) {
        dispatch(
          FilterInputData(AllFilteredResponses.map((item) => item.data))
        );
      }
    } catch (error) {
      ShowErrorAlert(error);
    }
  };
};

export const chartDataAction = (obj) => {
  return async (dispatch) => {
    try {
      const response = await POST("/superAdmin/report/pieChart", obj);

      if (response) {
        dispatch(chartData(response.data));
      }
    } catch (error) {
      ShowErrorAlert(error.response.data.error);
    }
  };
};
