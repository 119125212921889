import { ActionTypes } from "../ActionCreater/ActionType";
import { DELETE, GET, POST, PUT } from "../../Utils/Helper";
import {
  ShowErrorAlert,
  ShowSuccessAlert,
} from "../../Components/Notification";

// Functions to return object for reducers
const loaderRequest = () => {
  return {
    type: ActionTypes.DETAIL_STICKY_LOADER,
  };
};

const loaderSuccess = () => {
  return {
    type: ActionTypes.DETAIL_STICKY_LOADER_SUCCESS,
  };
};

const checkListLoader = () => {
  return {
    type: ActionTypes.DETAIL_CHECKLIST_LOADER,
  };
};

const checkListLoaderSuccess = () => {
  return {
    type: ActionTypes.DETAIL_CHECKLIST_LOADER_SUCCESS,
  };
};

const CheckedList = (data) => {
  return {
    type: ActionTypes.CHECKED_LIST,
    payload: data,
  };
};

const fetchStickyNotesData = (data) => {
  return {
    type: ActionTypes.STICKY_NOTES_DATA_RESPONSE,
    payload: data,
  };
};

// Thunk Functions for Operations on Details/Sticky
export const stickyNotesData = (Id) => {
  return async (dispatch) => {
    try {
      dispatch(loaderRequest());

      const response = await GET(`petitionWork/showWork/${Id}`);

      if (response.statusCode === 200) {
        dispatch(fetchStickyNotesData(response.data));
      }
    } catch (error) {
      ShowErrorAlert(JSON.stringify(error.response.data.message));
    } finally {
      dispatch(loaderSuccess());
    }
  };
};

export const createStickyNote = (obj, next) => {
  return async (dispatch) => {
    try {
      dispatch(loaderRequest());

      const response = await POST("petitionWork/create", obj);

      if (response.statusCode === 200) {
        ShowSuccessAlert(response.message);
        next();
      }
    } catch (error) {
      ShowErrorAlert(JSON.stringify(error.response.data.errors));
    } finally {
      dispatch(loaderSuccess());
    }
  };
};

export const SingleReadNote = (Id, next) => {
  return async (dispatch) => {
    try {
      dispatch(loaderRequest());

      const res = await GET(`petitionWork/show/${Id}`);

      if (res.statusCode === 200) {
        next(res.data);
      }
    } catch (error) {
      ShowErrorAlert(JSON.stringify(error.response.data.message));
    } finally {
      dispatch(loaderSuccess());
    }
  };
};

export const UpdateStickyNote = (Id, obj, next) => {
  return async (dispatch) => {
    try {
      dispatch(loaderRequest());

      const response = await PUT(`petitionWork/update/${Id}`, obj);

      if (response.statusCode === 200) {
        ShowSuccessAlert(response.message);
        next();
      }
    } catch (error) {
      ShowErrorAlert(JSON.stringify(error.response.data.errors));
    } finally {
      dispatch(loaderSuccess());
    }
  };
};

export const DeleteStickyNote = (Id, next) => {
  return async (dispatch) => {
    try {
      dispatch(loaderRequest());

      const response = await DELETE(`petitionWork/deleteWork/${Id}`);

      if (response.statusCode === 200) {
        ShowSuccessAlert(response.message);
        next();
      }
    } catch (error) {
      ShowErrorAlert(JSON.stringify(error.response.data.message));
    } finally {
      dispatch(loaderSuccess());
    }
  };
};

export const createCheckList = (obj, next) => {
  return async (dispatch) => {
    try {
      dispatch(checkListLoader());

      const res = await POST("petitionChecklist/create", obj);

      if (res.statusCode === 200) {
        ShowSuccessAlert(res.message);
        next();
      }

      ShowSuccessAlert(res.message);
    } catch (error) {
      ShowErrorAlert(JSON.stringify(error.response.data.errors));
    } finally {
      dispatch(checkListLoaderSuccess());
    }
  };
};

export const getCheckedListData = (Id, petition_Id) => {
  return async (dispatch) => {
    try {
      dispatch(checkListLoader());

      const res = await GET(
        `petitionChecklist/CheckedList/${Id}/${petition_Id}`
      );

      if (res.statusCode === 200) {
        dispatch(CheckedList(res.data));
      }
    } catch (error) {
      ShowErrorAlert(JSON.stringify(error.response.data.message));
    } finally {
      dispatch(checkListLoaderSuccess());
    }
  };
};
