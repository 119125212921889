import React, { useEffect, useReducer, useState } from "react";
import DataTable from "react-data-table-component";
import { FaCalendarAlt } from "react-icons/fa";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../Components/Dropdown";
import { NotificationData } from "../../Redux/Actions/globalUsage";
import { useNavigate } from "react-router-dom";
import {
  removeUnderscore,
  getSerialNumber,
  getDatefromTimeStampReversed,
  getUrlByType,
  isReadNotification,
} from "../../Components/Notification";
import { CustomStyles } from "./addon/file";
import { paginationComponentOptions } from "../../Components/datatable";
import {
  fetchPetitionList,
  PetitionListActionType,
  ReducerInitial,
} from "../petition/addons/file";

function NotificationCounts() {
  const [CurrentPage, setCurrentPage] = useState(1);
  const [filter, setfilter] = useState("");
  const [state, dispatchPaginate] = useReducer(
    fetchPetitionList,
    ReducerInitial
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notifyData, markAsRead, markAsAllRead } = bindActionCreators(
    NotificationData,
    dispatch
  );
  const userId = localStorage.getItem("id");

  /* A conditional style that is being applied to the rows in the table. */
  const conditionalStyle = [
    {
      when: (row) => row.status === true,
      style: {
        backgroundColor: "#96D7FF",
      },
    },
  ];

  const {
    data: { items, totalCount },
  } = useSelector((state) => state.notifyData);

  const NotifyCountCols = [
    {
      name: "Sr. No.",
      selector: (_, i) => getSerialNumber(i, CurrentPage),
      width: "90px",
      sortable: true,
    },
    {
      name: "Petition No.",
      selector: (row) => row.petition_number || "N/A",
      width: "10rem",
      sortable: true,
    },
    {
      name: "Type",
      cell: (row) => typeUi(removeUnderscore(row?.notification_type), row),
      width: "15rem",
      sortable: true,
    },
    {
      name: "Description",
      cell: (row) => (
        <div
          onClick={() => {
            goToPetitionPage(row);
          }}
          className="w-full px-2 text-justify"
          dangerouslySetInnerHTML={{
            __html: row.notification_details,
          }}
        ></div>
      ),
      wrap: true,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => getDatefromTimeStampReversed(row.notification_date),
      sortable: true,
      width: "10rem",
    },
    // {
    //   name: "Action",
    //   cell: (row) => ActionUI(row),
    //   width: "15rem",
    // },
  ];

  const getNotification = (userId) => {
    if (userId !== (null || undefined)) {
      notifyData(state);
    }
  };

  /**
   * If the length of the data is less than 80, then return the first 80 characters of the data.
   * Otherwise, return the first 80 characters of the data, followed by an ellipsis, followed by a
   * button that, when clicked, will expand the data.
   * </code>
   *
   *
   * A:
   *
   * I think you are looking for something like this:
   * <code>const LogsUI = (data) =&gt; {
   *   const [expanded, setExpanded] = useState(false);
   *
   *   return (
   *     &lt;&gt;
   *       &lt;div
   *         className="w-full px-2 text-justify"
   *         dangerouslySetInnerHTML={{
   *           __html: expanded ? data : data.substring(0, 80),
   *         }}
   *       /&gt;
   *       {data?.length &gt; 80 &amp;&amp; (
   * @param data - is the data that is being passed to the function.
   * @returns A function that returns a JSX element.
   */
  // const LogsUI = (data) => {
  //   return data?.length < 80 ? (
  //     <>
  //       <div
  //         className="w-full px-2 text-justify"
  //         dangerouslySetInnerHTML={{
  //           __html: data.substring(0, 80),
  //         }}
  //       />
  //     </>
  //   ) : (
  //     <>
  //       <div className="flex justify-start w-desc-width ">
  //         <div
  //           dangerouslySetInnerHTML={{
  //             __html: data.substring(0, 80),
  //           }}
  //         />
  //         <span>...</span>
  //       </div>
  //       <div className="pl-2">
  //         {data?.length < 80 ? null : (
  //           <div
  //             className="bg-[#296aff] p-1 rounded-full outline-none cursor-pointer"
  //             onClick={() => {
  //               ExpandData(data);
  //             }}
  //           >
  //             <img src={visibleIcon} alt="" />
  //           </div>
  //         )}
  //       </div>
  //     </>
  //   );
  // };

  // const ExpandData = (data) => {
  //   HtmlModal(data);
  // };

  /**
   * It's a function that returns a button that when clicked, calls a function that updates the
   * database and then calls another function that updates the state of the component.
   * @param row - the row data
   * @returns A React component.
   */
  // const ActionUI = (row) => {
  //   return (
  //     <>
  //       <div>
  //         <button
  //           title="View Petition"
  //           onClick={() => {
  //             markAsRead(row.id);
  //             getNotification(userId);
  //             navigate(
  //               `/dashboard/petitionlist/${getUrlByType(
  //                 removeUnderscore(row?.notification_type)
  //               )}/${row.petition_id}`
  //             );
  //           }}
  //           className="p-2 bg-[#296aff]  rounded-full text-white"
  //         >
  //           <FaEye className="w-4 h-4" />
  //         </button>
  //       </div>
  //     </>
  //   );
  // };

  /**
   * It takes a string, splits it into an array, and returns a div with a className based on the first
   * word in the array
   * @param notification_type - this is the type of notification that is being sent to the user.
   * @returns A function that returns a div.
   */
  const typeUi = (notification_type, row) => {
    return (
      <div
        onClick={() => goToPetitionPage(row)}
        className={`${
          notification_type === "assigns"
            ? "bg-green-500"
            : notification_type === "dates"
            ? "bg-orange-500"
            : notification_type === "remarks"
            ? "bg-purple-500"
            : "bg-blue-500"
        } p-2 text-white w-36 h-fit text-center uppercase rounded-md`}
      >
        {notification_type}
      </div>
    );
  };

  /**
   * When the page number changes, set the current page to the new page number.
   * @param pageNum - The page number that was clicked
   */
  const HandlePageChange = (pageNum) => {
    setCurrentPage(pageNum);
    dispatchPaginate({
      type: PetitionListActionType.POST_PETITION_OFFSET,
      payload: (pageNum - 1) * state.limit,
    });
  };

  function goToPetitionPage(row) {
    markAsRead(row.id);
    getNotification(userId);
    navigate(
      `/dashboard/petitionlist/${getUrlByType(
        removeUnderscore(row?.notification_type)
      )}/${row.petition_id}`
    );
  }

  useEffect(() => {
    getNotification(userId);
  }, [state.offset]);

  return (
    <div
      className="w-full overflow-x-hidden bg-white/75 py-8"
      style={{
        height: `calc(100vh - 4rem)`,
      }}
    >
      {/* Notification Counts Header */}
      <div className="w-full px-4 py-3 flex items-center gap-2 bg-gray-300">
        <FaCalendarAlt className="w-4 h-4 text-gray-700" />
        <p className="capitalize text-base font-medium">notification list</p>
      </div>
      {/* Notification Counts Body */}
      <div className="md:p-4">
        <DataTable
          data={
            filter.length === 0
              ? items
              : items.filter(
                  (item) => removeUnderscore(item.notification_type) === filter
                )
          }
          columns={NotifyCountCols}
          fixedHeader
          fixedHeaderScrollHeight="55vh"
          onRowClicked={goToPetitionPage}
          customStyles={CustomStyles}
          conditionalRowStyles={conditionalStyle}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          paginationTotalRows={totalCount}
          paginationServer
          onChangePage={HandlePageChange}
          subHeader
          subHeaderComponent={
            <>
              <div className="w-full flex md:flex-row flex-col gap-3 justify-between py-3">
                <Dropdown
                  ClassName="focus:outline-none border capitalize py-2 px-4 border-gray-500 text-gray-500 w-auto rounded-md"
                  DefaultValue={"--Select Filter--"}
                  HandleChange={(e) => setfilter(e.target.value)}
                >
                  <option value={""}>All</option>
                  <option>assigns</option>
                  <option>attachments</option>
                  <option>remarks</option>
                  <option>dates</option>
                </Dropdown>
                <button
                  disabled={!isReadNotification(items)}
                  onClick={() => {
                    markAsAllRead(localStorage.getItem("id"), () => {
                      getNotification(userId);
                    });
                  }}
                  className="bg-blue-500 rounded-md p-2 flex items-center gap-2 text-white hover:bg-blue-600 font-medium capitalize"
                >
                  <IoCheckmarkDoneCircleSharp className="text-3xl" /> Mark All
                  as read
                </button>
              </div>
            </>
          }
        />
      </div>
    </div>
  );
}

export default NotificationCounts;
