import React from "react";
import Button from "../../../../Components/Button";
import InputField from "../../../../Components/InputField";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CheckListObj, NullValidate } from "../addon/file";
import { useEffect } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { CheckLists } from "../../../../Redux/Actions/globalUsage";

const CreateCheckList = () => {
  const [CheckList, setCheckList] = useState(CheckListObj);
  const [Focus, setFocus] = useState(false);
  const [FormError, setFormError] = useState({});
  const {
    checkListLoader: { loading },
  } = useSelector((state) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { CreateCheckList } = bindActionCreators(CheckLists, dispatch);

  const GoBack = () => {
    navigate("/dashboard/checklist");
  };

  const HandleCreateCheckList = () => {
    setFocus(true);
    setFormError(NullValidate(CheckList));
    if (Object.keys(NullValidate(CheckList)).length === 0) {
      // Call Post Thunk Action
      CreateCheckList(CheckList, () => {
        navigate("/dashboard/checklist");
      });
    }
  };

  const HandleChangeInput = (event) => {
    const { value } = event.target;

    setCheckList({ ...CheckList, name: value });
  };

  // Props For InputFIeld
  const InputFieldProps = {
    className:
      "p-2 outline-none border border-gray-500 placeholder:text-gray-500 placeholder:capitalize rounded-sm text-gray-500 text-sm w-full",
    onchange: HandleChangeInput,
  };

  useEffect(() => {
    if (Focus) {
      if (Object.keys(NullValidate(CheckList)).length !== null)
        setFormError(NullValidate(CheckList));
    }
  }, [Focus, CheckList]);

  return (
    <div
      className="w-full overflow-hidden flex justify-center items-start pt-5 bg-white/75"
      style={{
        height: `calc(100vh - 4rem)`,
      }}
    >
      <div className="w-[95%] md:w-1/2  border border-gray-300 rounded-t-md overflow-hidden">
        <div className="p-2 bg-gray-300 font-semibold text-base text-[#174bff] w-full flex justify-between items-center">
          <h1 className="capitalize">new checklist</h1>
          <BsArrowLeftCircleFill
            className="text-[#296aff] text-2xl cursor-pointer"
            onClick={GoBack}
          />
        </div>
        <div className="bg-white h-full p-2">
          <div className="w-full grid gap-4 p-2">
            <div className="flex flex-col gap-1">
              <label className="capitalize text-sm text-gray-800 font-medium">
                checklist name
              </label>
              <InputField
                {...InputFieldProps}
                name="name"
                value={CheckList.name}
                placeholder={"enter case stage name"}
              />
              <p className="text-sm font-bold h-5 text-red-500">
                {FormError.name}
              </p>
            </div>
            <div className="w-full flex gap-4 justify-end">
              <Button
                type={"button"}
                text={"create"}
                isloading={loading}
                className={
                  "w-full flex justify-center h-fit outline-none bg-[#298aff] p-2 text-white rounded-md shadow-md min-w-[15%] md:w-[15%] font-medium capitalize"
                }
                onclick={HandleCreateCheckList}
              />
              <Button
                type={"button"}
                text={"cancel"}
                onclick={GoBack}
                className={
                  "w-full outline-none bg-[#fa5151] p-2 text-white rounded-md shadow-md min-w-[15%] md:w-[15%] font-medium capitalize"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCheckList;
