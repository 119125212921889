import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../../Components/Button";
import InputField from "../../../../Components/InputField";
import { NullValidate, regionObj } from "../addons/file";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { bindActionCreators } from "redux";
import { regionList } from "../../../../Redux/Actions/globalUsage";
import { useDispatch, useSelector } from "react-redux";

function UpdateRegion() {
  // Local States
  const [updateState, setupdateState] = useState(regionObj);
  const [FieldError, setFieldError] = useState({});
  const [Focus, setFocus] = useState(false);

  // Variables
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  // BindActionCreator Method to consume thunk functions
  const { SingleReadRegion, UpdateRegion } = bindActionCreators(
    regionList,
    dispatch
  );

  const { isloading } = useSelector((state) => state.regionLoader);

  // Function to Navigate Back to location
  const GoBack = () => {
    navigate("/dashboard/regionlist");
  };

  // Function To Handle Change of InputField
  const HandleChangeInput = (e) => {
    const { name, value } = e.target;
    setupdateState({ ...updateState, [name]: value });
  };

  // Props For InputFIeld
  const InputFieldProps = {
    className:
      "p-2 outline-none border border-gray-500 placeholder:text-gray-500 placeholder:capitalize rounded-sm text-gray-500 text-sm w-full",
    onchange: HandleChangeInput,
  };

  const HandleCreateRegion = () => {
    setFieldError(NullValidate(updateState));
    setFocus(true);
    if (Object.keys(NullValidate(updateState)).length === 0) {
      UpdateRegion(id, updateState, navigate);
    }
  };

  useEffect(() => {
    if (Focus) {
      if (Object.keys(NullValidate(updateState)).length !== null)
        setFieldError(NullValidate(updateState));
    }
  }, [Focus, updateState]);

  useEffect(() => {
    SingleReadRegion(id, setupdateState);

    return () => {
      setupdateState(regionObj);
    };
  }, []);

  return (
    <div
      className="w-full overflow-hidden flex justify-center items-start pt-5 bg-white/75"
      style={{
        height: `calc(100vh - 4rem)`,
      }}
    >
      <div className="w-[95%] md:w-1/2  border border-gray-300 rounded-t-md overflow-hidden">
        <div className="p-2 bg-gray-300 font-semibold text-base text-[#174bff] w-full flex justify-between items-center">
          <h1 className="capitalize">update region registration</h1>
          <BsArrowLeftCircleFill
            className="text-[#296aff] text-2xl cursor-pointer"
            onClick={GoBack}
          />
        </div>
        <div className="bg-white h-full p-2">
          <div className="w-full grid gap-4 p-2">
            <div className="flex flex-col gap-1">
              <label className="capitalize text-sm text-gray-800 font-medium">
                region name
              </label>
              <InputField
                {...InputFieldProps}
                name="name"
                value={updateState.name}
                placeholder={"enter region name"}
              />
              <p className="text-sm font-bold h-5 text-red-500">
                {FieldError.region_name}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <label className="capitalize text-sm text-gray-800 font-medium">
                remark
              </label>
              <InputField
                {...InputFieldProps}
                name="remark"
                value={updateState.remark}
                placeholder={"enter remark"}
              />
              <p className="text-sm font-bold h-5 text-red-500">
                {FieldError.remark}
              </p>
            </div>
            <div className="w-full flex gap-4 justify-end">
              <Button
                type={"button"}
                text={"update"}
                isloading={isloading}
                className={
                  "w-full flex justify-center h-fit outline-none bg-[#298aff] p-2 text-white rounded-md shadow-md min-w-[15%] md:w-[15%] font-medium capitalize"
                }
                onclick={HandleCreateRegion}
              />
              <Button
                type={"button"}
                text={"cancel"}
                onclick={GoBack}
                className={
                  "w-full outline-none bg-[#fa5151] p-2 text-white rounded-md shadow-md min-w-fit md:w-[10%] font-medium capitalize"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateRegion;
