import { ActionTypes } from "../ActionCreater/ActionType";

const initialDashCount = {
  isLoading: false,
  data: {},
};

export const dashboardCount = (state = initialDashCount, { type, payload }) => {
  switch (type) {
    case ActionTypes.DASH_COUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.DASH_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
      };

    default:
      return state;
  }
};
