import { ActionTypes } from "../ActionCreater/ActionType";

const SearchObj = {
  loader: false,
  data: [],
};

export const SearchReducer = (state = SearchObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.SEARCH_ACTION_REQUEST:
      return {
        ...state,
        loader: true,
      };

    case ActionTypes.SEARCH_ACTION_SUCCESS:
      return {
        ...state,
        loader: false,
        data: payload,
      };

    default:
      return state;
  }
};
