import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import Button from "../../../Components/Button";
import InputField from "../../../Components/InputField";
import { NotificationAction } from "../../../Redux/Actions/globalUsage";
import { notificationObj, NullValidate } from "./addons/file";

function NotificationSetup() {
  // Local States
  const [createState, setCreateState] = useState(notificationObj);
  const [FieldError, setFieldError] = useState({});
  const [Focus, setFocus] = useState(false);

  // Variables
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { FetchNotificationSetup, UpdateNotificationSetup } =
    bindActionCreators(NotificationAction, dispatch);

  const { isloading } = useSelector((state) => state.notificationState);

  const HandleChangeInput = (e) => {
    const { name, value } = e.target;
    setCreateState({ ...createState, [name]: value });
  };

  const InputFieldProps = {
    className:
      "p-2 outline-none border border-gray-500 placeholder:text-gray-500 placeholder:capitalize rounded-sm text-gray-500 text-sm w-full",
    onchange: HandleChangeInput,
  };

  const GoBack = () => {
    navigate("/dashboard/caselist");
  };

  const HandleSaveClick = () => {
    setFieldError(NullValidate(createState));
    setFocus(true);
    if (Object.keys(NullValidate(createState)).length === 0) {
      UpdateNotificationSetup(
        localStorage.getItem("id"),
        createState,
        navigate
      );
    }
  };

  useEffect(() => {
    if (Focus) {
      if (Object.keys(NullValidate(createState)).length !== null)
        setFieldError(NullValidate(createState));
    }
  }, [Focus, createState]);

  useEffect(() => {
    FetchNotificationSetup(setCreateState);

    return () => {
      setCreateState({});
    };
  }, []);

  return (
    <>
      <div
        className="px-4 pt-5 flex justify-center items-start overflow-hidden bg-white/75"
        style={{ height: `calc(100vh - 4rem)` }}
      >
        <div className="w-[95%] md:w-1/2 rounded-t-md rounded-b-md shadow-lg overflow-hidden">
          <div className="px-4 py-2 bg-gray-300 capitalize">
            <h1 className="text-base text-[#174bff] font-semibold">
              change settings
            </h1>
          </div>
          <div className="px-4 py-2 bg-white">
            <div className="flex flex-col gap-1 py-2">
              <label className="text-sm capitalize">high alert range</label>
              <InputField
                {...InputFieldProps}
                name="high_alert_range"
                value={createState.high_alert_range}
                placeholder="enter high alert range"
              />
              <p className="text-sm font-bold text-red-500 h-5">
                {FieldError.high_range}
              </p>
            </div>
            <div className="flex flex-col gap-1 py-2">
              <label className="text-sm capitalize">low alert range</label>
              <InputField
                {...InputFieldProps}
                name="alert_range"
                value={createState.alert_range}
                placeholder="enter alert range"
              />
              <p className="text-sm font-bold text-red-500 h-5">
                {FieldError.low_range}
              </p>
            </div>
            <div className="flex flex-col gap-1 py-2">
              <label className="text-sm capitalize">halt range</label>
              <InputField
                {...InputFieldProps}
                name="halt_range"
                value={createState.halt_range}
                placeholder="enter halt range"
              />
              <p className="text-sm font-bold text-red-500 h-5">
                {FieldError.halt_range}
              </p>
            </div>
            <div className="w-full flex justify-end gap-4 py-2">
              <Button
                type={"button"}
                text={"update"}
                isloading={isloading}
                className={
                  "w-full flex justify-center outline-none bg-[#298aff] p-2 text-white rounded-md shadow-md min-w-[15%] md:w-[15%] font-medium capitalize"
                }
                onclick={HandleSaveClick}
              />
              <Button
                type={"button"}
                text={"cancel"}
                onclick={GoBack}
                className={
                  "w-full outline-none bg-[#fa5151] p-2 text-white rounded-md shadow-md min-w-fit md:w-[10%] font-medium capitalize"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationSetup;
