import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import { arrayofLocalstorage } from "../Redux/Actions/loginAction";

function MainLayout() {
  const [cookies] = useCookies();

  let navigate = useNavigate();
  const token = Object.entries(cookies);

  useEffect(() => {
    if (!token.length) {
      navigate("/");
      arrayofLocalstorage?.map((item) => localStorage.removeItem(item));
    }
  }, [token.length, navigate]);

  return (
    <>
      <div className="isolate">
        <Navbar />
        <div className="relative -z-10">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default MainLayout;
