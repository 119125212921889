import { ActionTypes } from "../ActionCreater/ActionType";

const StickNoteObj = {
  loading: false,
  data: [],
};

const CheckListObj = {
  loading: false,
  CheckedLists: [],
};

export const DetailReducer = (state = StickNoteObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.DETAIL_STICKY_LOADER:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DETAIL_STICKY_LOADER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.STICKY_NOTES_DATA_RESPONSE:
      return {
        ...state,
        data: payload,
      };

    default:
      return state;
  }
};

export const DetailCheckListReducer = (
  state = CheckListObj,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.DETAIL_CHECKLIST_LOADER:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DETAIL_CHECKLIST_LOADER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.CHECKED_LIST:
      return {
        ...state,
        loading: false,
        CheckedLists: payload,
      };

    default:
      return state;
  }
};
