import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomStyles, {
  paginationComponentOptions,
} from "../../../Components/datatable";
import { MdModeEdit, MdDeleteForever } from "react-icons/md";
import { courtList } from "../../../Redux/Actions/globalUsage";
import { DeleteModal, getSerialNumber } from "../../../Components/Notification";
import { useState } from "react";

function CourtList() {
  let navigate = useNavigate();
  let { pathname } = useLocation();
  const dispatch = useDispatch();
  const [currentPage, setcurrentPage] = useState(1);

  const { courtlist } = useSelector((state) => state.courtState);

  const { fetchCourtApi, DeleteCourtApi } = bindActionCreators(
    courtList,
    dispatch
  );

  const courtListCols = [
    {
      name: "Sr No.",
      selector: (_, i) => getSerialNumber(i, currentPage),
      sortable: true,
    },
    {
      name: "Court Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Remark",
      selector: (row) => row.remark,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => ActionUI(row),
    },
  ];

  const ActionUI = (row) => {
    return (
      <>
        <div className="flex gap-x-2">
          <Link
            to={`edit/${row.id}`}
            type={"button"}
            className="p-2 bg-[#296aff] rounded-full text-white"
          >
            <MdModeEdit className="w-4 h-4" />
          </Link>
          <div
            className="p-2 bg-[#ff2025] rounded-full text-white cursor-pointer"
            onClick={() => HandleDeleteRequest(row.id)}
          >
            <MdDeleteForever className="w-4 h-4" />
          </div>
        </div>
      </>
    );
  };

  const HandleDeleteRequest = (id) => {
    DeleteModal(id, DeleteFunc);
  };

  const DeleteFunc = (id) => {
    DeleteCourtApi(id);
  };

  const createCourtList = () => {
    navigate("/dashboard/createcourt");
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    if (courtlist.length === 0) {
      fetchCourtApi();
    }
  }, []);

  return (
    <>
      <div
        className="px-4 py-5 overflow-x-hidden bg-white/75"
        style={{ height: `calc(100vh - 4rem)` }}
      >
        <div className="w-full flex justify-between items-center pb-5">
          <h1 className="text-3xl font-semibold capitalize">Court list</h1>
          <button
            className="outline-none flex items-center text-white bg-gradient-to-r from-[#1f9fff] to-[#1f4fdf] rounded-[10px] py-2 px-4"
            onClick={createCourtList}
          >
            <img
              className="mr-2 h-4"
              src="https://img.icons8.com/android/20/ffffff/plus.png"
              alt=""
            />
            Create Court List
          </button>
        </div>
        <div className="bg-white">
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="71vh"
            pagination
            columns={courtListCols}
            data={courtlist}
            customStyles={CustomStyles}
            onChangePage={handlePageChange}
            paginationComponentOptions={paginationComponentOptions}
          />
        </div>
      </div>
    </>
  );
}

export default CourtList;
