import { ROLES } from "../../../Components/addons/roleArray";

/* Creating a constant variable called UserObj and assigning it an object with the properties
user_name, ward_id, role, post_name, display_name, dsc, email, mobile, password, and
password_confirmation. */
export const UserObj = {
  user_name: "",
  ward_id: [],
  role: "",
  post_name: "",
  display_name: "",
  dsc: "",
  email: "",
  mobile: "",
  password: "",
  password_confirmation: "",
};

/**
 * It checks if the object has a property with a length of 0, and if it does, it adds an error message
 * to the Error object
 * @param object - {
 * @returns An object with the following properties:
 */
export const NullValidate = (object) => {
  let Error = {};

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  const mobileRegex = /[6789][0-9]{9}/;

  if (object.user_name.length === 0) Error.user_name = "User Name is Required";

  // if (object.ward_id.length === 0) Error.wards = "Wards Field is Required";

  if (object.role.length === 0) Error.role = "Role Field is Required";

  if (object.post_name.length === 0) Error.post_name = "Post Name is Required";

  if (object.display_name.length === 0)
    Error.display_name = "Display Name is Required";

  if (object.dsc.length === 0) Error.dsc = "Description is Required";
  else if (object.dsc.length >= 500)
    Error.dsc = "Words Should be less than 500";

  if (object.email.length === 0) Error.email = "Email Field is Required";
  else if (!emailRegex.test(object.email))
    Error.email = "Email not in proper format";

  if (object.mobile.length === 0) Error.mobile = "Mobile No is Required";
  else if (!mobileRegex.test(object.mobile))
    Error.mobile = "Please enter a valid mobile no. !";

  if (object.password.length === 0)
    Error.password = "Password Field is Required";
  else if (object.password.length < 6)
    Error.password = "Password Length should be minimum of 6";

  if (object.password_confirmation.length === 0)
    Error.password_confirmation = "Confirm Password is Required";
  else if (object.password !== object.password_confirmation)
    Error.password_confirmation = "Password and Confirm Password did not match";
  else if (object.password_confirmation.length < 6)
    Error.password_confirmation = "Confirm Password Length minimum should be 6";

  return Error;
};

/**
 * It checks if the user has entered any value in the input fields
 * @param object - The object that contains the values of the form.
 * @returns An object with the keys of the fields that are invalid.
 */
export const UpdateNullValidate = (object) => {
  let Error = {};

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  const mobileRegex = /[6789][0-9]{9}/;

  if (object.user_name.length === 0) Error.user_name = "User Name is Required";

  // if (object.ward_id.length === 0) Error.wards = "Wards Field is Required";

  if (object.role.length === 0) Error.role = "Role Field is Required";

  if (object.post_name.length === 0) Error.post_name = "Post Name is Required";

  if (object.display_name.length === 0)
    Error.display_name = "Display Name is Required";

  if (object.dsc.length >= 500) Error.dsc = "Words Should be less than 500";

  if (object.email.length === 0) Error.email = "Email Field is Required";
  else if (!emailRegex.test(object.email))
    Error.email = "Email not in proper format";

  if (object.mobile.length === 0) Error.mobile = "Mobile No is Required";
  else if (!mobileRegex.test(object.mobile))
    Error.mobile = "Please enter a valid mobile no. !";

  if (object.password?.length > 0) {
    if (object.password.length < 6)
      Error.password = "Password Length should be minimum of 6";

    if (object.password !== object.password_confirmation)
      Error.password_confirmation =
        "Password and Confirm Password did not match";
  }

  return Error;
};

const UsersList = [
  {
    role: "1",
    name: "All Users",
  },
  {
    role: ROLES.legal_dept,
    name: "Legal Department Officer",
  },
  {
    role: ROLES.dept,
    name: "Department",
  },
  {
    role: ROLES.ward,
    name: "Ward",
  },
  {
    role: ROLES.lawyer,
    name: "Lawyer",
  },
  {
    role: ROLES.sub,
    name: "Sub Officer",
  },
];

export function getUserListByRole(role) {
  if (parseInt(role) === 0) {
    return UsersList;
  } else {
    return [{ role: ROLES.sub, name: "Sub Officer" }];
  }
}
