import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import Button from "../../../../../Components/Button";
import InputField from "../../../../../Components/InputField";
import { DetailsAction } from "../../../../../Redux/Actions/globalUsage";
import { NoteObj, NullValidate } from "./addon/file";

const UpdateSticky = ({ setIsOpen2, SingleId }) => {
  const [updateStickyObj, setUpdateStickyObj] = useState(NoteObj);
  const [focus, setFocus] = useState(false);
  const [fieldError, setFieldError] = useState({});

  const { id } = useParams();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("id");
  const {
    stickyNoteState: { loading },
  } = useSelector((state) => state);

  const { UpdateStickyNote, stickyNotesData, SingleReadNote } =
    bindActionCreators(DetailsAction, dispatch);

  const HandleNoteChange = (event) => {
    const { name, value } = event.target;

    setUpdateStickyObj({
      ...updateStickyObj,
      [name]: value,
      petition_id: id,
      user_id: userId,
    });
  };

  const HandleStickyNote = () => {
    setFocus(true);
    setFieldError(NullValidate(updateStickyObj));
    if (Object.keys(NullValidate(updateStickyObj)).length === 0) {
      // Call for Update Thunk Method
      UpdateStickyNote(SingleId, updateStickyObj, () => {
        setIsOpen2(false);
        stickyNotesData(id);
      });
    }
  };

  useEffect(() => {
    if (focus) {
      if (Object.keys(NullValidate(updateStickyObj)).length !== null)
        setFieldError(NullValidate(updateStickyObj));
    }
  }, [focus, updateStickyObj]);

  useEffect(() => {
    SingleReadNote(SingleId, (data) => {
      setUpdateStickyObj({ description: data.description, date: data.date });
    });
  }, []);

  return (
    <>
      <div className="w-full">
        <div className="rounded-t-md w-full overflow-hidden">
          <h1 className="text-xl bg-gray-300 text-[#296aff] font-bold px-3 py-2">
            Update Sticky Note
          </h1>
        </div>
        <div className="flex flex-col p-5">
          <label className=" text-gray-800 font-medium text-base flex gap-1 pb-1">
            Select Date
            <span className="text-red-600 font-semibold">*</span>
          </label>
          <InputField
            type={"date"}
            className={
              "p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full"
            }
            name={"date"}
            value={updateStickyObj.date}
            onchange={HandleNoteChange}
          />
          <p className="capitalize h-6 text-sm text-red-500 font-semibold py-1">
            {fieldError.date}
          </p>
        </div>
        <div className="flex flex-col p-5">
          <label className=" text-gray-800 font-medium text-base flex gap-1 pb-1">
            Update Description
            <span className="text-red-600 font-semibold">*</span>
          </label>
          <textarea
            className="resize-none h-16 p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full"
            placeholder="Enter Description"
            name="description"
            value={updateStickyObj.description}
            onChange={HandleNoteChange}
          ></textarea>
          <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
            {fieldError.description}
          </p>
        </div>
        <div className="px-5 pb-5 flex gap-3 justify-end">
          <Button
            type={"button"}
            text={"Update Note"}
            isloading={loading}
            onclick={HandleStickyNote}
            className={
              "w-full flex justify-center items-center outline-none md:w-[15%] p-2 text-white min-w-fit font-medium rounded-md shadow-md bg-[#298aff] "
            }
          />
        </div>
      </div>
    </>
  );
};

export default UpdateSticky;
