export const ROLES = {
  super_admin: "0",
  admin: "1",
  legal_dept: "2",
  dept: "3",
  ward: "4",
  lawyer: "5",
  sub: "6",
  parent_role: "2",
};
