import React, { useReducer, useEffect, useCallback, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "../../../../../Components/Button";
import CustomStyles, {
  paginationComponentOptions,
} from "../../../../../Components/datatable";
import Loader2 from "../../../../../Components/Loader2";
import {
  CheckLists,
  DetailsAction,
} from "../../../../../Redux/Actions/globalUsage";
import { CheckListDataReducer, CheckListObj, checkObjType } from "./addon/file";
import { useParams } from "react-router-dom";
import { getSerialNumber } from "../../../../../Components/Notification";
import "./addon/customDataTable.css";

const DetailCheckList = () => {
  const [state, dispatchData] = useReducer(CheckListDataReducer, CheckListObj);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const { id } = useParams();
  const user_Id = localStorage.getItem("id") || "0";

  const CheckListCols = [
    {
      name: "Sr No.",
      selector: (_, i) => getSerialNumber(i, currentPage),
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
  ];

  const {
    checkListState: { data, isloading },
    checkedLists: { loading, CheckedLists },
  } = useSelector((state) => state);

  const { FetchCheckListData, createCheckList, getCheckedListData } =
    bindActionCreators({ ...CheckLists, ...DetailsAction }, dispatch);

  const HandlePageChange = (pageNum) => {
    setCurrentPage(pageNum);
  };

  const HandleRowSelected = (state) => {
    let arr = [];

    state.selectedRows?.map((item, index) => (arr[index] = item.id));

    dispatchData({
      type: checkObjType.CHECK_ID_DATA,
      payload: { arr, id, user_Id },
    });
  };

  const optimizeRowSelected = useCallback(HandleRowSelected, []);

  const HandleCreateCheckList = () => {
    createCheckList(state, () => {
      getCheckedListData(user_Id, id);
    });
  };

  const rowSelectCriteria = (row) => {
    return CheckedLists?.map((item) => item.checklist_id)?.includes(row?.id);
  };

  const optimizeRowCriteria = useCallback(rowSelectCriteria, [CheckedLists]);

  useEffect(() => {
    FetchCheckListData();
    getCheckedListData(user_Id, id);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="w-full">
        <div className="pb-2 pt-1 px-2 flex items-center justify-between">
          <h1 className="font-bold text-2xl">CheckList</h1>
          <Button
            className={
              "p-2 flex items-center gap-2 transition-all duration-150 ease-in-out hover:bg-blue-700 bg-blue-500 lg:rounded-full rounded-md text-white font-medium capitalize"
            }
            onclick={HandleCreateCheckList}
            isloading={loading}
            text={"+ submit"}
          />
        </div>
        <div>
          <DataTable
            columns={CheckListCols}
            data={data}
            customStyles={CustomStyles}
            selectableRows
            selectableRowSelected={optimizeRowCriteria}
            onSelectedRowsChange={optimizeRowSelected}
            selectableRowsHighlight
            paginationComponentOptions={paginationComponentOptions}
            pagination
            onChangePage={HandlePageChange}
            progressPending={isloading}
            progressComponent={<Loader2 />}
          />
        </div>
      </div>
    </>
  );
};

export default DetailCheckList;
