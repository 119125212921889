export const InitialAssignObj = {
  role: "",
  assigned_users: [],
};

export const initialobj = {
  stamp_no: "",
  num: "",
  name: "",
  type: "",
  par_num: "",
  court_num: "",
  adv_name: "",
  sec_act: "",
  dsc: "",
  case_id: "",
  court_id: "",
  attachment_id: "",
};

export const initialRemark = {
  petition_id: "",
  remark: "",
};

export const initialAttachment = {
  attachment_type: "",
  file: "",
  attachment_dsc: "",
  petition_id: "",
};

export const initialDate = {
  date_type: "",
  date: "",
  dsc: "",
};

export const initialStage = {
  petition_id: "",
  case_stage_type: "",
  remark: "",
};

export const dropDownData = {
  type: [
    { name: "Wp", value: 1 },
    { name: "CP", value: 2 },
  ],
  court: [
    { name: "court one", value: 1 },
    { name: "Bombay court", value: 2 },
  ],
  cases: [
    { name: "case one", value: 1 },
    { name: "case one", value: 1 },
  ],
};

export const nav_item = [
  { title: "Petition", route: "petitionlist/edit" },
  { title: "assign", route: "petitionlist/assign" },
  { title: "Documents", route: "petitionlist/document" },
  { title: "Important Dates", route: "petitionlist/importantdate" },
  { title: "Remark", route: "petitionlist/remarks" },
  { title: "Case Stage", route: "petitionlist/stage" },
  { title: "log", route: "petitionlist/logs" },
  { title: "Dispose", route: "petitionlist/dispose" },
  { title: "Overview", route: "petitionlist/details" },
];

export const PetitionFilterData = [
  {
    id: 5,
    name: "All Petition",
  },
  {
    id: 1,
    name: "All Pending Petition",
  },
  {
    id: 2,
    name: "Assigned Petition",
  },
  {
    id: 4,
    name: "Contempt Petition",
  },
  {
    id: 3,
    name: "Disposed Petition",
  },
];

export const DisposeObj = {
  petition_id: "",
  type: "",
  file: "",
  remark: "",
  date: "",
  attachment_type: "",
};

export const DisposeDrop = [
  {
    id: 1,
    name: "Close",
  },
  {
    id: 2,
    name: "Contempt Compliance",
  },
];

export const petitionFilterObj = {
  value: "",
};

export const petitionStageObj = {
  case_stage: "",
};

export const SearchObj = {
  search: "",
};

export const ReducerInitial = {
  offset: 0,
  limit: 10,
  sorting_column: "id",
  sorting_direction: "desc",
};

export const PetitionListActionType = {
  POST_PETITION_OFFSET: "POST_PETITION_OFFSET",
  PETITION_RESET: "PETITION_RESET",
};

export function fetchPetitionList(state, { type, payload }) {
  switch (type) {
    case PetitionListActionType.POST_PETITION_OFFSET:
      return {
        ...state,
        offset: payload,
      };
    case PetitionListActionType.PETITION_RESET:
      return {
        ...state,
        offset: payload,
      };

    default:
      return state;
  }
}

export function formValidataion(obj) {
  let Error = {};

  if (obj.stamp_no.length === 0)
    Error.stamp_no = "stamp number Field is Required";

  // if (obj.num.length === 0) Error.num = "petition number Field is required";

  if (obj.name.length === 0) Error.name = "petition Name Field is required";

  if (obj.type.length === 0) Error.type = " Type Field is required";

  // if (obj.par_num.length === 0) Error.par_num = "Parent No. Field is required";

  // if (obj.court_num.length === 0)
  //   Error.court_num = "Court No. Field is required";

  if (obj.adv_name.length === 0)
    Error.adv_name = "Advocate name  Field is required";

  if (obj.sec_act.length === 0) Error.sec_act = "Section Act Field is required";

  // if (obj.dsc.length === 0) Error.dsc = "description Field is required";

  if (obj.case_id.length === 0) Error.case_id = "Case Field is required";

  if (obj.court_id.length === 0) Error.court_id = "Court Field is required";

  // if (obj.attachment_id.length === 0)
  //   Error.attachment_id = "Attachment is required";

  return Error;
}

export function remarkValidation(obj) {
  let Error = {};

  if (obj.remark.length === 0) Error.remark = "Petition Remark is required!";
  else if (obj.remark.length >= 500)
    Error.remark = "Remark words should be less than 500";

  return Error;
}

export function attachmentValidation(obj) {
  let err = {};

  if (obj.attachment_type.length === 0)
    err.attachment_type = "Attachment Type is required !";

  if (obj.file.length === 0) err.file = "File is required!";

  // if (obj.attachment_dsc.length === 0)
  //   err.attachment_dsc = "Remark is required !";

  return err;
}

export function DateNullValidate(obj) {
  let Error = {};

  if (obj.date_type.length === 0)
    Error.date_type = "Date Type Field is Required";

  if (obj.date.length === 0) Error.date = "Date Field is Required";
  else if (obj.date > "2100-01-01") Error.date = "Invalid Date";

  return Error;
}

export function UsersNullValidate(obj) {
  let Error = {};

  if (obj.role.length === 0) Error.role = "please select a role";

  if (obj.assigned_users.length === 0)
    Error.assigned_users = "please select a user";

  return Error;
}

export function stageValidataion(obj) {
  let Error = {};

  if (obj.case_stage_type.length === 0)
    Error.case_stage_type = "Stage Field is Required";

  if (obj.remark.length === 0) Error.remark = "Remark Field is required";

  return Error;
}

export function DisposeNullValidate(obj) {
  console.log(obj.type);
  let Error = {};

  if (obj.type.length === 0) Error.type = "Dispose Type is Required";

  if (obj.attachment_type.length === 0)
    Error.attachment_type = "Attachment Type is Required";

  if (obj.type === "2") {
    if (obj.date.length === 0) Error.date = "Date is Required ";
  }

  if (obj.file.length === 0) Error.file = "Attachment is Required ";

  return Error;
}
