import React, { useEffect, useReducer, useState } from "react";
import { FaArrowCircleDown } from "react-icons/fa";
import Dropdown from "../../../../Components/Dropdown";
import { DropDownValidate, FilterObj } from "../addon/file";
import Button from "../../../../Components/Button";
import DataTable from "react-data-table-component";
import { bindActionCreators } from "redux";
import { Reports } from "../../../../Redux/Actions/globalUsage";
import { useDispatch, useSelector } from "react-redux";
import {
  getSerialNumber,
  ShowErrorAlert,
} from "../../../../Components/Notification";
import ViewMoreUI from "../../../../Components/ViewMoreUI";
import Loader2 from "../../../../Components/Loader2";
import CustomStyles, {
  paginationComponentOptions,
} from "../../../../Components/datatable";
import {
  fetchPetitionList,
  ReducerInitial,
} from "../../../petition/addons/file";
import CardComponent from "../common/CardComponent";

const DrillDown = () => {
  const [formData, setformData] = useState({});
  const [TableData, setTableData] = useState({ data: [] });
  const [state, dispatchPaginate] = useReducer(
    fetchPetitionList,
    ReducerInitial
  );
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  const FilterColumns = [
    {
      name: "Sr No.",
      selector: (_, i) => getSerialNumber(i, currentPage),
      sortable: true,
      width: "90px",
    },
    {
      name: "Stamp No.",
      selector: (row) => row.stamp_no,
      sortable: true,
      // width: "10rem",
    },
    {
      name: "Petition No.",
      selector: (row) => row.num || "N/A",
      sortable: true,
      // width: "10rem",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      // width: "10rem",
    },
    {
      name: "Description",
      selector: (row) => <ViewMoreUI data={row.dsc} />,
      sortable: true,
      width: "27rem",
    },
    {
      name: "Adv. Name",
      selector: (row) => <ViewMoreUI data={row.adv_name} />,
      sortable: true,
      // width: "10rem",
    },
  ];

  const { GetFilteredItems, ReportFilteredList } = bindActionCreators(
    Reports,
    dispatch
  );

  const {
    ReportData: { FilterInputData, isloading },
  } = useSelector((state) => state);

  const ChangeOfInput = (e) => {
    const { name, value } = e.target;
    if (name === "case_stages") {
      setformData({ ...formData, case_stages: [parseInt(value)] });
    } else {
      setformData({ ...formData, [name]: value });
    }
  };

  const dropdownFieldProps = {
    HandleChange: ChangeOfInput,
    ClassName:
      "p-[0.6rem] outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full",
  };

  const FilterClick = () => {
    if (DropDownValidate(formData).length > 0) {
      ShowErrorAlert(DropDownValidate(formData));
    } else if (DropDownValidate(formData).length === 0) {
      ReportFilteredList(formData);
    }
  };

  const handlePageChange = (pageNum) => {
    setCurrentPage(pageNum);
  };

  useEffect(() => {
    GetFilteredItems();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="lg:p-5 py-3 px-2">
      {/* CARD SECTION */}
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-2">
        <CardComponent setterFunction={setTableData} />
      </div>

      {/* FILTER SECTION */}
      <div className="pt-4 grid grid-cols-1 lg:grid-cols-3 w-full gap-5">
        <div className="flex flex-col w-full">
          <label className="capitalize text-sm text-gray-800 font-medium block pb-1">
            case stage
          </label>
          <Dropdown
            DefaultValue={"--Select Case Stage--"}
            SetValue={formData.case_stages}
            setName={"case_stages"}
            {...dropdownFieldProps}
          >
            {FilterInputData[0]?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Dropdown>
        </div>
        <div className="flex flex-col w-full">
          <label className="capitalize text-sm text-gray-800 font-medium block pb-1">
            case
          </label>
          <Dropdown
            DefaultValue={"--Select Case--"}
            SetValue={formData.case}
            setName={"case"}
            {...dropdownFieldProps}
          >
            {FilterInputData[1]?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Dropdown>
        </div>
        <div className="flex flex-col w-full">
          <label className="capitalize text-sm text-gray-800 font-medium block pb-1">
            court
          </label>
          <Dropdown
            DefaultValue={"--Select Court--"}
            SetValue={formData.court}
            setName={"court"}
            {...dropdownFieldProps}
          >
            {FilterInputData[2]?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Dropdown>
        </div>
        <div className="flex flex-col w-full">
          <label className="capitalize text-sm text-gray-800 font-medium block pb-1">
            type
          </label>
          <Dropdown
            DefaultValue={"--Select Type--"}
            SetValue={formData.type}
            setName={"type"}
            {...dropdownFieldProps}
          >
            {FilterInputData[3]?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Dropdown>
        </div>
        <div className="flex flex-col w-full">
          <label className="capitalize text-sm text-gray-800 font-medium block pb-1">
            created from
          </label>
          <input
            type="date"
            onChange={ChangeOfInput}
            value={formData.date_created_from}
            name="date_created_from"
            className="p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full"
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="capitalize text-sm text-gray-800 font-medium block pb-1">
            created to
          </label>
          <input
            type="date"
            onChange={ChangeOfInput}
            value={formData.date_created_to}
            name="date_created_to"
            className="p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full"
          />
        </div>
      </div>

      {/* Filter Button */}
      <div className="py-4">
        <Button
          type={"button"}
          onclick={FilterClick}
          isloading={isloading}
          className={
            "px-4 py-2 flex justify-center bg-[#396aff] text-white capitalize rounded-md font-medium w-full lg:w-sm min-w-fit overflow-hidden shadow-lg"
          }
          text={"filter"}
        />
      </div>

      {/* TABLE SECTION */}
      <div className="bg-white rounded-lg w-full shadow-lg">
        {/* HEADING SECTION */}
        <div className="rounded-t-lg bg-slate-100 border-b-2 border-slate-500">
          <h2 className="flex items-center gap-2 p-3">
            <FaArrowCircleDown className="text-blue-500" />
            Drill Down
          </h2>
        </div>

        {/* CONSTENT SECTION */}
        <div className="w-[90%] lg:w-4/5 mx-auto py-5 space-y-3 ">
          {/* head section */}
          <div className="flex justify-between items-center gap-2">
            <label>
              <span className="font-semibold text-gray-700 text-md">
                Search :
              </span>
              <input
                onChange={(e) => {
                  // setSearch({ search: e.target.value });
                }}
                type={"search"}
                placeholder="Search here..."
                className="lg:w-80 w-full border border-gray-500 focus:outline-none rounded-sm px-2 py-1"
              />
            </label>
          </div>
          {/* Body Section */}
          <div>
            <DataTable
              columns={FilterColumns}
              data={TableData.data}
              fixedHeader
              fixedHeaderScrollHeight="58vh"
              progressPending={false}
              progressComponent={<Loader2 />}
              pagination
              paginationComponentOptions={paginationComponentOptions}
              onChangePage={handlePageChange}
              customStyles={CustomStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrillDown;
