import React, { useMemo } from "react";
import "chart.js/auto";
import { Pie } from "react-chartjs-2";
import { getRandomColor } from "../../../../Components/Notification";

const PieChart = ({ data }) => {
  const labelColor = useMemo(() => getRandomColor(data.label.length), [data]);

  let chartData = {
    labels: data.label,
    datasets: [
      {
        data: data.data,
        backgroundColor: labelColor,
        borderColor: ["#555"],
        hoverOffset: 10,
        label: data.label,
      },
    ],
  };

  return (
    <div className="w-[90%] h-1/2">
      <Pie data={chartData} />
    </div>
  );
};

export default PieChart;
