import axios from "axios";
import {
  ShowErrorAlert,
  ShowSuccessAlert,
} from "../../Components/Notification";
import { DELETE, GET, POST, PUT } from "../../Utils/Helper";
import { ActionTypes } from "../ActionCreater/ActionType";

const { REACT_APP_BASEURL } = process.env;

//ACTION FOR THE LOADING STATUS
const fetchRequest = () => {
  return {
    type: ActionTypes.FETCH_PETITION_REQUEST,
  };
};

//ACTION FOR THE GET DATA
const fetchSuccess = (petitionarray) => {
  return {
    type: ActionTypes.READ_PETITION_LIST,
    payload: petitionarray,
  };
};

// ACTION FOR THE SINGLE DATA
const fetchSinglePetition = (data) => {
  return {
    type: ActionTypes.SINGLE_PETITION,
    payload: data,
  };
};

// ACTION FOR THE TODAY PETITION REQUEST
const todayRequest = () => {
  return {
    type: ActionTypes.TODAY_PETITION_REQUEST,
  };
};

// ACTION FOR THE TODAY PETITION DATA
const todayPetitionData = (todayPetitionlist) => {
  return {
    type: ActionTypes.TODAY_PETITION_LIST,
    payload: todayPetitionlist,
  };
};

const todayLoaderSuccess = () => {
  return {
    type: ActionTypes.TODAY_PETITION_LOADER_SUCCESS,
  };
};

// ACTION FOR THE TODAY PETITION REQUEST
const btnLoaderRequest = () => {
  return {
    type: ActionTypes.PETITION_LOADER_REQUEST,
  };
};

// ACTION FOR THE TODAY PETITION DATA
const btnLoaderSuccess = () => {
  return {
    type: ActionTypes.PETITION_LOADER_SUCCESS,
  };
};

// ACTION FOR THE LOADER OF PETITION REMARKS
const petitionRemarkLoader = () => {
  return {
    type: ActionTypes.PETITION_REMARK_LOADER,
  };
};

//ACTION FOR THE PETITION REMARKS
const fetchPetitionRemark = (data) => {
  return {
    type: ActionTypes.PETITION_REMARK_DATA,
    payload: data,
  };
};

const fetchAttachmentsRequest = () => {
  return {
    type: ActionTypes.PETITION_ATTACHMENT_LOADER,
  };
};

const fetchAttachmentsSuccess = (data) => {
  return {
    type: ActionTypes.PETITION_ATTACHMENT_SUCCESS,
    payload: data,
  };
};

const fetchDateRequest = () => {
  return {
    type: ActionTypes.PETITION_DATE_REQUEST,
  };
};

const fetchDateSuccess = (data) => {
  return {
    type: ActionTypes.PETITION_DATE_SUCCESS,
    payload: data,
  };
};

const fetchQuickAssignRequest = () => {
  return {
    type: ActionTypes.QUICK_ASSIGN_REQUEST,
  };
};

const fetchQuickAssignSuccess = () => {
  return {
    type: ActionTypes.QUICK_ASSIGN_SUCCESS,
  };
};

const getAssignedUser = (data) => {
  return {
    type: ActionTypes.PETITION_ASSIGN_USER_DATA,
    payload: data,
  };
};

const fetchPetitionLogSuccess = (data) => {
  return {
    type: ActionTypes.PETITION_LOG_SUCCESS,
    payload: data,
  };
};

const fetchDashPetitionList = (data) => {
  return {
    type: ActionTypes.UNASSIGNED_PETITION_LIST,
    payload: data,
  };
};

const fetchStageData = (data) => {
  return {
    type: ActionTypes.PETITION_STAGE_DATA,
    payload: data,
  };
};

const fetchDisposeRequest = () => {
  return {
    type: ActionTypes.DISPOSE_PETITION_REQUEST,
  };
};

const DisposeSuccess = (data) => {
  return {
    type: ActionTypes.DISPOSE_PETITION_SUCCESS,
    payload: data,
  };
};

//FUNCTION FOR THE LOAD PETITION DATA
export const loadPetitionlist = (object) => {
  return async (dispatch) => {
    dispatch(fetchRequest());
    await POST(`legalDepartment/petition`, object)
      .then((res) => {
        const response = res.data;
        dispatch(fetchSuccess(response));
      })
      .catch((err) => {
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message));
      });
  };
};

//FUNCTION FOR THE LOAD PETITION DATA
export const loadPetitionasignedtome = (object) => {
  return async (dispatch) => {
    dispatch(fetchRequest());
    await POST(`legalDepartment/petition/assignToMePetition`, object)
      .then((res) => {
        const response = res.data;
        dispatch(fetchSuccess(response));
      })
      .catch((err) => {
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message));
      });
  };
};

export const DashPetitionList = () => {
  return async (dispatch) => {
    await GET("dashboard/unassignPetition")
      .then((res) => {
        dispatch(fetchDashPetitionList(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      );
  };
};

// FUNCTION FOR THE TODAY PETITION
export const todayPetition = (obj) => {
  return async (dispatch) => {
    dispatch(todayRequest());
    await POST(`/dashboard/todayPetitions`, obj)
      .then((res) => {
        dispatch(todayPetitionData(res.data));
      })
      .catch((err) => {
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message));
      })
      .finally(() => dispatch(todayLoaderSuccess()));
  };
};

//FUNCTION FOR THE DATE FILTER OF PETITION
export const filterPetition = (data) => {
  return async (dispatch) => {
    dispatch(todayRequest());
    dispatch(btnLoaderRequest());
    await POST(`dashboard/filterPetitions`, data)
      .then((res) => {
        dispatch(todayPetitionData(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      )
      .finally(() => dispatch(btnLoaderSuccess()));
  };
};

// FUNCTION TO CREATE NEW PETITION
export const createpetition = (data, navigate) => {
  return async (dispatch) => {
    dispatch(btnLoaderRequest());
    await POST(`legalDepartment/petition/create`, data)
      .then((res) => {
        if (res?.statusCode === 200) {
          ShowSuccessAlert(res?.message);
          navigate(`/dashboard/petitionlist/assign/${res?.data}`);
        }
      })
      .catch((err) => {
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message));
      })
      .finally(() => dispatch(btnLoaderSuccess()));
  };
};

// FUNTION FOR THE SINGLE READ
export const fetchSingleRead = (id, navigate, setupdateState = null) => {
  return async (dispatch) => {
    try {
      dispatch(btnLoaderRequest());
      const response = await GET(`legalDepartment/petition/show/${id}`);
      dispatch(fetchSinglePetition(response.data));
      if (setupdateState !== null) {
        setupdateState(response.data);
      }
    } catch (err) {
      if (err.response.data.statusCode === 403) {
        navigate("denied", {
          replace: true,
          state: "/dashboard/notificationCounts",
        });
      } else ShowErrorAlert(JSON.stringify(err?.response?.data?.message));
    } finally {
      dispatch(btnLoaderSuccess());
    }
  };
};

//FUNCTION FOR THE UPDATE PETITION
export const UpdatePetition = (id, data, navigate, PaginateObj) => {
  return async (dispatch) => {
    dispatch(btnLoaderRequest());
    await PUT(`legalDepartment/petition/update/${id}`, data)
      .then((res) => {
        if (res.statusCode === 200) {
          ShowSuccessAlert(res.message);
          dispatch(loadPetitionlist(PaginateObj));
          navigate("/dashboard/petitionlist");
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      )
      .finally(() => dispatch(btnLoaderSuccess()));
  };
};

// FUNCTION FOR THE DELETE PETITION
export const deletePetitionList = (id) => {
  return async (dispatch) => {
    await DELETE(`legalDepartment/petition/delete/${id}`)
      .then((res) => {
        if (res.statusCode === 200) {
          ShowSuccessAlert(res.message);
          dispatch(loadPetitionlist());
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      );
  };
};

//FUNCTION FOR THE PETITION REMARK GETTING DATA
export const loadPetitionRemark = (id) => {
  return async (dispatch) => {
    try {
      dispatch(petitionRemarkLoader());
      const res = await GET(`legalDepartment/petition/remarksPetition/${id}`);

      dispatch(fetchPetitionRemark(res.data?.remark_detail));
    } catch (err) {
      ShowErrorAlert(JSON.stringify(err?.response?.data?.message));
    }
  };
};

// FUNCTION TO CREATE NEW PETITION
export const createpetitionRemark = (data, id, updateModal) => {
  return async (dispatch) => {
    dispatch(btnLoaderRequest());
    await POST(`legalDepartment/petition/createRemark`, data)
      .then((res) => {
        if (res.statusCode === 200) {
          updateModal(false);
          ShowSuccessAlert(res.message);
          dispatch(loadPetitionRemark(id));
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      )
      .finally(() => dispatch(btnLoaderSuccess()));
  };
};

// FUNCTION TO UPDATE PETITION DATE
export const updatePetitionRemark = (obj, id, callback) => {
  return async (dispatch) => {
    dispatch(btnLoaderRequest());
    await PUT(`/legalDepartment/petition/updateRemark/${id}`, obj)
      .then((res) => {
        ShowSuccessAlert(res.message);
        callback();
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      )
      .finally(() => dispatch(btnLoaderSuccess()));
  };
};

// FUNCTION TO LOAD PETITION ATTACHMENT
export const loadPetitionAttachment = (id) => {
  return async (dispatch) => {
    dispatch(fetchAttachmentsRequest());
    await GET(`attachments/showAttachment/${id}`)
      .then((res) => {
        dispatch(fetchAttachmentsSuccess(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      );
  };
};

// function to add attachment to petition
export const createAttachment = (obj, setisOpen, callback, config) => {
  return async (dispatch) => {
    dispatch(btnLoaderRequest());
    await POST(`attachments/create`, obj, config)
      .then((res) => {
        if (res.statusCode === 200) {
          ShowSuccessAlert(res.message);
          setisOpen(false);
          callback();
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      )
      .finally(() => dispatch(btnLoaderSuccess()));
  };
};

export const SimpleAttachment = (
  obj,
  callback,
  updateLoader,
  updateState,
  prev
) => {
  const jwtToken = localStorage.getItem("token");
  const LocalImageUrl =
    "http://vvmclegal.emahapolitician.in/api/attachments/create";

  const config = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    onUploadProgress: (e) => {
      const { loaded, total } = e;
      const percentCompleted = Math.floor((loaded / total) * 100);
      updateLoader({ percent: percentCompleted });
    },
  };
  return async (dispatch) => {
    dispatch(btnLoaderRequest());
    await axios
      .post(`${REACT_APP_BASEURL}attachments/create`, obj, config)
      .then((res) => {
        if (res.data.statusCode === 200) {
          const id = res.data.data["attachment-id"];
          if (id) {
            updateState({ ...prev, attachment_id: id.toString() });
          }
          ShowSuccessAlert(res.data.message);
          callback();
        }
      })
      .catch((err) => {
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message));
      })
      .finally(() => dispatch(btnLoaderSuccess()));
  };
};

// Function To Delete Case Data
export const deleteAttachment = (id, callback) => {
  return async () => {
    await DELETE(`attachments/delete/${id}`)
      .then((res) => {
        if (res.statusCode === 200) {
          ShowSuccessAlert(res.message);
          callback();
        }
      })
      .catch((err) => ShowErrorAlert(err?.response?.data?.message));
  };
};

// FUNCTION TO LOAD PETITION DATE
export const loadPetitionDate = (id) => {
  return async (dispatch) => {
    dispatch(fetchDateRequest());
    await GET(`legalDepartment/petition/showDate/${id}`)
      .then((res) => {
        dispatch(fetchDateSuccess(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      );
  };
};

// FUNCTION TO CREATE PETITION DATE
export const createPetitionDate = (obj, id, updateModal) => {
  return async (dispatch) => {
    dispatch(btnLoaderRequest());
    await POST(`legalDepartment/petition/createDate`, obj)
      .then((res) => {
        updateModal(false);
        ShowSuccessAlert(res.message);
        dispatch(loadPetitionDate(id));
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.message)))
      .finally(() => dispatch(btnLoaderSuccess()));
  };
};

// FUNCTION TO UPDATE PETITION DATE
export const updatePetitionDate = (obj, id, pid, updateModal) => {
  return async (dispatch) => {
    dispatch(btnLoaderRequest());
    await PUT(`legalDepartment/petition/updateDate/${id}`, obj)
      .then((res) => {
        updateModal(false);
        ShowSuccessAlert(res.message);
        dispatch(loadPetitionDate(pid));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      )
      .finally(() => dispatch(btnLoaderSuccess()));
  };
};

// FUNCTION TO GET QUICKASSIGN DATA
export const loadQuickAssign = (id, updateMethod) => {
  return async (dispatch) => {
    dispatch(fetchQuickAssignRequest());
    await GET(`legalDepartment/assign/showAssign/${id}`)
      .then((res) => {
        updateMethod(res.data);
      })
      .catch((err) => ShowErrorAlert(err?.response?.data?.message))
      .finally(() => dispatch(fetchQuickAssignSuccess()));
  };
};

// Function To Empty Store Data of Quick Assign
export const EmptyAssignedUsers = () => {
  return {
    type: ActionTypes.PETITION_ASSIGN_EMPTY,
  };
};

// FUNCTION TO QUICK ASSIGN A PETITION
export const CreateQuickAssign = (
  obj,
  updateMethod,
  initialState,
  multiSelect,
  callback
) => {
  return async (dispatch) => {
    dispatch(fetchQuickAssignRequest());
    await POST("legalDepartment/assign/quickAssign", obj)
      .then((res) => {
        if (res.statusCode === 200) {
          ShowSuccessAlert(res.message);
          updateMethod(initialState);
          multiSelect.current.resetSelectedValues();
          callback();
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      )
      .finally(() => {
        dispatch(fetchQuickAssignSuccess());
      });
  };
};

export const DeleteAssignedUser = (id, cb) => {
  return async () => {
    try {
      const response = await DELETE(
        `legalDepartment/assign/assignDelete/${id}`
      );

      if (response) {
        ShowSuccessAlert(response.message);
        cb();
      }
    } catch (error) {
      ShowErrorAlert(JSON.stringify(error.response.data.message));
    }
  };
};

//FUNCTION TO GET PETION LOGS
export const loadPetitionLog = (id) => {
  return async (dispatch) => {
    dispatch(btnLoaderRequest());
    await GET(`/log/showPetitionLog/${id}`)
      .then((res) => {
        dispatch(fetchPetitionLogSuccess(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      )
      .finally(() => dispatch(btnLoaderSuccess()));
  };
};

//FUNCTION TO GET assignedUser
export const loadassignedUser = (id, roleId) => {
  return async (dispatch) => {
    // dispatch(btnLoaderRequest());
    await GET(`superAdmin/user/assignedUsers/${id}/${roleId}`)
      .then((res) => {
        const data = res.data;
        dispatch(getAssignedUser(data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      );
    // .finally(() => dispatch(btnLoaderSuccess()));
  };
};

export const AssignUser = (id, obj, updateMethod, callback) => {
  return async (dispatch) => {
    dispatch(fetchQuickAssignRequest());
    await PUT(`legalDepartment/assign/assignUpdate/${id}`, obj)
      .then((res) => {
        if (res.statusCode === 200) {
          ShowSuccessAlert(res.message);
          dispatch(loadQuickAssign(id, updateMethod));
          callback();
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      );
  };
};

//FUNCTION TO GET assignedUser
export const loadStage = (id) => {
  return async (dispatch) => {
    await GET(`/legalDepartment/petition/showPetitionStage/${id}`)
      .then((res) => {
        dispatch(fetchStageData(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      );
  };
};

export const CreateStage = (obj, callback) => {
  return async (dispatch) => {
    dispatch(fetchQuickAssignRequest());
    await POST("legalDepartment/petition/createPetitionStage", obj)
      .then((res) => {
        if (res.statusCode === 200) {
          ShowSuccessAlert(res.message);
          callback();
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      )

      .finally(() => {
        dispatch(fetchQuickAssignSuccess());
      });
  };
};

// FUNCTION TO UPDATE PETITION DATE
export const UpdateStage = (obj, id, callback) => {
  return async (dispatch) => {
    dispatch(btnLoaderRequest());
    await PUT(`legalDepartment/petition/updatePetitionStage/${id}`, obj)
      .then((res) => {
        ShowSuccessAlert(res.message);
        callback();
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      )
      .finally(() => dispatch(btnLoaderSuccess()));
  };
};

export const DisposeData = (id) => {
  return async (dispatch) => {
    dispatch(fetchDisposeRequest());
    await GET(`legalDepartment/petition/showDispose/${id}`)
      .then((res) => {
        dispatch(DisposeSuccess(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      );
  };
};

// Function to Dispose Petition
export const DisposePetition = (obj, callback, updateLoader) => {
  const jwtToken = localStorage.getItem("token");

  const config = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    onUploadProgress: (e) => {
      const { loaded, total } = e;
      const percentCompleted = Math.floor((loaded / total) * 100);
      updateLoader({ percent: percentCompleted });
    },
  };
  return async (dispatch) => {
    dispatch(btnLoaderRequest());
    await axios
      .post(`${REACT_APP_BASEURL}legalDepartment/petition/dispose`, obj, config)
      .then((res) => {
        if (res.data.statusCode === 200) {
          ShowSuccessAlert(res.data.message);
          callback();
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      )
      .finally(() => {
        dispatch(btnLoaderSuccess());
      });
  };
};

//FUNCTION FOR THE REOPEN PETITION

export const reopenPetition = (id, callback) => {
  return async (dispatch) => {
    dispatch(fetchQuickAssignRequest());
    await POST(`legalDepartment/petition/reopen/${id}`)
      .then((res) => {
        if (res.statusCode === 200) {
          ShowSuccessAlert(res.message);
          callback();
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      )

      .finally(() => {
        dispatch(fetchQuickAssignSuccess());
      });
  };
};
