import React from 'react'

function Loader() {
  return (
    <div>
        <div>
            <div className='loader'></div>
        </div>
    </div>
  )
}

export default Loader