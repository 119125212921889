export const notificationObj = {
  high_alert_range: "",
  alert_range: "",
  halt_range: "",
};

export function NullValidate(object) {
  let Error = {};

  if (object.high_alert_range.length === 0)
    Error.high_range = "High range field required";

  if (object.alert_range.length === 0)
    Error.low_range = "Alert range field required";

  if (object.halt_range.length === 0)
    Error.halt_range = "Halt Range field required";

  return Error;
}
