import { ActionTypes } from "../ActionCreater/ActionType";

const InitialObj = {
  isloading: false,
};

export const NotificationState = (state = InitialObj, { type }) => {
  switch (type) {
    case ActionTypes.NOTIFICATION_SETUP_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.NOTIFICATION_SETUP_SUCCESS:
      return {
        ...state,
        isloading: false,
      };

    case ActionTypes.UPDATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isloading: false,
      };

    default:
      return state;
  }
};
