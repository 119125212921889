import {
  ShowErrorAlert,
  ShowSuccessAlert,
} from "../../Components/Notification";
import { DELETE, GET, POST, PUT } from "../../Utils/Helper";
import { ActionTypes } from "../ActionCreater/ActionType";

// fuction to fetch petition type request
const fetchRequest = () => {
  return {
    type: ActionTypes.FETCH_PETITION_TYPE_REQUEST,
  };
};

// function to pass data to store after successfull fetch
const fetchSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_PETITION_TYPE_SUCCESS,
    payload: data,
  };
};

// function to set Button Loader to True
const PostRequest = () => {
  return {
    type: ActionTypes.POST_PETITION_TYPE_REQUEST,
  };
};

// function to set Button Loader to false
const PostSuccess = () => {
  return {
    type: ActionTypes.POST_PETITION_TYPE_SUCCESS,
  };
};

// Thunk function to fetch Petition Type List
export const fetchPetitionType = () => {
  return async (dispatch) => {
    dispatch(fetchRequest());
    await GET("superAdmin/petitionType")
      .then((res) => {
        dispatch(fetchSuccess(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};

// function to Create Petition Type
export const PostPetitionType = (obj, navigate) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await POST("superAdmin/petitionType/create", obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchPetitionType());
          ShowSuccessAlert(res.message);
          navigate("/dashboard/petitiontype");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => dispatch(PostSuccess()));
  };
};

// function to Single Read Petition Type
export const SingleReadPetitionType = (id, updateMethod) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await GET(`superAdmin/petitionType/show/${id}`)
      .then((res) => {
        updateMethod(res.data);
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.message)))
      .finally(() => dispatch(PostSuccess()));
  };
};

// function to Update Petition Type
export const UpdatePetitionType = (id, obj, navigate) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await PUT(`superAdmin/petitionType/update/${id}`, obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchPetitionType());
          ShowSuccessAlert(res.message);
          navigate("/dashboard/petitiontype");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => dispatch(PostSuccess()));
  };
};

// function to Delete Petition Type
export const DeletePetitionType = (id) => {
  return async (dispatch) => {
    await DELETE(`superAdmin/petitionType/delete/${id}`)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchPetitionType());
          ShowSuccessAlert(res.message);
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};
