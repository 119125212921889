import React, { useEffect, useState } from "react";
import { nav_item } from "./addon/file";
import { NavLink, Outlet } from "react-router-dom";
import { IoIosArrowDropupCircle } from "react-icons/io";

const Report = () => {
  const [ShowButton, setShowButton] = useState(false);

  useEffect(() => {
    const getScrollvalue = () => {
      if (window.pageYOffset > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", getScrollvalue);
    return () => {
      window.removeEventListener("scroll", getScrollvalue);
    };
  }, []);

  return (
    <div className="py-3 px-5">
      {/* SCROLL TO TOP SECTION */}
      {ShowButton && (
        <div className="fixed bottom-5 right-5 z-20">
          <IoIosArrowDropupCircle
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            className="text-6xl drop-shadow-2xl hover:text-blue-600 cursor-pointer text-blue-500 "
          />
        </div>
      )}

      {/* NAVIGATION SECTION */}
      <div className="grid grid-cols-2 lg:grid-cols-5 gap-1">
        {nav_item?.map((item, index) => (
          <NavLink
            key={index}
            to={item.route}
            className={({ isActive }) =>
              isActive ? "menuReport-active menuReport" : "menuReport"
            }
          >
            {item.title}
          </NavLink>
        ))}
      </div>

      {/* CONTENT SECTION */}
      <div className="border-2 border-zinc-500/30">
        <Outlet />
      </div>
    </div>
  );
};

export default Report;
