import React from "react";
import Button from "../../../../Components/Button";
import InputField from "../../../../Components/InputField";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { CheckListObj, NullValidate } from "../addon/file";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { CheckLists } from "../../../../Redux/Actions/globalUsage";
import { useEffect } from "react";

const UpdateCheckList = () => {
  const [updateCheckList, setupdateCheckList] = useState(CheckListObj);
  const [Focus, setFocus] = useState(false);
  const [FormError, setFormError] = useState({});

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { SingleReadCheckList, UpdateCheckListData } = bindActionCreators(
    CheckLists,
    dispatch
  );

  const {
    checkListLoader: { loading },
  } = useSelector((state) => state);

  const GoBack = () => {
    navigate("/dashboard/checklist");
  };

  const HandleUpdateCheckList = () => {
    setFocus(true);
    setFormError(NullValidate(updateCheckList));
    if (Object.keys(NullValidate(updateCheckList)).length === 0) {
      // Update Thunk Action Here
      UpdateCheckListData(id, updateCheckList, () => {
        navigate("/dashboard/checklist");
      });
    }
  };

  const HandleChangeInput = (event) => {
    const { value } = event.target;

    setupdateCheckList({ ...updateCheckList, name: value });
  };

  // Props For InputFIeld
  const InputFieldProps = {
    className:
      "p-2 outline-none border border-gray-500 placeholder:text-gray-500 placeholder:capitalize rounded-sm text-gray-500 text-sm w-full",
    onchange: HandleChangeInput,
  };

  useEffect(() => {
    if (Focus) {
      if (Object.keys(NullValidate(updateCheckList)).length !== null)
        setFormError(NullValidate(updateCheckList));
    }
  }, [Focus, updateCheckList]);

  useEffect(() => {
    SingleReadCheckList(id, (data) => {
      setupdateCheckList(data);
    });

    return () => {
      setupdateCheckList(CheckListObj);
    };

    // eslint-disable-next-line
  }, [id]);

  return (
    <div
      className="w-full overflow-hidden flex justify-center items-start pt-5 bg-white/75"
      style={{
        height: `calc(100vh - 4rem)`,
      }}
    >
      <div className="w-[95%] md:w-1/2  border border-gray-300 rounded-t-md overflow-hidden">
        <div className="p-2 bg-gray-300 font-semibold text-base text-[#174bff] w-full flex justify-between items-center">
          <h1 className="capitalize">update checklist</h1>
          <BsArrowLeftCircleFill
            className="text-[#296aff] text-2xl cursor-pointer"
            onClick={GoBack}
          />
        </div>
        <div className="bg-white h-full p-2">
          <div className="w-full grid gap-4 p-2">
            <div className="flex flex-col gap-1">
              <label className="capitalize text-sm text-gray-800 font-medium">
                checklist name
              </label>
              <InputField
                {...InputFieldProps}
                name="name"
                value={updateCheckList.name}
                placeholder={"enter case stage name"}
              />
              <p className="text-sm font-bold h-5 text-red-500">
                {FormError.name}
              </p>
            </div>
            <div className="w-full flex gap-4 justify-end">
              <Button
                type={"button"}
                text={"update"}
                isloading={loading}
                className={
                  "w-full flex justify-center h-fit outline-none bg-[#298aff] p-2 text-white rounded-md shadow-md min-w-[15%] md:w-[15%] font-medium capitalize"
                }
                onclick={HandleUpdateCheckList}
              />
              <Button
                type={"button"}
                text={"cancel"}
                onclick={GoBack}
                className={
                  "w-full outline-none bg-[#fa5151] p-2 text-white rounded-md shadow-md min-w-[15%] md:w-[15%] font-medium capitalize"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCheckList;
