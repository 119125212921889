import React, { useEffect, useReducer, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { BsPlusSquareFill } from "react-icons/bs";
import { AiFillMinusSquare } from "react-icons/ai";
import { FaCalendarAlt, FaEye } from "react-icons/fa";
import Button from "../../Components/Button";
import Dropdown from "../../Components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  DashCounts,
  petitionList,
  PetitionRoleAction,
  usersList,
} from "../../Redux/Actions/globalUsage";
import {
  getSerialNumber,
  Notify,
  ShowErrorAlert,
} from "../../Components/Notification";
import CustomStyles, {
  paginationComponentOptions,
} from "../../Components/datatable";
import Loader2 from "../../Components/Loader2";
import { elements, initial, NullValidate, quickAssignObj } from "./addon/file";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import ProtectedComponent from "../../Components/ProtectedComponent";
import { ROLES } from "../../Components/addons/roleArray";
import { Link } from "react-router-dom";
import ViewMoreUI from "../../Components/ViewMoreUI";
import {
  fetchPetitionList,
  ReducerInitial,
  PetitionListActionType,
} from "../petition/addons/file";
import { type } from "@testing-library/user-event/dist/type";

function HomePage() {
  // Local States
  const [QuickAssign, setQuickAssign] = useState(quickAssignObj);
  const [ShowQuickAssignUI, setShowQuickAssignUI] = useState(false);
  const [date, setdate] = useState(initial);
  const [tableData, settableData] = useState([]);
  const [preSelectedValue, setpreSelectedValue] = useState([]);
  const [role, setrole] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [elementName, setelementName] = useState(elements.today_petition);
  const [resetPaginationOfDataTable, setresetPaginationOfDataTable] =
    useState(false);

  const [state, dispatchPaginate] = useReducer(
    fetchPetitionList,
    ReducerInitial
  );
  const [TotalRows, setTotalRows] = useState(0);

  const dispatch = useDispatch();
  const multiSelect = useRef();

  /* Importing the action creators from the petitionList and usersList and binding them to the dispatch
  function. */
  const {
    todayPetition,
    filterPetition,
    CreateQuickAssign,
    DashPetitionList,
    loadassignedUser,
  } = bindActionCreators({ ...petitionList, ...usersList }, dispatch);

  const { dashboardData } = bindActionCreators(DashCounts, dispatch);
  const { FetchPetitionRoleData } = bindActionCreators(
    PetitionRoleAction,
    dispatch
  );

  const {
    data: {
      total_high_critical_count,
      total_high_hearing_count,
      high_critical_petition,
      high_hearing_petition,
      total_low_critical_count,
      total_low_hearing_count,
      low_critical_petition,
      low_hearing_petition,
      work_count,
      work_petition,
      pending_count,
      pending_petition,
      intrim_close_count,
      intrim_close_petition,
      contempt_due_count,
      contempt_due_petition,
    },
  } = useSelector((state) => state.dashboardCountState);
  const { data, isLoading } = useSelector((state) => state.todayPetition);
  const { filterIsloading } = useSelector((state) => state.petitionLoader);
  const {
    quickAssign: { loading },
    QuickAssign: { data: QuickAssignData },
    petitionRoleState: { data: RoleData },
  } = useSelector((state) => state);

  const { assigned_users, users } = useSelector(
    (state) => state.PetitionAssignedUserState
  );

  const conditionalStyle = [
    {
      when: (row) => row.active === false || row.active === 0,
      style: {
        backgroundColor: "#FFB3B3",
      },
    },
  ];

  const setPaginatedData = {
    [elements.high_critical]: high_critical_petition,
    [elements.high_hearing]: high_hearing_petition,
    [elements.low_critical]: low_critical_petition,
    [elements.low_hearing]: low_hearing_petition,
    [elements.work_petition]: work_petition,
    [elements.pending_petition]: pending_petition,
    [elements.interim_close]: intrim_close_petition,
    [elements.contempt_due]: contempt_due_petition,
    [elements.date_filter]: data,
  };

  function callApiOfPagination(key) {
    if (key === elements.date_filter || key === elements.today_petition) {
      settableData(data?.items);
      setTotalRows(data?.totalCount);
    } else {
      settableData(setPaginatedData[key]?.data?.items);
      setTotalRows(setPaginatedData[key]?.data?.totalCount);
    }
  }

  // COLUMNS  HEADER DATA
  const petitionCols = [
    {
      name: "Sr. no.",
      selector: (_, i) => getSerialNumber(i, currentPage),
      width: "4.5rem",
    },
    {
      name: "Stamp No.",
      selector: (row) => row.stamp_no || "N/A",
    },
    {
      name: "Petition No.",
      selector: (row) => row.num || "N/A",
    },
    {
      name: "Petition Name",
      selector: (row) => <ViewMoreUI data={row.name || row.petitionName} />,
    },
    {
      name: "Advocate Name",
      selector: (row) => row.adv_name,
    },
    {
      name: "Description",
      selector: (row) => <ViewMoreUI data={row.dsc} />,
    },
    {
      name: "Action",
      cell: (row) => (
        <Link
          to={`/dashboard/petitionlist/assign/${row?.id}`}
          title="View Petition"
          className="text-xl cursor-pointer  bg-blue-500 rounded-full w-8 h-8 flex justify-center items-center text-white"
        >
          <FaEye />
        </Link>
      ),
    },
  ];

  /**
   * When the user clicks the button, the function will toggle the state of the UI, reset the role, and
   * reset the multiSelect component.
   */
  const HandleUItoggle = () => {
    setShowQuickAssignUI(ShowQuickAssignUI ? false : true);
    setrole("");
    setQuickAssign(quickAssignObj);
    multiSelect.current.resetSelectedValues();
  };

  // ONCHANGE FUNCTION
  const HandleChangeDate = (e) => {
    setdate({ ...date, [e.target.name]: e.target.value });
  };

  // Quick Assign Change Functions
  const HandleChange = (e) => {
    const { name, value } = e.target;
    setQuickAssign({ ...QuickAssign, [name]: value });
  };

  /**
   * If the petition_id is greater than 0, set the role to the value of the target and load the
   * assigned user.
   *
   * If the petition_id is not greater than 0, notify the user that they need to select a petition.
   * @param e - the event object
   */
  const handleRoleChange = (e) => {
    if (QuickAssign.petition_id.length > 0) {
      setrole(e.target.value);
      loadassignedUser(QuickAssign.petition_id, e.target.value);
    } else {
      Notify(toast.error, "Please Select Petition !");
    }
  };

  /**
   * When a user is selected, the user's id is added to the assigned_users array.
   * @param _ - is the event object
   * @param item - {
   */
  const UserSelected = (_, item) => {
    setQuickAssign({
      ...QuickAssign,
      assigned_users: [...QuickAssign.assigned_users, item.to_user_id],
    });
  };

  /**
   * When a user is removed from the list, the user's ID is removed from the assigned_users array.
   * @param list - [{to_user_id: 1, to_user_name: "John Doe"}, {to_user_id: 2, to_user_name: "Jane
   * Doe"}]
   */
  const UserRemove = (list) => {
    setQuickAssign({
      ...QuickAssign,
      assigned_users: list.map((item) => item.to_user_id),
    });
    setpreSelectedValue(list);
  };

  /**
   * If the object is empty, then create a quick assign, and then set the role to an empty string and
   * then call the DashPetitionList function.
   */
  const HandleQuickAssign = () => {
    Notify(toast.error, NullValidate(QuickAssign).petition_id);
    Notify(toast.error, NullValidate(QuickAssign).assigned_users);

    if (Object.keys(NullValidate(QuickAssign)).length === 0) {
      CreateQuickAssign(
        QuickAssign,
        setQuickAssign,
        quickAssignObj,
        multiSelect,
        () => {
          setrole("");
          DashPetitionList();
        }
      );
    }
  };

  /**
   * If the date.from_date is less than or equal to date.to_date and date.to_date is greater than or
   * equal to date.from_date, then filterPetition(date) is called.
   */
  const handleFilter = () => {
    if (
      date.from_date.length > 0 &&
      date.to_date.length > 0 &&
      date.from_date <= date.to_date &&
      date.to_date >= date.from_date &&
      date.from_date < "2100-01-01" &&
      date.to_date < "2100-01-01"
    ) {
      setelementName(elements.date_filter);
      resetPaginationState();
      filterPetition({ ...date, ...state });
    } else {
      ShowErrorAlert("Invalid Date");
      setdate(initial);
      todayPetition(state);
    }
  };

  function resetPaginationState() {
    setcurrentPage(1);
    setresetPaginationOfDataTable(!resetPaginationOfDataTable);
    dispatchPaginate({
      type: PetitionListActionType.PETITION_RESET,
      payload: 0,
    });
  }

  const handlePageChange = (page) => {
    setcurrentPage(page);
    dispatchPaginate({
      type: PetitionListActionType.POST_PETITION_OFFSET,
      payload: (page - 1) * state.limit,
    });
  };

  const handleTodayPetition = () => {
    todayPetition(state);
    setelementName(elements.today_petition);
  };

  const LoadAllData = () => {
    todayPetition(state);
    DashPetitionList();
    dashboardData(state);
    FetchPetitionRoleData();
  };

  useEffect(() => {
    switch (elementName) {
      case elements.today_petition:
        todayPetition(state);
        break;
      case elements.date_filter:
        filterPetition({ ...date, ...state });
        break;

      default:
        dashboardData(state);
        break;
    }

    // eslint-disable-next-line
  }, [state.offset]);

  useEffect(() => {
    callApiOfPagination(elementName);

    // eslint-disable-next-line
  }, [setPaginatedData, state.offset]);

  useEffect(() => {
    if (role.length > 0) {
      setpreSelectedValue(assigned_users);
    }
  }, [role, assigned_users]);

  useEffect(() => {
    /* Setting the value of the assigned_users property of the QuickAssign object to the value of the
    preSelectedValue property of the QuickAssign object. */
    setQuickAssign({
      ...QuickAssign,
      assigned_users: preSelectedValue?.map((item) => item.to_user_id),
    });

    // eslint-disable-next-line
  }, [preSelectedValue]);

  useEffect(() => {
    LoadAllData();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className="w-full overflow-x-hidden bg-white/75"
        style={{ height: `calc(100vh - 4rem)` }}
      >
        {/* Cards Section */}
        <div className="p-4 w-full">
          {/* Cards UI */}
          <div className="grid md:grid-cols-4 grid-cols-1 gap-5 md:gap-8 w-full">
            <div className="p-4 w-full bg-[#ff4040] rounded-md shadow-lg">
              <div className="w-full text-white capitalize flex flex-col gap-1 font-semibold text-lg">
                <p
                  onClick={() => {
                    setelementName(elements.high_critical);
                    resetPaginationState();
                  }}
                  className="cursor-pointer w-fit"
                >
                  critical :
                  <span className="pl-2">{total_high_critical_count}</span>
                </p>
                <p
                  onClick={() => {
                    setelementName(elements.high_hearing);
                    resetPaginationState();
                  }}
                  className="cursor-pointer w-fit"
                >
                  hearing :
                  <span className="pl-2">{total_high_hearing_count}</span>
                </p>
              </div>
            </div>
            <div className="p-4 w-full bg-[#e2c700] rounded-md shadow-lg">
              <div className="text-white capitalize flex flex-col gap-1 font-semibold text-lg">
                <p
                  onClick={() => {
                    setelementName(elements.low_critical);
                    resetPaginationState();
                  }}
                  className="cursor-pointer w-fit"
                >
                  critical :
                  <span className="pl-2">{total_low_critical_count}</span>
                </p>
                <p
                  onClick={() => {
                    setelementName(elements.low_hearing);
                    resetPaginationState();
                  }}
                  className="cursor-pointer w-fit"
                >
                  hearing :
                  <span className="pl-2">{total_low_hearing_count}</span>
                </p>
              </div>
            </div>
            <div className="p-4 w-full bg-[#0C86ED] rounded-md shadow-lg">
              <div className="text-white capitalize flex flex-col gap-1 font-semibold text-lg">
                <p
                  className="cursor-pointer"
                  onClick={() => {
                    setelementName(elements.work_petition);
                    resetPaginationState();
                  }}
                >
                  work petitions :<span className="pl-2">{work_count}</span>
                </p>
                <p
                  className="cursor-pointer"
                  onClick={() => {
                    setelementName(elements.pending_petition);
                    resetPaginationState();
                  }}
                >
                  pending petitions :
                  <span className="pl-2">{pending_count}</span>
                </p>
              </div>
            </div>
            <div className="p-4 w-full bg-[#01E023] rounded-md shadow-lg">
              <div className="text-white capitalize flex flex-col gap-1 font-semibold text-lg">
                <p
                  className="cursor-pointer"
                  onClick={() => {
                    setelementName(elements.interim_close);
                    resetPaginationState();
                  }}
                >
                  interim close :
                  <span className="pl-2">{intrim_close_count}</span>
                </p>
                <p
                  className="cursor-pointer"
                  onClick={() => {
                    setelementName(elements.contempt_due);
                    resetPaginationState();
                  }}
                >
                  contempt due :
                  <span className="pl-2">{contempt_due_count}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Quick Assign Section */}
        <ProtectedComponent
          allowedRoles={[ROLES.super_admin, ROLES.admin, ROLES.legal_dept]}
        >
          <div className="w-full relative z-20">
            {/* Quick Assign Header Text */}
            <div
              className="px-4 py-2 w-full flex gap-2 bg-gray-300 items-center cursor-pointer"
              onClick={HandleUItoggle}
            >
              {ShowQuickAssignUI ? (
                <AiFillMinusSquare
                  className={
                    ShowQuickAssignUI &&
                    "transition-all duration-150 ease-linear w-4 h-4 text-gray-600"
                  }
                />
              ) : (
                <BsPlusSquareFill
                  className={
                    !ShowQuickAssignUI &&
                    "transition-all duration-150 ease-linear w-4 h-4 text-gray-600"
                  }
                />
              )}

              <p className="capitalize text-base font-medium">
                quick assignment
              </p>
            </div>
            {/* Conditional Based Quick Assign UI */}
            <div
              className={
                ShowQuickAssignUI
                  ? "transition-all duration-200 delay-150 ease-in-out px-4 w-full flex items-center bg-white border-b border-gray-400"
                  : "h-0 transition-all duration-200 delay-100 ease-in-out"
              }
            >
              <div
                className={
                  ShowQuickAssignUI
                    ? "transition-all duration-300 delay-200 ease-in-out scale-100 p-3 flex-col md:flex-row flex gap-10 items-center flex-1 bg-gray-300/50 rounded-md shadow-md"
                    : "h-0 scale-0"
                }
              >
                <Dropdown
                  ClassName={
                    "p-2 outline-none border border-gray-500 rounded-sm  text-gray-500 text-sm w-full"
                  }
                  DefaultValue={"--Select Petition--"}
                  setName={"petition_id"}
                  SetValue={QuickAssign?.petition_id}
                  HandleChange={HandleChange}
                >
                  {QuickAssignData?.length === 0 && (
                    <option value="" disabled>
                      No Data Available
                    </option>
                  )}
                  {QuickAssignData?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Dropdown>
                <Dropdown
                  ClassName={
                    "p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full"
                  }
                  DefaultValue={"--Select Role--"}
                  setName={"role"}
                  SetValue={role}
                  HandleChange={handleRoleChange}
                >
                  {RoleData?.length === 0 && (
                    <option value="" disabled>
                      No Data Available
                    </option>
                  )}
                  {RoleData?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Dropdown>
                <div className="w-full">
                  <Multiselect
                    options={users}
                    onSelect={UserSelected}
                    onRemove={UserRemove}
                    selectedValues={preSelectedValue}
                    ref={multiSelect}
                    placeholder="Select User"
                    displayValue="to_user_id_name"
                    className="w-full outline-none border border-gray-500 bg-white px-2 pb-1 text-gray-500 text-sm rounded-sm"
                    showArrow={true}
                    style={{
                      searchBox: {
                        border: "none",
                      },
                    }}
                  />
                </div>
                <Button
                  text={"assign"}
                  onclick={HandleQuickAssign}
                  isloading={loading}
                  className={
                    "w-full flex justify-center outline-none md:w-[15%] p-2 capitalize text-white min-w-fit font-medium rounded-md shadow-md bg-[#298aff]"
                  }
                />
              </div>
            </div>
          </div>
        </ProtectedComponent>
        {/* Todays Petition UI section */}
        <div className="w-full pt-3">
          <div
            className="px-4 py-2 w-full flex gap-2 bg-gray-300 items-center cursor-pointer"
            onClick={handleTodayPetition}
          >
            <FaCalendarAlt className="w-4 h-4 text-gray-700" />
            <p className="capitalize text-base font-medium">
              today's petition's
            </p>
          </div>
          <div className="px-4 py-2">
            <div className="w-full flex flex-col md:flex-row gap-4 justify-end">
              <input
                type="date"
                name="from_date"
                max={date.to_date}
                value={date.from_date}
                onChange={HandleChangeDate}
                className="p-2 border border-gray-500 outline-none text-gray-500 text-sm rounded-sm"
              />
              <input
                type="date"
                name="to_date"
                min={date.from_date}
                value={date.to_date}
                onChange={HandleChangeDate}
                className="p-2 border border-gray-500 outline-none text-gray-500 text-sm rounded-sm"
              />
              <Button
                text={"filter"}
                onclick={handleFilter}
                isloading={filterIsloading}
                className={` w-full flex justify-center items-center outline-none md:w-[10%]  p-2 capitalize text-white min-w-fit font-medium rounded-md shadow-md bg-[#298aff]`}
              />
            </div>
          </div>
          <div className="px-4 py-2">
            <DataTable
              columns={petitionCols}
              data={tableData}
              fixedHeader
              fixedHeaderScrollHeight="46vh"
              pagination
              paginationServer
              paginationTotalRows={TotalRows}
              conditionalRowStyles={conditionalStyle}
              onChangePage={handlePageChange}
              paginationComponentOptions={paginationComponentOptions}
              responsive={true}
              progressPending={isLoading}
              progressComponent={<Loader2 />}
              customStyles={CustomStyles}
              paginationDefaultPage={1}
              paginationResetDefaultPage={resetPaginationOfDataTable}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
