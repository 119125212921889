import { ShowErrorAlert } from "../../Components/Notification";
import { GET } from "../../Utils/Helper";
import { ActionTypes } from "../ActionCreater/ActionType";

// function to fetch role request
const fetchPetitionRoleRequest = () => {
  return {
    type: ActionTypes.PETITION_ROLE_REQUEST,
  };
};

const fetchSuccess = (data) => {
  return {
    type: ActionTypes.PETITION_ROLE_SUCCESS,
    payload: data,
  };
};

export const FetchPetitionRoleData = () => {
  return async (dispatch) => {
    dispatch(fetchPetitionRoleRequest());
    await GET("superAdmin/user/getMapedRole")
      .then((res) => {
        const response = res.data;
        dispatch(fetchSuccess(response));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};
