const ProtectedComponent = ({ allowedRoles, parent_role, children }) => {
  const Roles = [localStorage.getItem("role")];
  const parentRole = [localStorage.getItem("parent_role")];

  return Roles?.find((role) => allowedRoles?.includes(role)) ||
    parentRole?.find((role) => parent_role?.includes(role))
    ? children
    : null;
};

export default ProtectedComponent;
