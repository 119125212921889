import {
  ShowErrorAlert,
  ShowSuccessAlert,
} from "../../Components/Notification";
import { DELETE, GET, POST, PUT } from "../../Utils/Helper";
import { ActionTypes } from "../ActionCreater/ActionType";

// function to set Button Loader to True
const PostRequest = () => {
  return {
    type: ActionTypes.POST_USER_REQUEST,
  };
};

// function to set Button Loader back to false
const PostSuccess = () => {
  return {
    type: ActionTypes.POST_USER_SUCCESS,
  };
};

// Function to fetching request
const fetchRequest = () => {
  return {
    type: ActionTypes.FETCH_USER_REQUEST,
  };
};

// Function to dispatch data after successfull request
const fetchSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_USER_LIST,
    payload: data,
  };
};

// function to get Wards Data
const fetchWardsRequest = () => {
  return {
    type: ActionTypes.FETCH_WARDS_REQUEST,
  };
};

// fuction to pass data after request successfull
const fetchWardsSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_WARDS_SUCCESS,
    payload: data,
  };
};

// Thunk function to fetch Users Data
export const fetchUsersData = () => {
  return async (dispatch) => {
    dispatch(fetchRequest());
    await GET("superAdmin/user")
      .then((res) => {
        dispatch(fetchSuccess(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};

// Function to fetch Wards Data
export const fetchWardsData = () => {
  return async (dispatch) => {
    dispatch(fetchWardsRequest());
    await GET("superAdmin/user/getWards")
      .then((res) => {
        if (res.statusCode === 200) dispatch(fetchWardsSuccess(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};

// Function to Create New User Data
export const PostUserData = (obj, navigate) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await POST("superAdmin/user/create", obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchUsersData());
          ShowSuccessAlert(res.message);
          navigate("/dashboard/readuser");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => {
        dispatch(PostSuccess());
      });
  };
};

// Function to Single Read User Data
export const SingleReadUser = (id, updateMethod, InitialState) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await GET(`superAdmin/user/show/${id}`)
      .then((res) => {
        let temp = [];
        const ward = res?.data?.ward;
        if (ward.length !== 0) {
          ward?.filter((item, index) => {
            temp[index] = item.id;
            updateMethod({ ...InitialState, ...res.data, ward_id: temp });
          });
        } else updateMethod({ ...InitialState, ...res.data });
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.message)))
      .finally(() => {
        dispatch(PostSuccess());
      });
  };
};

// Function to Update User Data
export const UpdateUserData = (id, obj, navigate) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await PUT(`superAdmin/user/update/${id}`, obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchUsersData());
          ShowSuccessAlert(res.message);
          navigate("/dashboard/readuser");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => {
        dispatch(PostSuccess());
      });
  };
};

// Function to Delete User Data
export const DeleteUser = (id) => {
  return async (dispatch) => {
    await DELETE(`superAdmin/user/delete/${id}`)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchUsersData());
          ShowSuccessAlert(res.message);
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};
