import { ActionTypes } from "../ActionCreater/ActionType";

const CourtObj = {
  isloading: false,
  courtlist: [],
};

const ButtonLoader = {
  isloading: false,
};

export const CourtListReducer = (state = CourtObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_COURT_REQUEST:
      return { ...state, isloading: true };

    case ActionTypes.READ_COURT_LIST:
      return {
        ...state,
        isloading: false,
        courtlist: payload,
      };

    default:
      return state;
  }
};

export const ButtonLoaderReducer = (state = ButtonLoader, { type }) => {
  switch (type) {
    case ActionTypes.POST_COURT_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.POST_COURT_SUCCESS:
      return {
        ...state,
        isloading: false,
      };

    default:
      return state;
  }
};
