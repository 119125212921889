import { ActionTypes } from "../ActionCreater/ActionType";

const InitialObj = {
  isloading: false,
  userData: [],
};

const WardsObj = {
  WardIsLoading: false,
  wardsData: [],
};

const ButtonLoader = {
  btnLoading: false,
};

export const UserReducer = (state = InitialObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_USER_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.FETCH_USER_LIST:
      return {
        ...state,
        isloading: false,
        userData: payload,
      };

    default:
      return state;
  }
};

export const WardReducer = (state = WardsObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_WARDS_REQUEST:
      return {
        ...state,
        WardIsLoading: true,
      };

    case ActionTypes.FETCH_WARDS_SUCCESS:
      return {
        ...state,
        WardIsLoading: false,
        wardsData: payload,
      };

    default:
      return state;
  }
};

export const UserLoader = (state = ButtonLoader, { type }) => {
  switch (type) {
    case ActionTypes.POST_USER_REQUEST:
      return {
        ...state,
        btnLoading: true,
      };

    case ActionTypes.POST_USER_SUCCESS:
      return {
        ...state,
        btnLoading: false,
      };

    default:
      return state;
  }
};
