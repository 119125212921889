import React, { useState } from "react";
import Dropdown from "../../../../Components/Dropdown";
import { FaArrowCircleDown, FaFileCsv } from "react-icons/fa";
import { BsFilePdfFill, BsFileEarmarkPdfFill } from "react-icons/bs";
import Loader2 from "../../../../Components/Loader2";
import DataTable from "react-data-table-component";
import CustomStyles from "../../../../Components/datatable";

const data = [
  {
    particular: "Cases Listed Today",
    civil: 5,
    criminal: 10,
    Total: 15,
  },
  {
    particular: "Cases Disposed In Last Month(more than 10 years old)",
    civil: 5,
    criminal: 10,
    Total: 15,
  },
  {
    particular: "Undated Cases",
    civil: 5,
    criminal: 10,
    Total: 15,
  },
];

const Alert = () => {
  const [formData, setformData] = useState({});
  const [formError, setformError] = useState({});

  const ChangeOfInput = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  const dropdownFieldProps = {
    HandleChange: ChangeOfInput,
    ClassName:
      "p-[0.6rem] outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full",
  };

  //COLUMNS FOR THE REMARKS TABLE
  const column = [
    {
      name: "Particular",
      selector: (row) => row.particular,
      sortable: true,
      width: "30rem",
      wrap: true,
    },
    {
      name: "Civil",
      selector: (row) => row.civil,
      sortable: true,
    },
    {
      name: "Criminal",
      selector: (row) => row.criminal,
      sortable: true,
    },
    {
      name: "Total",
      selector: (row) => row.Total,
      sortable: true,
    },
  ];

  return (
    <div className="lg:p-5 py-3 px-2">
      {/* FILTER SECTION */}
      <div className="py-5 flex w-full  gap-5">
        <div className="flex flex-col w-full lg:w-1/2">
          <Dropdown
            DefaultValue={"--Select Filter--"}
            // SetValue={formData.date_type}
            setName={"date_type"}
            {...dropdownFieldProps}
          >
            {/* {dateTypeData?.map((item, i) => (
              <option key={i} value={item.id}>
                {item.name}
              </option>
            ))} */}
          </Dropdown>

          <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
            {formError.date_type}
          </p>
        </div>
      </div>

      {/* TABLE SECTION */}
      <div className="bg-white rounded-lg w-full shadow-lg">
        {/* HEADING SECTION */}
        <div className="rounded-t-lg bg-slate-100 border-b-2 border-slate-500">
          <h2 className="flex items-center gap-2 p-3">
            <FaArrowCircleDown className="text-blue-500" />
            Drill Down
          </h2>
        </div>

        {/* CONSTENT SECTION */}
        <div className="w-[90%] lg:w-4/5 mx-auto py-5 space-y-3 ">
          {/* head section */}
          <div className="flex justify-between items-center">
            <label>
              <span className="font-bold  text-md">Search : </span>
              <input
                onChange={(e) => {
                  // setSearch({ search: e.target.value });
                }}
                type={"search"}
                placeholder="Search here..."
                className="lg:w-80 w-full border border-black focus:outline-none  rounded-md p-1"
              />
            </label>
          </div>
          <div className="max-h-[30rem] overflow-auto ">
            <div className="w-full">
              {false ? (
                <div className="flex justify-center items-center ">
                  <Loader2 />
                </div>
              ) : (
                <DataTable
                  columns={column}
                  customStyles={CustomStyles}
                  data={data}
                  fixedHeader
                  fixedHeaderScrollHeight="50vh"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
