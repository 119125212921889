export const PetitionTypeObj = {
  name: "",
  remark: "",
};

export function NullValidate(object) {
  let Error = {};

  if (object.name.length === 0) Error.name = "Name Field is Required";

  if (object.remark.length === 0) Error.remark = "Remark Field is Required";

  return Error;
}
