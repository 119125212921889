import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import CustomStyles from "../../Components/datatable";
import DataTable from "react-data-table-component";
import { bindActionCreators } from "redux";
import { Link, useNavigate } from "react-router-dom";
import { MdModeEdit } from "react-icons/md";
import { RiFileList3Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  caseStageList,
  DropAction,
  GlobalSearch,
  petitionList,
  Reports,
} from "../../Redux/Actions/globalUsage";
import Loader2 from "../../Components/Loader2";
import Dropdown from "../../Components/Dropdown";
import {
  fetchPetitionList,
  PetitionFilterData,
  petitionFilterObj,
  PetitionListActionType,
  petitionStageObj,
  ReducerInitial,
  SearchObj,
} from "./addons/file";
import ViewMoreUI from "../../Components/ViewMoreUI";
import { debounce, getSerialNumber } from "../../Components/Notification";

const Petition = () => {
  const [PetitionData, setPetitionData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [TotalRows, setTotalRows] = useState(0);
  const [petitionFilter, setPetitionFilter] = useState(petitionFilterObj);
  const [petitionStageFilter, setPetitionStageFilter] =
    useState(petitionStageObj);
  const [searchFilter, setSearchFilter] = useState(SearchObj);
  const [resetPagination, setResetPagination] = useState(false);

  const caseStageRef = useRef();
  const petitionFilterRef = useRef();
  const searchInput = useRef();

  const conditionalStyle = [
    {
      when: (row) => row.active === false || row.active === 0,
      style: {
        backgroundColor: "#FFB3B3",
      },
    },
  ];

  const [state, dispatchPaginate] = useReducer(
    fetchPetitionList,
    ReducerInitial
  );

  // Variables
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let role = localStorage.getItem("role");

  // Bind Action Creator Method to wrap dispatch function with action Creators
  const {
    loadPetitionlist,
    loadPetitionasignedtome,
    PetitionDropWiseData,
    fetchCaseStage,
    PetitionStageFilter,
  } = bindActionCreators(
    {
      ...petitionList,
      ...GlobalSearch,
      ...DropAction,
      ...caseStageList,
      ...Reports,
    },
    dispatch
  );

  // Consuming Array from Store using useSelector Hook
  const {
    data: { items, totalCount },
    isLoading,
  } = useSelector((state) => state.petitionState);
  const { data: PetitionSearchData } = useSelector(
    (state) => state.PetitionSearchState
  );
  const {
    DropDataState: { data },
    caseStageState: { caseStageData },
    ReportData: { filteredData },
  } = useSelector((state) => state);

  /* Defining the columns of the table. */
  const petitionCols = [
    {
      name: "Sr No.",
      selector: (_, i) => getSerialNumber(i, currentPage),
      sortable: true,
      width: "90px",
    },
    {
      name: "Stamp No.",
      selector: (row) => row.stamp_no,
      sortable: true,
      // width: "10rem",
    },
    {
      name: "Petition No.",
      selector: (row) => row.num || "N/A",
      sortable: true,
      // width: "10rem",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      // width: "10rem",
    },
    {
      name: "Adv. Name",
      selector: (row) => <ViewMoreUI data={row.adv_name} />,
      sortable: true,
      // width: "10rem",
    },
    {
      name: "Description",
      selector: (row) => <ViewMoreUI data={row.dsc} />,
      sortable: true,
      width: "27rem",
    },
    {
      name: "Action",
      cell: (row) => ActionUI(row),
      width: "10rem",
    },
  ];

  const HandleChange = (e) => {
    const { value } = e.target;
    caseStageRef.current.value = "";
    searchInput.current.value = "";
    setSearchFilter(SearchObj);
    setPetitionStageFilter(petitionStageObj);
    setPetitionFilter({ ...petitionFilter, value: value });
    resetPaginationData();
  };

  const HandleChangeCaseStage = (event) => {
    const { value } = event.target;
    petitionFilterRef.current.value = "";
    searchInput.current.value = "";
    setSearchFilter(SearchObj);
    setPetitionFilter(petitionFilterObj);
    setPetitionStageFilter({ ...petitionStageFilter, case_stage: value });
    resetPaginationData();
  };

  // FUNCTION TO DISPLAY  EDIT AND DELETE BUTTON IN DATA TABLE
  const ActionUI = (row) => {
    return (
      <>
        <div className="flex gap-x-4 justify-center">
          <Link
            to={`/dashboard/petitionlist/assign/${row.id}`}
            type={"button"}
            className="p-2 bg-[#296aff] rounded-full text-white"
          >
            <MdModeEdit className="w-4 h-4" />
          </Link>
          <Link
            to={`/dashboard/petitionlist/logs/${row.id}`}
            className="p-2 bg-[#00ba37] rounded-full text-white"
            type="button"
          >
            <RiFileList3Line className="w-4 h-4" />
          </Link>
        </div>
      </>
    );
  };

  const handleSearch = (value) => {
    setSearchFilter({ ...searchFilter, search: value });
  };

  /* A debounce function. */
  const OptimizeSearch = useCallback(debounce(handleSearch, 500), []);

  function resetPaginationData() {
    setcurrentPage(1);
    setResetPagination(!resetPagination);
    dispatchPaginate({
      type: PetitionListActionType.PETITION_RESET,
      payload: 0,
    });
  }

  // Handle Page Change
  const handlePageChange = (pageNum) => {
    setcurrentPage(pageNum);
    dispatchPaginate({
      type: PetitionListActionType.POST_PETITION_OFFSET,
      payload: (pageNum - 1) * state.limit,
    });
  };

  useEffect(() => {
    setPetitionData(PetitionSearchData);
  }, [PetitionSearchData]);

  useEffect(() => {
    setPetitionData(items);
    setTotalRows(totalCount);
    // eslint-disable-next-line
  }, [items]);

  useEffect(() => {
    setPetitionData(filteredData);
    // eslint-disable-next-line
  }, [filteredData]);

  useEffect(() => {
    setPetitionData(data?.items);
    setTotalRows(data?.totalCount);
    // eslint-disable-next-line
  }, [data?.items]);

  useEffect(() => {
    fetchCaseStage();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (petitionFilter.value === "5" || petitionFilter.value.length === 0) {
      if (petitionStageFilter.case_stage.length !== 0) {
        PetitionStageFilter({
          ...petitionStageFilter,
          ...searchFilter,
          ...state,
        });
      } else if (
        !petitionStageFilter.case_stage.length ||
        searchFilter.search.length !== 0
      ) {
        if (role === "0" || role === "1" || role === "2") {
          loadPetitionlist({ ...state, ...searchFilter });
        } else {
          loadPetitionasignedtome({ ...state, ...searchFilter });
        }
      }
    } else
      PetitionDropWiseData({ ...petitionFilter, ...searchFilter, ...state });

    // eslint-disable-next-line
  }, [
    petitionFilter.value,
    petitionStageFilter.case_stage,
    searchFilter.search,
    state.offset,
  ]);

  return (
    <div
      className="px-4 pt-5 overflow-x-hidden bg-white/75"
      style={{ height: `calc(100vh - 4rem)` }}
    >
      <div className="w-full flex justify-between items-center pb-5">
        <h1 className="text-3xl font-semibold capitalize">petition list</h1>
        <div className="flex items-center flex-wrap gap-7">
          {localStorage.getItem("role") === "2" ||
          (localStorage.getItem("role") === "6" &&
            localStorage.getItem("parent_role") === "2") ? (
            <button
              className="outline-none capitalize flex items-center text-white bg-gradient-to-r from-[#1f9fff] to-[#1f4fdf] rounded-[10px] py-2 px-4"
              onClick={() => {
                navigate("/dashboard/createpetition");
              }}
            >
              <img
                className="mr-2 h-4"
                src="https://img.icons8.com/android/20/ffffff/plus.png"
                alt=""
              />
              new petition
            </button>
          ) : null}
        </div>
      </div>
      <div>
        <DataTable
          fixedHeader
          fixedHeaderScrollHeight="58vh"
          columns={petitionCols}
          data={PetitionData}
          progressPending={isLoading}
          progressComponent={<Loader2 />}
          conditionalRowStyles={conditionalStyle}
          pagination
          paginationServer
          paginationDefaultPage={1}
          paginationResetDefaultPage={resetPagination}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
          paginationTotalRows={TotalRows}
          onChangePage={handlePageChange}
          customStyles={CustomStyles}
          subHeader
          subHeaderComponent={
            <div className="flex justify-between items-center w-full lg:flex-nowrap flex-wrap">
              <div className="flex gap-3 flex-wrap lg:w-half w-full lg:flex-nowrap my-3">
                <div className="w-full lg:w-auto">
                  <label className="text-sm text-gray-600 font-semibold capitalize pb-1 block">
                    petition filter
                  </label>
                  <Dropdown
                    dropdownRef={petitionFilterRef}
                    ClassName="focus:outline-none border w-full py-2 px-4 border-black rounded-md"
                    DefaultValue={"--Select Filter--"}
                    HandleChange={HandleChange}
                  >
                    {PetitionFilterData?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Dropdown>
                </div>
                <div className="w-full lg:w-auto">
                  <label className="text-sm text-gray-600 font-semibold capitalize pb-1 block">
                    case stage filter
                  </label>
                  <Dropdown
                    dropdownRef={caseStageRef}
                    ClassName="focus:outline-none border w-full py-2 px-4 border-black rounded-md"
                    DefaultValue={"--Select case stage--"}
                    HandleChange={HandleChangeCaseStage}
                  >
                    {caseStageData?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Dropdown>
                </div>
              </div>
              <div className="w-full lg:w-auto">
                <label className="text-sm text-gray-600 font-semibold capitalize block pb-1">
                  search
                </label>
                <input
                  ref={searchInput}
                  onChange={(e) => {
                    OptimizeSearch(e.target.value);
                    // caseStageRef.current.value = "";
                    // petitionFilterRef.current.value = "";
                  }}
                  type={"text"}
                  placeholder="Search"
                  className="lg:w-80 w-full border border-black focus:outline-none rounded-md p-2"
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Petition;
