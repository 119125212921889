import React, { useEffect, useState } from "react";
import { MdAddBox } from "react-icons/md";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { nav_item } from "../addons/file";
import { useDispatch, useSelector } from "react-redux";
import { petitionList } from "../../../Redux/Actions/globalUsage";

const EditPetition = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [petition, setpetition] = useState({});
  const { fetchSingleRead, loadStage } = bindActionCreators(
    { ...petitionList },
    dispatch
  );

  const { data } = useSelector((state) => state.stageDataState);

  const loadsinglePetition = () => {
    fetchSingleRead(id, navigate, setpetition);
  };

  useEffect(() => {
    loadsinglePetition();
    loadStage(id);

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="w-full overflow-x-hidden bg-white">
        {/* HEADER SECTION OF EDIT PETITION  */}
        <div className="z-20 sticky lg:top-0 bg-blue-600 text-white font-semibold grid lg:grid-cols-3 md:grid-cols-2 text-center grid-cols-1  gap-2 justify-evenly p-5">
          <div className="space-x-2">
            <span>Petition Stamp Number :</span>
            <span>{petition.stamp_no}</span>
          </div>
          <div className="space-x-2">
            <span>Petition Number :</span>
            <span>{petition.num}</span>
          </div>
          <div className="space-x-2">
            <span>Stage :</span>
            <span>{data[0]?.case_stage_name}</span>
          </div>
        </div>

        {/* NAVIGATION SECTION FOR THE EDIT PETITION  */}
        <div className="z-20 sticky lg:top-[4rem] bg-white grid lg:grid-cols-9 md:grid-cols-5 grid-cols-2 capitalize  font-semibold">
          {nav_item?.map((item, i) => (
            <NavLink
              key={i}
              className={({ isActive }) =>
                isActive
                  ? "bg-blue-500 text-white flex items-center justify-start lg:justify-center gap-1 p-2"
                  : "text-blue-700 hover:bg-blue-500 py-2 border border-blue-500 hover:border-white hover:text-white flex items-center justify-start px-1 lg:justify-center gap-1"
              }
              to={`${item.route}/${id}`}
            >
              <MdAddBox className="text-lg" />
              {item.title}
            </NavLink>
          ))}
        </div>

        {/* CONTENT OF EDIT PETITION  */}
        <div className="relative">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default EditPetition;
