import { ShowErrorAlert } from "../../Components/Notification";
import { POST } from "../../Utils/Helper";
import { ActionTypes } from "../ActionCreater/ActionType";

// Function to fetch Dashboard Counts

const dashboardCountRequest = () => {
  return {
    type: ActionTypes.DASH_COUNT_REQUEST,
  };
};

const dashboardCountSuccess = (data) => {
  return {
    type: ActionTypes.DASH_COUNT_SUCCESS,
    payload: data,
  };
};

// Thunk Function to Get High Dashboard Counts
export const dashboardData = (obj) => {
  return async (dispatch) => {
    dispatch(dashboardCountRequest());
    await POST("dashboard/dashboardCountAndPetition", obj)
      .then((res) => {
        dispatch(dashboardCountSuccess(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};
