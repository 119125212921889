import React, { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { MdModeEdit, MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import CustomStyles, {
  paginationComponentOptions,
} from "../../../Components/datatable";
import { DeleteModal, getSerialNumber } from "../../../Components/Notification";
import { AttachmentAction } from "../../../Redux/Actions/globalUsage";

function Attachment() {
  // Variables
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setcurrentPage] = useState(1);

  // bindActionCreator Method wraps dispatch function with action creators
  const { fetchAttachments, DeleteAttachmentData } = bindActionCreators(
    AttachmentAction,
    dispatch
  );

  const { attachmentData } = useSelector((state) => state.attachmentState);

  // Attachment Columns
  const AttachmentCols = [
    {
      name: "Sr No.",
      selector: (_, i) => getSerialNumber(i, currentPage),
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Remark",
      selector: (row) => row.remark,
    },
    {
      name: "Action",
      cell: (row) => ActionUI(row),
    },
  ];

  // Creating Action UI
  const ActionUI = (row) => {
    return (
      <>
        <div className="flex gap-x-2">
          <Link
            to={`edit/${row.id}`}
            type={"button"}
            className="p-2 bg-[#296aff] rounded-full text-white"
          >
            <MdModeEdit className="w-4 h-4" />
          </Link>
          <div
            className="p-2 bg-[#ff2025] rounded-full text-white cursor-pointer"
            onClick={() => HandleDeleteRequest(row.id)}
          >
            <MdDeleteForever className="w-4 h-4" />
          </div>
        </div>
      </>
    );
  };

  // function to open delete Modal
  const HandleDeleteRequest = (id) => {
    DeleteModal(id, DeleteRequest);
  };

  // function to dispatch delete action
  const DeleteRequest = (id) => {
    DeleteAttachmentData(id);
  };

  // Navigate to Attachment
  const NavigateAttachment = () => {
    navigate("/dashboard/createattachment");
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  // custom hook to run useEffect once
  // useRunOneTime(fetchAttachments, attachmentData);
  useEffect(() => {
    if (attachmentData.length === 0) {
      fetchAttachments();
    }
  }, []);

  return (
    <>
      <div
        className="px-4 pt-5 overflow-hidden bg-white/75"
        style={{ height: `calc(100vh - 4rem)` }}
      >
        <div className="w-full flex justify-between items-center pb-5">
          <h1 className="text-3xl font-semibold capitalize">
            attachments list
          </h1>
          <button
            className="outline-none flex items-center text-white bg-gradient-to-r from-[#1f9fff] to-[#1f4fdf] rounded-[10px] py-2 px-4"
            onClick={NavigateAttachment}
          >
            <img
              className="mr-2 h-4"
              src="https://img.icons8.com/android/20/ffffff/plus.png"
              alt=""
            />
            Create Attachment
          </button>
        </div>
        <div>
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="70vh"
            pagination
            columns={AttachmentCols}
            data={attachmentData}
            customStyles={CustomStyles}
            onChangePage={handlePageChange}
            paginationComponentOptions={paginationComponentOptions}
          />
        </div>
      </div>
    </>
  );
}

export default Attachment;
