import {
  ShowErrorAlert,
  ShowSuccessAlert,
} from "../../Components/Notification";
import { ActionTypes } from "../ActionCreater/ActionType";
import { DELETE, GET, POST, PUT } from "../../Utils/Helper";

const fetchCourtRequest = () => {
  return {
    type: ActionTypes.FETCH_COURT_REQUEST,
  };
};

const fetchCourtList = (courtObj) => {
  return {
    type: ActionTypes.READ_COURT_LIST,
    payload: courtObj,
  };
};

// function to set Button loader to true
const PostRequest = () => {
  return {
    type: ActionTypes.POST_COURT_REQUEST,
  };
};

// function to set Button Loader to false
const PostSuccess = () => {
  return {
    type: ActionTypes.POST_COURT_SUCCESS,
  };
};

// Function to fetch court lists
export const fetchCourtApi = () => {
  return async (dispatch) => {
    dispatch(fetchCourtRequest());
    await GET("superAdmin/courtType")
      .then((res) => {
        dispatch(fetchCourtList(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};

// function to create court list
export const PostCourtApi = (obj, navigate) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await POST("superAdmin/courtType/create", obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchCourtApi());
          ShowSuccessAlert(res.message);
          navigate("/dashboard/courtlist");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => {
        dispatch(PostSuccess());
      });
  };
};

// Function To fetch Single Data from redux Store
export const fetchSingleCourt = (id, setupdateState) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await GET(`superAdmin/courtType/show/${id}`)
      .then((res) => {
        setupdateState(res.data);
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.message)))
      .finally(() => {
        dispatch(PostSuccess());
      });
  };
};

// Function to Update Single Court Data
export const UpdateCourtData = (id, obj, navigate) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await PUT(`superAdmin/courtType/update/${id}`, obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchCourtApi());
          ShowSuccessAlert(res.message);
          navigate("/dashboard/courtlist");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => {
        dispatch(PostSuccess());
      });
  };
};

// function to delete from court list
export const DeleteCourtApi = (id) => {
  return async (dispatch) => {
    await DELETE(`superAdmin/courtType/delete/${id}`)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchCourtApi());
          ShowSuccessAlert(res.message);
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};
