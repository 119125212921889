import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { Notify } from "../../../../Components/Notification";
import { Reports } from "../../../../Redux/Actions/globalUsage";
import { cardData } from "../addon/file";
import Card from "./Card";

const CardComponent = ({ setterFunction }) => {
  const [CardsData, setCardsData] = useState(cardData);

  const dispatch = useDispatch();

  const { FetchCardsReportData } = bindActionCreators(Reports, dispatch);

  const {
    ReportData: {
      data: {
        today_entered_petitions_count,
        today_entered_petitions,
        all_petitions_count,
        all_petitions,
        pending_petitions_count,
        pending_petitions,
        dispose_petitions_count,
        dispose_petitions,
      },
    },
  } = useSelector((state) => state);

  const HandleCardDataClick = (id) => {
    switch (id) {
      case 1:
        if (today_entered_petitions.length !== 0) {
          setterFunction({
            name: "Today Entered Petition",
            petitions_count: today_entered_petitions_count,
            data: today_entered_petitions,
          });
        } else {
          Notify(toast.warn, "No Data Available");
          setterFunction({
            name: "Today Entered Petition",
            petitions_count: today_entered_petitions_count,
            data: [],
          });
        }
        break;

      case 2:
        if (all_petitions.length !== 0) {
          setterFunction({
            name: "All Petition",
            petitions_count: all_petitions_count,
            data: all_petitions,
          });
        } else {
          Notify(toast.warn, "No Data Available");
          setterFunction({
            name: "All Petition",
            petitions_count: all_petitions_count,
            data: [],
          });
        }
        break;

      case 3:
        if (pending_petitions.length !== 0) {
          setterFunction({
            name: "Pending Petition",
            petitions_count: pending_petitions_count,
            data: pending_petitions,
          });
        } else {
          Notify(toast.warn, "No Data Available");
          setterFunction({
            name: "Pending Petition",
            petitions_count: pending_petitions_count,
            data: [],
          });
        }
        break;

      case 4:
        if (dispose_petitions.length !== 0) {
          setterFunction({
            name: "Dispose Petition",
            petitions_count: dispose_petitions_count,
            data: dispose_petitions,
          });
        } else {
          Notify(toast.warn, "No Data Available");
          setterFunction({
            name: "Dispose Petition",
            petitions_count: dispose_petitions_count,
            data: [],
          });
        }
        break;

      default:
        setterFunction([]);
    }
  };

  useEffect(() => {
    const newArr = CardsData.map((item) => {
      if (item.id === 1) {
        return {
          ...item,
          count: today_entered_petitions_count,
        };
      }
      if (item.id === 2) {
        return {
          ...item,
          count: all_petitions_count,
        };
      }
      if (item.id === 3) {
        return {
          ...item,
          count: pending_petitions_count,
        };
      }
      if (item.id === 4) {
        return {
          ...item,
          count: dispose_petitions_count,
        };
      }
      return item;
    });

    const Merged = [...newArr, ...CardsData];

    setCardsData(
      Merged.filter(
        (item, index) => index === Merged.findIndex((i) => i.id === item.id)
      )
    );

    // eslint-disable-next-line
  }, [
    today_entered_petitions_count,
    all_petitions_count,
    pending_petitions_count,
    dispose_petitions_count,
  ]);

  useEffect(() => {
    FetchCardsReportData();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {CardsData?.map((item) => (
        <Card
          HandleClick={() => HandleCardDataClick(item.id)}
          key={item.id}
          {...item}
        />
      ))}
    </>
  );
};

export default CardComponent;
