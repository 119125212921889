import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineDownload, AiOutlinePlus } from "react-icons/ai";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../Components/Button";
import CustomStyles, {
  paginationComponentOptions,
} from "../../../../Components/datatable";
import Dropdown from "../../../../Components/Dropdown";
import Loader2 from "../../../../Components/Loader2";
import Modal from "../../../../Components/modal/Modal";
import {
  AttachmentAction,
  petitionList,
} from "../../../../Redux/Actions/globalUsage";
import {
  DeleteModal,
  getSerialNumber,
  useRunOneTime,
} from "../../../../Components/Notification";
import { attachmentValidation, initialAttachment } from "../../addons/file";
import { useParams } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import ActiveComponents from "../../../../Components/ActiveComponents";
import UserIdComponent from "../../../../Components/UserIdComponent";

const Documents = () => {
  const { REACT_APP_IMAGEURL } = process.env;
  const initialpercent = { percent: 0 };

  const [UploadData, setUploadData] = useState(initialpercent);
  const [isOpen, setisOpen] = useState(false);
  const [formData, setformData] = useState(initialAttachment);
  const [formError, setformError] = useState(initialAttachment);
  const [focus, setfocus] = useState(false);
  const [showUploadBox, setShowUploadBox] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);

  const { id } = useParams();
  const fileRef = useRef();
  const dispatch = useDispatch();

  const { data: singlePetitionData } = useSelector(
    (state) => state.singlePetitionState
  );

  const DocumentCols = [
    {
      name: "Sr no.",
      selector: (_, i) => getSerialNumber(i, currentPage),
    },
    {
      name: "Attached By",
      selector: (row) => row.user_id_name,
    },
    {
      name: "Attachment Type",
      selector: (row) =>
        row.attachment_detail?.attachment_type_detail?.name || "Dispose",
    },
    {
      name: "Remark",
      selector: (row) => row.attachment_detail?.attachment_dsc || "N/A",
    },
    {
      name: "Document",
      selector: (row) => row.attachment_id_name || "N/A",
    },
    {
      name: "Action",
      cell: (row) => ActionUI(row),
    },
  ];

  const conditionalStyle = [
    {
      when: (row) => row?.attachment_detail?.attachment_type === 8,
      style: {
        backgroundColor: "#FFB3B3",
      },
    },
  ];

  // Function to Dispatch Delete Request
  const DeleteRequest = (attachid) => {
    deleteAttachment(attachid, () => {
      loadPetitionAttachment(id);
    });
  };

  // Delete Function to Open delete modal
  const HandleDeleteRequest = (ID) => {
    DeleteModal(ID, DeleteRequest);
  };

  const ActionUI = (row) => {
    return (
      <>
        <div className="flex justify-evenly w-full">
          <a
            href={`${REACT_APP_IMAGEURL}${row?.attachment_id_path || "#"}`}
            download={`${row?.attachment_id_name}.pdf`}
            rel="noreferrer"
            target="_blank"
            className="p-2 bg-blue-500 rounded-full text-white"
          >
            <AiOutlineDownload className="w-4 h-4 text-white" />
          </a>
          <ActiveComponents active={singlePetitionData?.active}>
            <UserIdComponent userId={row.user_id}>
              <button
                title="Delete Petition"
                onClick={() => HandleDeleteRequest(row.attachment_id)}
                className="p-2 bg-red-500  rounded-full text-white"
              >
                <MdDeleteForever className="w-4 h-4" />
              </button>
            </UserIdComponent>
          </ActiveComponents>
        </div>
      </>
    );
  };

  const { fetchAttachments } = bindActionCreators(AttachmentAction, dispatch);
  const { loadPetitionAttachment, deleteAttachment, SimpleAttachment } =
    bindActionCreators(petitionList, dispatch);

  const { attachmentData } = useSelector((state) => state.attachmentState);
  const { data, isLoading: documentloading } = useSelector(
    (state) => state.p_attachment
  );
  const { filterIsloading } = useSelector((state) => state.petitionLoader);

  const ChangeOfInput = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value, petition_id: id });
  };

  const handleFile = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.files[0] });
    setShowUploadBox(false);
  };

  const dropdownFieldProps = {
    HandleChange: ChangeOfInput,
    ClassName:
      "p-[0.69rem] outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full",
  };

  const handleSubmit = () => {
    setformError(attachmentValidation(formData));
    setfocus(true);

    let fd = new FormData();
    fd.append("file", formData.file);
    fd.append("attachment_type", formData.attachment_type);
    fd.append("attachment_dsc", formData.attachment_dsc);
    fd.append("petition_id", formData.petition_id);

    if (Object.keys(attachmentValidation(formData)).length === 0) {
      setShowUploadBox(true);
      SimpleAttachment(
        fd,
        () => {
          loadPetitionAttachment(id);
          setformData(initialAttachment);
          fileRef.current.value = "";
          setShowUploadBox(false);
          setisOpen(false);
        },
        setUploadData,
        setformData,
        initialAttachment
      );

      setfocus(false);
    }
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    if (focus) {
      if (Object.keys(attachmentValidation(formData)).length !== null) {
        setformError(attachmentValidation(formData));
      }
    }
  }, [focus, formData]);

  useRunOneTime(() => {
    fetchAttachments();
  }, attachmentData);

  useEffect(() => {
    loadPetitionAttachment(id);
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} setisOpen={setisOpen}>
        <div className=" w-full h-full space-y-5">
          <h3 className="font-bold text-xl rounded-t-md w-full bg-gray-300 text-[#296aff]  p-3 ">
            Add Petition Documents
          </h3>

          <div className="grid lg:grid-cols-2 grid-cols-1 gap-3 px-5">
            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                Documents Type
                <span className="text-red-600 font-semibold pl-1">*</span>
              </label>
              <Dropdown
                DefaultValue={"--Select Document Type--"}
                SetValue={formData.attachment_type}
                setName={"attachment_type"}
                {...dropdownFieldProps}
              >
                {attachmentData?.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Dropdown>

              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {formError?.attachment_type}
              </p>
            </div>
            <div className="flex flex-col">
              <label className="text-sm text-gray-800 font-medium block pb-1">
                File
                <span className="text-red-600 font-semibold pl-1">*</span>
              </label>
              <input
                type={"file"}
                name="file"
                ref={fileRef}
                accept={"application/pdf"}
                onChange={handleFile}
                className="p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full"
              />
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {formError?.file}
              </p>
            </div>
            {showUploadBox && (
              <div className="w-full col-span-2 bg-blue-400 rounded-md p-2 mt-2">
                <div className="text-white font-semibold flex justify-between">
                  <p>
                    {UploadData.percent === 100
                      ? "Uploaded"
                      : UploadData.percent === 0
                      ? "Click on Submit to start Uploading"
                      : "Uploading..."}
                  </p>
                  <p>{UploadData.percent}%</p>
                </div>
                <div className="w-full h-2 mt-2 bg-white rounded-sm">
                  <div
                    className={`h-full bg-blue-500 rounded-sm`}
                    style={{ width: `${UploadData.percent}%` }}
                  ></div>
                </div>
              </div>
            )}
            <div className="flex flex-col lg:col-span-2">
              <label className=" text-gray-800 font-medium text-sm flex gap-1 pb-1">
                Petition Document Remarks
              </label>
              <textarea
                className="resize-none h-24 p-2 outline-none border border-gray-500 rounded-sm text-gray-500  text-sm w-full"
                placeholder="Enter Remarks"
                value={formData.attachment_dsc}
                onChange={ChangeOfInput}
                name="attachment_dsc"
              ></textarea>
              <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
                {formError.attachment_dsc}
              </p>
            </div>
          </div>

          <div className="flex justify-end px-5 py-3">
            <Button
              type={"button"}
              text={"Submit"}
              isloading={filterIsloading}
              onclick={handleSubmit}
              className={
                "w-full flex justify-center items-center outline-none md:w-[20%] p-2 text-white min-w-fit font-medium rounded-md shadow-md bg-[#298aff] "
              }
            />
          </div>
        </div>
      </Modal>
      <div className="flex flex-col items-start py-2 bg-white/75 min-h-[calc(100vh-10.5rem)]">
        {/* HEADER FOR THE REMARKS */}
        <div className="flex justify-between w-full p-5">
          <h2 className="font-bold text-2xl">Petition Documents</h2>
          <ActiveComponents active={singlePetitionData?.active}>
            <button
              onClick={() => {
                setisOpen(true);
                setformData(initialAttachment);
                setUploadData({ percent: 0 });
                setfocus(false);
                setformError({});
                setShowUploadBox(false);
              }}
              className="outline-none capitalize flex items-center text-white bg-gradient-to-r from-[#1f9fff] to-[#1f4fdf] rounded-[10px] py-2 px-4"
            >
              <AiOutlinePlus className=" text-xl" /> Add Attachment
            </button>
          </ActiveComponents>
        </div>
        <div className="w-full p-5">
          {documentloading ? (
            <div className="flex justify-center items-center ">
              <Loader2 />
            </div>
          ) : (
            <DataTable
              columns={DocumentCols}
              customStyles={CustomStyles}
              paginationComponentOptions={paginationComponentOptions}
              conditionalRowStyles={conditionalStyle}
              data={data}
              pagination
              onChangePage={handlePageChange}
              fixedHeader
              fixedHeaderScrollHeight="42vh"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Documents;
