export const InitialCourtObj = {
  name: "",
  remark: "",
};

export function NullValidate(object) {
  let Error = {};

  if (object.name.length === 0)
    Error.court_name = "Court Name Field is Required";

  if (object.remark.length === 0) Error.remark = "Remark Field is required";

  return Error;
}
