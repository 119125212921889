import React from "react";
import visibleIcon from "../Assets/visible.png";
import { TextModal } from "./Notification";

const ViewMoreUI = ({ data }) => {
  const ExpandData = (data) => {
    TextModal(data);
  };

  return data ? (
    <>
      <div className="flex justify-between items-center">
        <div className="pr-2">
          {data?.length < 40
            ? data?.substring(0, 40)
            : `${data?.substring(0, 40)}...`}
        </div>

        {data?.length < 40 ? null : (
          <div
            className="bg-[#296aff] p-1 rounded-full outline-none cursor-pointer"
            onClick={() => {
              ExpandData(data);
            }}
          >
            <img src={visibleIcon} alt="" />
          </div>
        )}
      </div>
    </>
  ) : (
    "N/A"
  );
};

export default ViewMoreUI;
