export const border = "1px solid #11111150";

export const CustomStyles = {
  headCells: {
    style: {
      padding: "0.5rem",
      fontSize: "1rem",
      whiteSpace: "normal",
      fontWeight: "600",
      color: "#111827",
      justifyContent: "center",
      Text: "center",
      borderTop: border,
      borderLeft: border,
      "&:last-child": {
        borderRight: border,
      },
    },
  },
  rows: {
    style: {
      color: "rgb(31 41 55)",
      fontWeight: "500",
      border: border,
      cursor: "pointer",
      background: "#F7F2F2",
      "&:hover": {
        // background: "#1f2937",
        // color: "red",
      },
    },
  },
  cells: {
    style: {
      justifyContent: "center",
      Text: "center",
      padding: "1rem 0",
      borderRight: border,
      "&:last-child": {
        borderRight: "0",
      },
    },
  },
};
