import React, { useMemo } from "react";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { getRandomColor } from "../../../../Components/Notification";

const BarChart = ({ data }) => {
  const labelColor = useMemo(() => getRandomColor(data.label.length), [data]);

  let chartData = {
    labels: data.label,
    Option: {
      scales: {
        x: {
          ticks: {
            callback: function (value, index, ticks) {
              console.log(value, index, ticks);
            },
          },
        },
      },
    },

    datasets: [
      {
        data: data.data,
        backgroundColor: labelColor,
        borderColor: ["#555"],
        hoverOffset: 10,
        label: ["Petition Status"],
        // barThickness:90
      },
    ],
  };

  return (
    <div className="w-full  h-1/2 px-7">
      <Bar data={chartData} />
    </div>
  );
};

export default BarChart;
