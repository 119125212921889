import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlinePlus } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import Loader2 from "../../../../Components/Loader2";
import { useDispatch, useSelector } from "react-redux";
import { petitionList } from "../../../../Redux/Actions/globalUsage";
import CustomStyles, {
  paginationComponentOptions,
} from "../../../../Components/datatable";
import Modal from "../../../../Components/modal/Modal";
import Button from "../../../../Components/Button";
import { initialRemark, remarkValidation } from "../../addons/file";
import { MdModeEdit } from "react-icons/md";
import { getSerialNumber } from "../../../../Components/Notification";
import ViewMoreUI from "../../../../Components/ViewMoreUI";
import ActiveComponents from "../../../../Components/ActiveComponents";
import UserIdComponent from "../../../../Components/UserIdComponent";

const Remark = () => {
  const [isOpen, setisOpen] = useState(false);
  const [formData, setformData] = useState(initialRemark);
  const [formError, setformError] = useState({});
  const [focus, setfocus] = useState(false);
  const [Id, setId] = useState("");
  const [EditModal, setEditModal] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);

  const { id } = useParams();
  const dispatch = useDispatch();

  const { loadPetitionRemark, createpetitionRemark, updatePetitionRemark } =
    bindActionCreators(petitionList, dispatch);

  const { filterIsloading } = useSelector((state) => state.petitionLoader);

  const { data, isLoading } = useSelector((state) => state.petitionRemark);

  const { data: singlePetitionData } = useSelector(
    (state) => state.singlePetitionState
  );

  //COLUMNS FOR THE REMARKS TABLE
  const column = [
    {
      name: "Sr no.",
      selector: (_, i) => getSerialNumber(i, currentPage),
      sortable: true,
      width: "7rem",
    },
    {
      name: "From ",
      selector: (row) => row.remark_user_detail.user_name,
      sortable: true,
      width: "20rem",
    },
    {
      name: "Remarks",
      selector: (row) => <ViewMoreUI data={row.remark} />,
      sortable: true,
      width: "50rem",
    },
    {
      name: "Action",
      cell: (row) => ActionUI(row),
      // width:'10rem'
    },
  ];

  // FUNCTION TO DISPLAY  EDIT AND DELETE BUTTON IN DATA TABLE
  const ActionUI = (row) => {
    return (
      <>
        <div className="flex gap-x-2">
          <ActiveComponents active={singlePetitionData?.active}>
            <UserIdComponent userId={row.from_user_id}>
              <button
                onClick={() => {
                  setId(row.id);
                  setEditModal(true);
                }}
                type={"button"}
                className="p-2 bg-blue-500 rounded-full text-white"
              >
                <MdModeEdit
                  title="Edit Assigned Petition"
                  className="w-4 h-4"
                />
              </button>
            </UserIdComponent>
          </ActiveComponents>
        </div>
      </>
    );
  };

  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
      petition_id: id,
    });
  };

  const handleSubmit = () => {
    console.log(remarkValidation(formData).length);
    setformError(remarkValidation(formData));
    setfocus(true);

    if (Object.keys(remarkValidation(formData)).length === 0) {
      createpetitionRemark(formData, id, setisOpen);
      setformData(initialRemark);
      setfocus(false);
    }
  };

  const handleEdit = () => {
    setformError(remarkValidation(formData));
    setfocus(true);
    if (Object.keys(remarkValidation(formData)).length === 0) {
      updatePetitionRemark(formData, Id, () => {
        setformData(initialRemark);
        setfocus(false);
        setEditModal(false);
        loadPetitionRemark(id);
      });
    }
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    if (EditModal) {
      let singleData = data.filter((item) => item.id === Id);
      console.log("remark", singleData);
      setformData(singleData[0]);
    } else {
      setformData(initialRemark);
    }
  }, [EditModal]);

  useEffect(() => {
    if (focus) {
      if (Object.keys(remarkValidation(formData)).length !== null)
        setformError(remarkValidation(formData));
    }
  }, [focus, formData]);

  useEffect(() => {
    loadPetitionRemark(id);
  }, []);

  return (
    <>
      {/* CREATE MODAL */}
      <Modal isOpen={isOpen} setisOpen={setisOpen}>
        <div className=" w-full h-full space-y-5">
          <h3 className="font-bold text-xl rounded-t-md w-full bg-gray-300 text-[#296aff]  p-3 ">
            Add New Remarks
          </h3>

          <div className="flex flex-col p-5">
            <label className=" text-gray-800 font-medium text-base flex gap-1 pb-1">
              Petition Remarks
              <span className="text-red-600 font-semibold pl-1">*</span>
            </label>
            <textarea
              className="resize-none h-24 p-2 outline-none border border-gray-500 rounded-sm text-gray-500  text-base w-full"
              placeholder="Enter Remarks"
              value={formData.remark}
              onChange={handleChange}
              name="remark"
            ></textarea>
            <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
              {formError.remark}
            </p>
          </div>

          <div className="flex justify-end px-5 py-3">
            <Button
              type={"button"}
              text={"Submit"}
              isloading={filterIsloading}
              onclick={handleSubmit}
              className={
                "w-full flex justify-center items-center outline-none md:w-[20%] p-2 text-white min-w-fit font-medium rounded-md shadow-md bg-[#298aff] "
              }
            />
          </div>
        </div>
      </Modal>

      {/* UPDATE MODAL */}
      <Modal isOpen={EditModal} setisOpen={setEditModal}>
        <div className=" w-full h-full space-y-5">
          <h3 className="font-bold text-xl rounded-t-md w-full bg-gray-300 text-[#296aff]  p-3 ">
            Update Remarks
          </h3>

          <div className="flex flex-col p-5">
            <label className=" text-gray-800 font-medium text-base flex gap-1 pb-1">
              Petition Remarks
              <span className="text-red-600 font-semibold pl-1">*</span>
            </label>
            <textarea
              className="resize-none h-24 p-2 outline-none border border-gray-500 rounded-sm text-gray-500  text-base w-full"
              placeholder="Enter Remarks"
              value={formData.remark}
              onChange={handleChange}
              name="remark"
            ></textarea>
            <p className=" capitalize  h-6 text-sm text-red-500 font-semibold py-1">
              {formError.remark}
            </p>
          </div>

          <div className="flex justify-end px-5 py-3">
            <Button
              type={"button"}
              text={"Submit"}
              isloading={filterIsloading}
              onclick={handleEdit}
              className={
                "w-full flex justify-center items-center outline-none md:w-[20%] p-2 text-white min-w-fit font-medium rounded-md shadow-md bg-[#298aff] "
              }
            />
          </div>
        </div>
      </Modal>
      <div className="flex flex-col items-start py-2 bg-white/75 min-h-[calc(100vh-10.5rem)]">
        {/* HEADER FOR THE REMARKS */}
        <div className="flex justify-between w-full p-5">
          <h2 className="font-bold text-2xl">Petition Remarks</h2>
          <ActiveComponents active={singlePetitionData?.active}>
            <button
              onClick={() => {
                setisOpen(true);
              }}
              className="outline-none capitalize flex items-center text-white bg-gradient-to-r from-[#1f9fff] to-[#1f4fdf] rounded-[10px] py-2 px-4"
            >
              <AiOutlinePlus className=" text-xl" /> Add Remark
            </button>
          </ActiveComponents>
        </div>
        <div className="w-full p-5">
          {isLoading ? (
            <div className="flex justify-center items-center ">
              <Loader2 />
            </div>
          ) : (
            <DataTable
              columns={column}
              customStyles={CustomStyles}
              paginationComponentOptions={paginationComponentOptions}
              pagination
              data={data}
              fixedHeader
              fixedHeaderScrollHeight="50vh"
              onChangePage={handlePageChange}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Remark;
