import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { MdModeEdit, MdDeleteForever } from "react-icons/md";
import {
  DeleteModal,
  getSerialNumber,
  useRunOneTime,
} from "../../../Components/Notification";
import CustomStyles, {
  paginationComponentOptions,
} from "../../../Components/datatable";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { roleTypeList } from "../../../Redux/Actions/globalUsage";
import { useState } from "react";

function RoleType() {
  // Variables
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setcurrentPage] = useState(1);

  // bindActionCreator method to wrap dispatch method with action creators
  const { fetchRoleType } = bindActionCreators(roleTypeList, dispatch);

  // using useSelector to get array Data from Store
  const { roleData } = useSelector((state) => state.roleState);

  // RoleType Columns
  const RoleTypeCols = [
    {
      name: "Sr No.",
      selector: (_, i) => getSerialNumber(i, currentPage),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Remark",
      selector: (row) => row.remark,
      sortable: true,
    },
    // {
    //   name: "Action",
    //   cell: (row) => ActionUI(row),
    // },
  ];

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  // Action UI
  // const ActionUI = (row) => {
  //   return (
  //     <>
  //       <div className="flex gap-x-2">
  //         <Link
  //           to={`edit/${row.id}`}
  //           type={"button"}
  //           className="p-2 bg-[#296aff] rounded-full text-white"
  //         >
  //           <MdModeEdit className="w-4 h-4" />
  //         </Link>
  //         <div
  //           className="p-2 bg-[#ff2025] rounded-full text-white cursor-pointer"
  //           onClick={() => HandleDeleteRequest(row.id)}
  //         >
  //           <MdDeleteForever className="w-4 h-4" />
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  // const HandleDeleteRequest = (id) => {
  //   DeleteModal(id, DeleteRequest);
  // };

  // const DeleteRequest = (id) => {
  //   DeleteRoleData(id);
  // };

  const createRole = () => {
    navigate("/dashboard/createrole");
  };

  // useRunOneTime(fetchRoleType, roleData);

  useEffect(() => {
    if (roleData.length === 0) {
      fetchRoleType();
    }
  }, []);

  return (
    <>
      <div
        className="px-4 pt-5 overflow-hidden bg-white/75"
        style={{ height: `calc(100vh - 4rem)` }}
      >
        <div className="w-full flex justify-between items-center pb-5">
          <h1 className="text-3xl font-semibold capitalize">role type list</h1>
          {/* <button
            className="outline-none flex items-center text-white bg-gradient-to-r from-[#1f9fff] to-[#1f4fdf] rounded-[10px] py-2 px-4"
            onClick={createRole}
          >
            <img
              className="mr-2 h-4"
              src="https://img.icons8.com/android/20/ffffff/plus.png"
              alt=""
            />
            Create Role
          </button> */}
        </div>
        <div>
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="70vh"
            pagination
            columns={RoleTypeCols}
            data={roleData}
            customStyles={CustomStyles}
            onChangePage={handlePageChange}
            paginationComponentOptions={paginationComponentOptions}
          />
        </div>
      </div>
    </>
  );
}

export default RoleType;
