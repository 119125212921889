import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlinePlus } from "react-icons/ai";
import Button from "../../../../Components/Button";
import CustomStyles, {
  paginationComponentOptions,
} from "../../../../Components/datatable";
import Loader2 from "../../../../Components/Loader2";
import Modal from "../../../../Components/modal/Modal";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  petitionList,
  PetitionRoleAction,
  usersList,
} from "../../../../Redux/Actions/globalUsage";
import { InitialAssignObj, UsersNullValidate } from "../../addons/file";
import { useParams } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import Dropdown from "../../../../Components/Dropdown";
import {
  DeleteModal,
  getSerialNumber,
} from "../../../../Components/Notification";
import ActiveComponents from "../../../../Components/ActiveComponents";
import ProtectedComponent from "../../../../Components/ProtectedComponent";
import { ROLES } from "../../../../Components/addons/roleArray";

const Assign = () => {
  const [formData, setformData] = useState(InitialAssignObj);
  const [formData2, setformData2] = useState(InitialAssignObj);
  const [isOpen, setisOpen] = useState(false);
  const [formError, setformError] = useState({});
  const [focus, setfocus] = useState(false);
  const [data, setdata] = useState([]);
  const [EditModal, setEditModal] = useState(false);
  const [preSelectedValue, setpreSelectedValue] = useState([]);

  const dispatch = useDispatch();
  const multiselect = useRef();
  const { id } = useParams();
  const [currentPage, setcurrentPage] = useState(1);

  //COLUMNS FOR THE REMARKS TABLE
  const column = [
    {
      name: "Sr No.",
      selector: (_, i) => getSerialNumber(i, currentPage),
      sortable: true,
    },
    {
      name: "From",
      selector: (row) => row.from_user_id_name,
      sortable: true,
    },
    {
      name: "To",
      selector: (row) => row.to_user_id_name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => ActionUI(row),
    },
  ];

  // FUNCTION TO DISPLAY  EDIT AND DELETE BUTTON IN DATA TABLE
  const ActionUI = (row) => {
    return (
      <>
        <div className="flex gap-x-2">
          <ActiveComponents active={singlePetitionData?.active}>
            {localStorage.getItem("role") === "2" ||
            localStorage.getItem("id") === row.from_user_id.toString() ? (
              <button
                onClick={() => {
                  // setEditModal(true);
                  // FetchPetitionRoleData();
                  // EmptyAssignedUsers();
                  DeleteModal(row.id, DeleteRequest);
                }}
                type={"button"}
                className="p-2 bg-red-500 rounded-full text-white"
              >
                <AiFillDelete
                  title="Remove Assigned Petition"
                  className="w-4 h-4"
                />
              </button>
            ) : null}
          </ActiveComponents>
        </div>
      </>
    );
  };

  const DeleteRequest = (ID) => {
    DeleteAssignedUser(ID, () => {
      loadQuickAssign(id, setdata);
    });
  };

  const {
    loadQuickAssign,
    FetchPetitionRoleData,
    loadassignedUser,
    AssignUser,
    EmptyAssignedUsers,
    DeleteAssignedUser,
  } = bindActionCreators(
    { ...usersList, ...petitionList, ...PetitionRoleAction },
    dispatch
  );

  const { assigned_users: currently_assigned_users, users: unassigned_user } =
    useSelector((state) => state.PetitionAssignedUserState);

  const {
    petitionRoleState: { data: RoleData },
  } = useSelector((state) => state);

  const { loading } = useSelector((state) => state.quickAssign);

  const { data: singlePetitionData } = useSelector(
    (state) => state.singlePetitionState
  );

  const ChangeOfInput = (e) => {
    const { value } = e.target;
    setformData({ ...formData, role: value, petition_id: id });
    loadassignedUser(id, value);
  };

  const ChangeOfInput2 = (e) => {
    const { value } = e.target;
    setformData2({ ...formData2, role: value, petition_id: id });
    loadassignedUser(id, value);
  };

  const dropdownFieldProps = {
    HandleChange: ChangeOfInput,
    ClassName:
      "p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full",
  };

  const SelectedValues = (_, item) => {
    setformData({
      ...formData,
      assigned_users: [...formData.assigned_users, item.to_user_id],
    });
  };

  const RemovedValues = (list) => {
    setformData({
      ...formData,
      assigned_users: list.map((item) => item.to_user_id),
    });
    setpreSelectedValue(list);
  };

  const SelectedValuesUpdate = (_, item) => {
    setformData2({
      ...formData2,
      assigned_users: [...formData2.assigned_users, item.to_user_id],
    });
  };

  const RemovedValuesUpdate = (list) => {
    setformData2({
      ...formData2,
      assigned_users: list.map((item) => item.to_user_id),
    });
    setpreSelectedValue(list);
  };

  const handleSubmit = () => {
    setformError(UsersNullValidate(formData));
    setfocus(true);
    if (Object.keys(UsersNullValidate(formData)).length === 0) {
      AssignUser(id, formData, setdata, () => {
        // loadQuickAssign(id, setdata);
        setformData(InitialAssignObj);
        multiselect.current.resetSelectedValues();
        setisOpen(false);
      });
      setfocus(false);
    }
  };

  const handleUpdate = () => {
    setformError(UsersNullValidate(formData2));
    setfocus(true);
    if (Object.keys(UsersNullValidate(formData2)).length === 0) {
      // UpdateAssignedUser(id, formData2, setdata, () => {
      //   setEditModal(false);
      //   setformData2(InitialAssignObj);
      // });
      setfocus(false);
    }
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    loadQuickAssign(id, setdata);
  }, []);

  useEffect(() => {
    if (focus) {
      if (Object.keys(UsersNullValidate(formData)).length !== null) {
        setformError(UsersNullValidate(formData));
      }
    }
  }, [focus, formData]);

  // Side Effects for Create Assign
  useEffect(() => {
    if (isOpen) {
      setformData({
        ...formData,
        assigned_users: multiselect.current
          .getSelectedItems()
          .map((item) => item.to_user_id),
      });
    }
  }, [currently_assigned_users, formData]);

  useEffect(() => {
    if (isOpen) setpreSelectedValue(currently_assigned_users);
  }, [formData.role, currently_assigned_users]);

  useEffect(() => {
    setpreSelectedValue([]);
    setformData(InitialAssignObj);
  }, [isOpen]);

  // Side Effects for Update Assign
  useEffect(() => {
    if (EditModal) {
      setformData2({
        ...formData2,
        assigned_users: multiselect.current
          .getSelectedItems()
          .map((item) => item.to_user_id),
      });
    }
  }, [currently_assigned_users, formData]);

  useEffect(() => {
    if (EditModal) setpreSelectedValue(currently_assigned_users);
  }, [formData.role, currently_assigned_users]);

  useEffect(() => {
    setpreSelectedValue([]);
    setformData2(InitialAssignObj);
  }, [EditModal]);

  return (
    <>
      {/* CREATE MODAL */}
      <Modal isOpen={isOpen} setisOpen={setisOpen}>
        <div className=" w-full h-full space-y-5">
          <h3 className="font-bold text-xl rounded-t-md w-full bg-gray-300 text-[#296aff]  p-3">
            Assign User
          </h3>

          <div className="flex flex-col p-5">
            <label className="text-sm text-gray-800 font-medium block pb-1">
              Role Type
              <span className="text-red-600 font-semibold pl-1">*</span>
            </label>
            <Dropdown
              DefaultValue={"--Select Role--"}
              setName={"role"}
              {...dropdownFieldProps}
            >
              {RoleData?.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </Dropdown>
            <p className="h-6 text-sm text-red-500 font-semibold py-1">
              {formError.role}
            </p>
          </div>

          <div className="flex flex-col p-5">
            <label className="text-sm text-gray-800 font-medium block pb-1">
              Users
              <span className="text-red-600 font-semibold pl-1">*</span>
            </label>
            <Multiselect
              options={unassigned_user}
              ref={multiselect}
              onSelect={SelectedValues}
              onRemove={RemovedValues}
              displayValue={"to_user_id_name"}
              selectedValues={preSelectedValue}
              className="w-full border border-gray-500 pb-1 px-2 rounded-sm"
              showArrow={true}
              style={{
                searchBox: {
                  border: "none",
                },
              }}
            />

            <p className="h-6 text-sm text-red-500 font-semibold py-1">
              {formError.assigned_users}
            </p>
          </div>

          <div className="flex justify-end px-5 py-3">
            <Button
              type={"button"}
              text={"Submit"}
              isloading={loading}
              onclick={handleSubmit}
              className={
                "w-full flex justify-center items-center outline-none md:w-[20%] p-2 text-white min-w-fit font-medium rounded-md shadow-md bg-[#298aff] "
              }
            />
          </div>
        </div>
      </Modal>

      {/* UPDATE MODAL */}
      <Modal isOpen={EditModal} setisOpen={setEditModal}>
        <div className=" w-full h-full space-y-5">
          <h3 className="font-bold text-xl rounded-t-md w-full bg-gray-300 text-[#296aff]  p-3 ">
            Assign Update User
          </h3>

          <div className="flex flex-col p-5">
            <label className="text-sm text-gray-800 font-medium block pb-1">
              Role Type
              <span className="text-red-600 font-semibold pl-1">*</span>
            </label>
            <Dropdown
              DefaultValue={"--Select Role--"}
              setName={"role"}
              HandleChange={ChangeOfInput2}
              ClassName="p-2 outline-none border border-gray-500 rounded-sm text-gray-500 text-sm w-full"
            >
              {RoleData?.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </Dropdown>
            <p className="h-6 text-sm text-red-500 font-semibold py-1">
              {formError.role}
            </p>
          </div>

          <div className="flex flex-col p-5">
            <label className="text-sm text-gray-800 font-medium block pb-1">
              Users
              <span className="text-red-600 font-semibold pl-1">*</span>
            </label>
            <Multiselect
              options={unassigned_user}
              ref={multiselect}
              onSelect={SelectedValuesUpdate}
              onRemove={RemovedValuesUpdate}
              displayValue={"to_user_id_name"}
              selectedValues={preSelectedValue}
              className="w-full border border-gray-500 pb-1 px-2 rounded-sm"
              showArrow={true}
              style={{
                searchBox: {
                  border: "none",
                },
              }}
            />
            <p className="h-6 text-sm text-red-500 font-semibold py-1">
              {formError.assigned_users}
            </p>
          </div>

          <div className="flex justify-end px-5 py-3">
            <Button
              type={"button"}
              text={"Submit"}
              isloading={loading}
              onclick={handleUpdate}
              className={
                "w-full flex justify-center items-center outline-none md:w-[20%] p-2 text-white min-w-fit font-medium rounded-md shadow-md bg-[#298aff] "
              }
            />
          </div>
        </div>
      </Modal>

      <div className="flex flex-col items-start py-2 bg-white/75 min-h-[calc(100vh-10.5rem)]">
        {/* HEADER FOR THE REMARKS */}
        <div className="flex justify-between w-full p-5">
          <h2 className="font-bold text-2xl">Assigned User List</h2>
          <ActiveComponents active={singlePetitionData?.active}>
            <ProtectedComponent
              allowedRoles={[
                ROLES.admin,
                ROLES.super_admin,
                ROLES.dept,
                ROLES.legal_dept,
                ROLES.lawyer,
                ROLES.ward,
              ]}
              parent_role={ROLES.parent_role}
            >
              <button
                onClick={() => {
                  setisOpen(true);
                  FetchPetitionRoleData();
                  EmptyAssignedUsers();
                }}
                className="outline-none capitalize flex items-center text-white bg-gradient-to-r from-[#1f9fff] to-[#1f4fdf] rounded-[10px] py-2 px-4"
              >
                <AiOutlinePlus className=" text-xl" /> Assign
              </button>
            </ProtectedComponent>
          </ActiveComponents>
        </div>
        <div className="w-full p-5">
          {loading ? (
            <div className="flex justify-center items-center ">
              <Loader2 />
            </div>
          ) : (
            <DataTable
              columns={column}
              customStyles={CustomStyles}
              paginationComponentOptions={paginationComponentOptions}
              data={data}
              pagination
              // paginationServer
              onChangePage={handlePageChange}
              fixedHeader
              fixedHeaderScrollHeight="50vh"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Assign;
