import React from "react";

export default function Denied() {
  return (
    <div>
      <div>
        <div style={{ height: "80vh", display: "flex", padding: "0px 16px" }}>
          <div className="  w-full mx-auto flex justify-center items-center">
            <div role="alert">
              <div className="bg-red-500 text-white text-3xl font-bold rounded-t px-4 py-2">
                Attention
              </div>
              <div className="border border-t-0 border-red-400 text-xl rounded-b bg-red-100 px-4 py-3 text-red-700">
                <p>You don't have the permission to access.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
