import React from "react";
import DataTable from "react-data-table-component";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdModeEdit, MdDeleteForever } from "react-icons/md";
import { useSelector } from "react-redux";
import CustomStyles from "../../../Components/datatable";

function DepartmentList() {
  let navigate = useNavigate();
  let { pathname } = useLocation();
  const cases = useSelector((state) => state.cases);

  const departmentCols = [
    {
      name: "Id",
      selector: (_, i) => ++i,
      sortable: true,
    },
    {
      name: "Department Name",
      selector: (row) => row.case_name,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => ActionUI(row),
    },
  ];

  const ActionUI = (row) => {
    return (
      <>
        <div className="flex gap-x-2">
          <Link
            to={`edit/${row.id}`}
            type={"button"}
            className="p-2 bg-[#296aff] rounded-full text-white"
          >
            <MdModeEdit className="w-4 h-4" />
          </Link>
          <div className="p-2 bg-[#ff2025] rounded-full text-white cursor-pointer">
            <MdDeleteForever className="w-4 h-4" />
          </div>
        </div>
      </>
    );
  };

  const createDepartment = () => {
    navigate("/dashboard/createdepartment");
    localStorage.setItem("path_name", pathname);
  };

  return (
    <>
      <div
        className="px-4 pt-5 overflow-hidden bg-white/75"
        style={{ height: `calc(100vh - 4rem)` }}
      >
        <div className="w-full flex justify-between items-center pb-5">
          <h1 className="text-3xl font-semibold capitalize">
            Departments list
          </h1>
          <button
            className="outline-none flex items-center text-white bg-gradient-to-r from-[#1f9fff] to-[#1f4fdf] rounded-[10px] py-2 px-4"
            onClick={createDepartment}
          >
            <img
              className="mr-2 h-4"
              src="https://img.icons8.com/android/20/ffffff/plus.png"
              alt=""
            />
            Create Department
          </button>
        </div>
        <div>
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="75vh"
            columns={departmentCols}
            // data={cases}
            customStyles={CustomStyles}
          />
        </div>
      </div>
    </>
  );
}

export default DepartmentList;
