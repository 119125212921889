import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { loginAction, NotificationData } from "../Redux/Actions/globalUsage";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaAngleRight } from "react-icons/fa";
import { IoLogOutOutline } from "react-icons/io5";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ForSubItems, items, NotAdminItems } from "./addons/navitems";
import { IoNotificationsSharp } from "react-icons/io5";
import { useCookies } from "react-cookie";

function Navbar() {
  const [navItem, setnavItem] = useState([]);
  const [Class, setClass] = useState(0);
  const [LogoutModal, setLogoutModal] = useState(false);
  const [currentNavItem, setcurrentNavItem] = useState(null);
  const [notifyNo, setnotifyNo] = useState(0);
  const [_, __, removeCookie] = useCookies();
  const Element = useRef();

  const displayName = localStorage.getItem("display_name") || "Admin";

  const userId = localStorage.getItem("id");

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");

  const { LogoutRequest } = bindActionCreators(loginAction, dispatch);
  const { notifyCount } = bindActionCreators(NotificationData, dispatch);

  const { data } = useSelector((state) => state.notifyData);

  const LogoutClick = () => {
    LogoutRequest(localStorage.getItem("token"), navigate, removeCookie);
  };

  const AddClass = (i, element) => {
    setcurrentNavItem(element.target.parentElement);

    if (Class === i) {
      setClass(0);
    } else {
      setClass(i);
    }
  };

  const ShowLogoutModal = () => {
    if (LogoutModal) setLogoutModal(false);
    else setLogoutModal(true);
  };

  const HideMenuBar = () => {
    Element.current.classList.toggle("hide");
  };

  useEffect(() => {
    let lists = document.querySelectorAll("li");

    for (let i = 0; i < lists.length; i++) {
      lists[i].onclick = function () {
        let j = 0;
        while (j < lists.length) {
          lists[j++].className = "px-3 py-1 rounded-sm relative capitalize";
        }
        lists[i].className =
          "px-3 py-1 rounded-sm relative capitalize customActive";
      };
    }
  });

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (e.target.parentElement !== currentNavItem) {
        setClass(0);
      }
    };

    if (currentNavItem !== null) {
      document.addEventListener("click", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [currentNavItem]);

  useEffect(() => {
    if (role === "0" || role === "1") setnavItem(items);
    else if (role === "6") setnavItem(ForSubItems);
    else setnavItem(NotAdminItems);
  }, [role]);

  useEffect(() => {
    if (userId !== (null || undefined)) {
      notifyCount(setnotifyNo);
    }

    // eslint-disable-next-line
  }, [notifyNo, data]);

  return (
    <div className="h-[4rem] flex items-center border-b border-gray-400 bg-[#323B3D] sticky top-0">
      <div className="flex justify-between items-center w-full mr-2">
        {/* <img src="" alt="" /> */}
        <h1 className="font-semibold tracking-[5px] text-xl text-white pl-5 isolate z-10">
          VVMC
        </h1>
        <div onClick={HideMenuBar}>
          <GiHamburgerMenu className="w-10 h-6 lg:hidden cursor-pointer outline-none border-0 text-white" />
        </div>
      </div>
      <ul
        ref={Element}
        className={`transition duration-200 translate-x-[-100%] lg:translate-x-0  lg:flex lg:justify-end lg:gap-1 w-full mr-0 absolute top-[4rem] lg:top-3 lg:bg-transparent bg-white left-0 z-10 lg:z-0 lg:text-white font-medium  text-center NAV__BAR`}
      >
        <li className="px-3 py-1 rounded-sm relative capitalize">
          <NavLink
            to={"dashboard/homepage"}
            className={({ isActive }) =>
              isActive
                ? "bg-[#396aff] px-3 lg:py-1 py-4 rounded-sm cursor-pointer flex justify-center items-center gap-1"
                : "hover:bg-[#396aff] px-3 lg:py-1 py-4 rounded-sm cursor-pointer flex justify-center items-center gap-1"
            }
          >
            dashboard
          </NavLink>
        </li>
        {navItem?.map((item, i) => (
          <li
            key={item.id}
            className="px-3 py-1 rounded-sm relative capitalize"
            onClick={(e) => {
              AddClass(i + 1, e);
            }}
          >
            <p
              className={
                Class === i + 1
                  ? "bg-[#396aff] px-3 lg:py-1 py-4 rounded-sm flex justify-center items-center cursor-pointer"
                  : "hover:bg-[#396aff] px-3 lg:py-1 py-4 rounded-sm flex justify-center items-center cursor-pointer"
              }
            >
              <span className="px-1">{item.title}</span>
              <FaAngleRight
                className={`${
                  Class === i + 1
                    ? "text-sm rotate-90 transition-all duration-200 ease-in-out"
                    : "h-full text-sm rotate-0 transition-all duration-200 ease-in-out"
                }`}
              />
            </p>
            {Class === i + 1 && (
              <div className="absolute top-16 w-[94%] lg:w-fit lg:top-10 shadow-xl max-h-96  bg-white rounded-md overflow-x-hidden text-gray-700 z-10">
                <div className="flex flex-col text-left overflow-x-hidden ">
                  {item.subitem.map((items, index) => (
                    <>
                      <Link
                        key={items.id}
                        to={`${items.routes}`}
                        onClick={() => {
                          window.innerWidth < 768 && HideMenuBar();
                        }}
                        className="hover:bg-blue-500 hover:text-white even:border-b even:border-gray-300 last:border-b-0"
                      >
                        <p
                          className="w-full whitespace-nowrap px-4 py-1 last:border-b-0 border-b border-gray-600"
                          key={index}
                        >
                          {items.name}
                        </p>
                      </Link>
                    </>
                  ))}
                </div>
              </div>
            )}
          </li>
        ))}
        <li className="px-3 py-1 rounded-md relative capitalize">
          <Link
            to={"/dashboard/notificationCounts"}
            className="hover:bg-[#396aff] w-full h-full cursor-pointer"
          >
            <div className="w-full lg:w-auto lg:h-full flex justify-center items-center py-3 lg:py-0">
              <IoNotificationsSharp className="text-xl text-gray-700 lg:text-white w-full" />
            </div>
            <div
              className={`${
                notifyNo > 0 ? "animate-bounce" : ""
              } absolute top-sm left-half lg:top-0 lg:right-0 bg-blue-500 rounded-full h-5 w-5 min-w-fit overflow-hidden flex justify-center items-center`}
            >
              <span className="text-sm font-bold text-white">
                {notifyNo || 0}
              </span>
            </div>
          </Link>
        </li>
        <li className="px-3 py-1 rounded-md relative capitalize">
          <p
            className="hover:bg-[#396aff] px-3 lg:py-1 py-4 rounded-sm cursor-pointer flex justify-center items-center gap-1"
            onClick={ShowLogoutModal}
          >
            {displayName}
            <IoLogOutOutline />
          </p>
          {LogoutModal && (
            <div
              className="absolute lg:top-10 top-full w-[94%] lg:w-fit shadow-lg cursor-pointer group text-gray-500 hover:text-white bg-white rounded-md px-2 py-1 flex items-center hover:bg-blue-500"
              onClick={LogoutClick}
            >
              <IoLogOutOutline />
              <span>Logout</span>
            </div>
          )}
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
