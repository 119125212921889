import { ActionTypes } from "../ActionCreater/ActionType";
import { DELETE, GET, POST, PUT } from "../../Utils/Helper";
import {
  ShowErrorAlert,
  ShowSuccessAlert,
} from "../../Components/Notification";

// function to make fetching request
const fetchRequest = () => {
  return {
    type: ActionTypes.FETCH_REGION_REQUEST,
  };
};

// function to check if request has been successful and send payload
const fetchSuccess = (data) => {
  return {
    type: ActionTypes.READ_REGION_LIST,
    payload: data,
  };
};

// function to set Button Loader to true
const PostRequest = () => {
  return {
    type: ActionTypes.POST_REGION_REQUEST,
  };
};

// function to set Button Loader to false
const PostSuccess = () => {
  return {
    type: ActionTypes.POST_REGION_SUCCESS,
  };
};

// Thunk function to call the api and dispatch the payload
export const fetchRegionData = () => {
  return async (dispatch) => {
    dispatch(fetchRequest());
    await GET("superAdmin/region")
      .then((res) => {
        dispatch(fetchSuccess(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};

// Thunk function to create new region
export const PostRegion = (obj, navigate) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await POST("superAdmin/region/create", obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchRegionData());
          ShowSuccessAlert(res.message);
          navigate("/dashboard/regionlist");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => {
        dispatch(PostSuccess());
      });
  };
};

// Thunk function to SingleRead Region
export const SingleReadRegion = (id, updateMethod) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await GET(`superAdmin/region/show/${id}`)
      .then((res) => {
        updateMethod(res.data);
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.message)))
      .finally(() => {
        dispatch(PostSuccess());
      });
  };
};

// Thunk function to update region
export const UpdateRegion = (id, obj, navigate) => {
  return async (dispatch) => {
    dispatch(PostRequest());
    await PUT(`superAdmin/region/update/${id}`, obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchRegionData());
          ShowSuccessAlert(res.message);
          navigate("/dashboard/regionlist");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.message)))
      .finally(() => {
        dispatch(PostSuccess());
      });
  };
};

// Thunk function to delete region
export const DeleteRegion = (id) => {
  return async (dispatch) => {
    await DELETE(`superAdmin/region/delete/${id}`)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchRegionData());
          ShowSuccessAlert(res.message);
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};
