import { ShowErrorAlert } from "../../Components/Notification";
import { POST } from "../../Utils/Helper";
import { ActionTypes } from "../ActionCreater/ActionType";

// Common Function to fetch and load data onto these functions
const DropRequest = () => {
  return {
    type: ActionTypes.DROP_DATA_REQUEST,
  };
};

const DropDataSuccess = (data) => {
  return {
    type: ActionTypes.DROP_DATA_SUCCESS,
    payload: data,
  };
};

// Thunk function to Fetch DropData
export const PetitionDropWiseData = (obj) => {
  return async (dispatch) => {
    dispatch(DropRequest());
    await POST("legalDepartment/petition/filterDropdown", obj)
      .then((res) => {
        dispatch(DropDataSuccess(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};

// Thunk function to Fetch DropData
export const PetitionStageFilter = (obj) => {
  return async (dispatch) => {
    dispatch(DropRequest());
    await POST("legalDepartment/petition/filterCaseStage", obj)
      .then((res) => {
        dispatch(DropDataSuccess(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};
