export const initial = {
  from_date: "",
  to_date: "",
};

export const quickAssignObj = {
  petition_id: "",
  assigned_users: [],
};

export function NullValidate(object) {
  let Error = {};

  if (object.petition_id.length === 0)
    Error.petition_id = "please select petition";

  if (object.assigned_users.length === 0)
    Error.assigned_users = "please select a user";

  return Error;
}

//OBJECT FOR THE SETTING THE DATA ON TABLE ACCORDING TO CARDS ON DASHBOARD PAGE
export const elements = {
  high_critical: "high critical",
  high_hearing: "high hearing",
  low_critical: "low critical",
  low_hearing: "low hearing",
  work_petition: "work petition",
  pending_petition: "pending petition",
  interim_close: "interim close",
  contempt_due: "contempt due",
  date_filter: "date filter",
  today_petition: "today petition",
};
