import { ActionTypes } from "../ActionCreater/ActionType";
import { POST } from "../../Utils/Helper";
import { ShowErrorAlert } from "../../Components/Notification";

// Function To fetch Data from Search
const SearchRequest = () => {
  return {
    type: ActionTypes.SEARCH_ACTION_REQUEST,
  };
};

const SearchFilter = (data) => {
  return {
    type: ActionTypes.SEARCH_ACTION_SUCCESS,
    payload: data,
  };
};

export const SearchByKey = (obj) => {
  return async (dispatch) => {
    dispatch(SearchRequest());
    await POST("legalDepartment/petition/searchPetition", obj)
      .then((res) => {
        dispatch(SearchFilter(res.data));
      })
      .catch((err) => ShowErrorAlert(err.response.data.message));
  };
};
