import { ActionTypes } from "../ActionCreater/ActionType";

const InitialReportObj = {
  isloading: false,
  data: {},
  filteredData: [],
  chartsApiData: null,
  FilterInputData: [],
};

export const ReportReducer = (state = InitialReportObj, { type, payload }) => {
  switch (type) {
    case ActionTypes.REPORT_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.REPORT_SUCCESS:
      return {
        ...state,
        isloading: false,
        data: payload,
      };

    case ActionTypes.FILTER_REPORT_REQUEST:
      return {
        ...state,
        isloading: true,
      };

    case ActionTypes.FILTER_REPORT_SUCCESS:
      return {
        ...state,
        isloading: false,
        filteredData: payload,
      };
    case ActionTypes.CHART_REPORT_DATA:
      return {
        ...state,
        chartsApiData: payload,
      };

    case ActionTypes.INPUT_FILTER_DATA:
      return {
        ...state,
        FilterInputData: payload,
      };

    default:
      return state;
  }
};
