import {
  ShowErrorAlert,
  ShowSuccessAlert,
} from "../../Components/Notification";
import { DELETE, GET, POST, PUT } from "../../Utils/Helper";
import { ActionTypes } from "../ActionCreater/ActionType";

const fetchCheckLists = () => {
  return {
    type: ActionTypes.MASTER_CHECKLIST_REQUEST,
  };
};

const fetchCheckListRes = (data) => {
  return {
    type: ActionTypes.MASTER_CHECKLIST_RESPONSE,
    payload: data,
  };
};

const loaderRequest = () => {
  return {
    type: ActionTypes.MASTER_CHECKLIST_LOADER,
  };
};

const loaderSuccess = () => {
  return {
    type: ActionTypes.MASTER_CHECKLIST_LOADER_SUCCESS,
  };
};

// Thunk Functions For Crud of CheckList On Masters
export const FetchCheckListData = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchCheckLists());
      const response = await GET("superAdmin/checklist");

      if (response) {
        dispatch(fetchCheckListRes(response.data));
      }
    } catch (error) {
      ShowErrorAlert(JSON.stringify(error.response.data.message));
    }
  };
};

export const CreateCheckList = (obj, next) => {
  return async (dispatch) => {
    try {
      dispatch(loaderRequest());
      const response = await POST("superAdmin/checklist/create", obj);

      if (response.statusCode === 200) {
        ShowSuccessAlert(response.message);
        next();
      }
    } catch (error) {
      ShowErrorAlert(JSON.stringify(error.response.data.errors));
    } finally {
      dispatch(loaderSuccess());
    }
  };
};

export const SingleReadCheckList = (Id, next) => {
  return async (dispatch) => {
    try {
      dispatch(loaderRequest());

      const response = await GET(`superAdmin/checklist/show/${Id}`);

      if (response.statusCode === 200) {
        next(response.data);
      }
    } catch (error) {
      ShowErrorAlert(JSON.stringify(error.response.data.message));
    } finally {
      dispatch(loaderSuccess());
    }
  };
};

export const UpdateCheckListData = (Id, obj, next) => {
  return async (dispatch) => {
    try {
      dispatch(loaderRequest());

      const response = await PUT(`superAdmin/checklist/update/${Id}`, obj);

      if (response.statusCode === 200) {
        ShowSuccessAlert(response.message);
        next();
      }
    } catch (error) {
      ShowErrorAlert(JSON.stringify(error.response.data.errors));
    }
  };
};

export const DeleteCheckList = (Id, next) => {
  return async (dispatch) => {
    try {
      dispatch(loaderRequest());

      const response = await DELETE(`superAdmin/checklist/delete/${Id}`);

      if (response.statusCode === 200) {
        ShowSuccessAlert(response.message);
        next();
      }
    } catch (error) {
      ShowErrorAlert(JSON.stringify(error.response.data.message));
    } finally {
      dispatch(loaderSuccess());
    }
  };
};
