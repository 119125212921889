import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { DeleteModal, getSerialNumber } from "../../../Components/Notification";
import { MdModeEdit, MdDeleteForever } from "react-icons/md";
import CustomStyles, {
  paginationComponentOptions,
} from "../../../Components/datatable";
import { PetitionTypeAction } from "../../../Redux/Actions/globalUsage";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useState } from "react";

function PetitionType() {
  // Variables
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setcurrentPage] = useState(1);

  // Bind Action Creator Method for wrapping dispatch method with action creators
  const { DeletePetitionType, fetchPetitionType } = bindActionCreators(
    PetitionTypeAction,
    dispatch
  );

  const { petitionTypeData } = useSelector((state) => state.typeState);

  // Columns
  const PetitionTypeCols = [
    {
      name: "Sr No.",
      selector: (_, i) => getSerialNumber(i, currentPage),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Remark",
      selector: (row) => row.remark,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => ActionUI(row),
    },
  ];

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  const ActionUI = (row) => {
    return (
      <>
        <div className="flex gap-x-2">
          <Link
            to={`edit/${row.id}`}
            type={"button"}
            className="p-2 bg-[#296aff] rounded-full text-white"
          >
            <MdModeEdit className="w-4 h-4" />
          </Link>
          <div
            className="p-2 bg-[#ff2025] rounded-full text-white cursor-pointer"
            onClick={() => HandleDeleteRequest(row.id)}
          >
            <MdDeleteForever className="w-4 h-4" />
          </div>
        </div>
      </>
    );
  };

  // function to Open DeleteModal
  const HandleDeleteRequest = (id) => {
    DeleteModal(id, DeleteRequest);
  };

  // function to Dispatch Delete Action
  const DeleteRequest = (id) => {
    DeletePetitionType(id);
  };

  const CreatePetitionType = () => {
    navigate("/dashboard/petitioncreate");
  };

  // useRunOneTime(fetchPetitionType, petitionTypeData);

  useEffect(() => {
    if (petitionTypeData.length === 0) {
      fetchPetitionType();
    }
  }, []);

  return (
    <>
      <div
        className="px-4 py-5 overflow-x-hidden bg-white/75"
        style={{ height: `calc(100vh - 4rem)` }}
      >
        <div className="w-full flex justify-between items-center pb-5">
          <h1 className="text-3xl font-semibold capitalize">
            petition type list
          </h1>
          <button
            className="outline-none flex items-center text-white bg-gradient-to-r from-[#1f9fff] to-[#1f4fdf] rounded-[10px] py-2 px-4"
            onClick={CreatePetitionType}
          >
            <img
              className="mr-2 h-4"
              src="https://img.icons8.com/android/20/ffffff/plus.png"
              alt=""
            />
            Create Petition Type
          </button>
        </div>
        <div className="bg-white">
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="70vh"
            pagination
            columns={PetitionTypeCols}
            data={petitionTypeData}
            customStyles={CustomStyles}
            onChangePage={handlePageChange}
            paginationComponentOptions={paginationComponentOptions}
          />
        </div>
      </div>
    </>
  );
}

export default PetitionType;
