export const CheckListObj = {
  name: "",
};

export function NullValidate(obj) {
  let Error = {};

  if (obj.name.length === 0) Error.name = "Name Field is Required";

  return Error;
}
