import { ActionTypes } from "../ActionCreater/ActionType";
import { GET, POST, DELETE, PUT } from "../../Utils/Helper";
import {
  ShowErrorAlert,
  ShowSuccessAlert,
} from "../../Components/Notification";

// function to send fetch Request
const fetchDateTypeRequest = () => {
  return {
    type: ActionTypes.DATE_TYPE_REQUEST,
  };
};

// function to pass the data after success
const fetchDateTypeSuccess = (data) => {
  return {
    type: ActionTypes.DATE_TYPE_SUCCESS,
    payload: data,
  };
};

// function to set Btn Loader to true
const SetBtnLoader = () => {
  return {
    type: ActionTypes.DATE_TYPE_LOADER_REQUEST,
  };
};

// function to set Btn loader to false
const UpdateBtnLoader = () => {
  return {
    type: ActionTypes.DATE_TYPE_LOADER_SUCCESS,
  };
};

// Thunk function to fetch DateType Data
export const fetchDateTypeData = () => {
  return async (dispatch) => {
    dispatch(fetchDateTypeRequest());
    await GET("superAdmin/dateType")
      .then((res) => {
        dispatch(fetchDateTypeSuccess(res.data));
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};

// function to create date type
export const PostDateType = (obj, navigate) => {
  return async (dispatch) => {
    dispatch(SetBtnLoader());
    await POST("superAdmin/dateType/create", obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchDateTypeData());
          ShowSuccessAlert(res.message);
          navigate("/dashboard/datetype");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => {
        dispatch(UpdateBtnLoader());
      });
  };
};

// function to single read date type Data
export const SingleReadDateType = (id, updateMethod) => {
  return async (dispatch) => {
    dispatch(SetBtnLoader());
    await GET(`superAdmin/dateType/show/${id}`)
      .then((res) => {
        updateMethod(res.data);
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.message)))
      .finally(() => {
        dispatch(UpdateBtnLoader());
      });
  };
};

// function to Update Date type Data
export const UpdateDateType = (id, obj, navigate) => {
  return async (dispatch) => {
    dispatch(SetBtnLoader());
    await PUT(`superAdmin/dateType/update/${id}`, obj)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchDateTypeData());
          ShowSuccessAlert(res.message);
          navigate("/dashboard/datetype");
        }
      })
      .catch((err) => ShowErrorAlert(JSON.stringify(err.response.data.errors)))
      .finally(() => {
        dispatch(UpdateBtnLoader());
      });
  };
};

// function to Delete Date Type Data
export const DeleteDateType = (id) => {
  return async (dispatch) => {
    await DELETE(`superAdmin/dateType/delete/${id}`)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(fetchDateTypeData());
          ShowSuccessAlert(res.message);
        }
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err.response.data.message))
      );
  };
};
