import { ActionTypes } from "../ActionCreater/ActionType";
import { POST } from "../../Utils/Helper";
import {
  ShowErrorAlert,
  ShowSuccessAlert,
} from "../../Components/Notification";
import axios from "axios";

const { REACT_APP_BASEURL } = process.env;

const fetchLoaderRequest = () => {
  return {
    type: ActionTypes.FORGOT_PASS_LOADER_REQUEST,
  };
};

const fetchLoaderSuccess = () => {
  return {
    type: ActionTypes.FORGOT_PASS_LOADER_SUCCESS,
  };
};

// function to send otp
export const sendEmail = (obj, updateMethod) => {
  return async (dispatch) => {
    dispatch(fetchLoaderRequest());
    await axios
      .post(`${REACT_APP_BASEURL}mail/forgotPassword`, obj)
      // await POST(`mail/forgotPassword`, obj)
      .then((res) => {
        updateMethod(1);
        ShowSuccessAlert(res?.data?.message);
        console.log(res);
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      )
      .finally(() => dispatch(fetchLoaderSuccess()));
  };
};

// function to send otp
export const verifyOTP = (obj, updateMethod) => {
  return async (dispatch) => {
    dispatch(fetchLoaderRequest());
    await axios
      .post(`${REACT_APP_BASEURL}mail/verifyOtp`, obj)
      // await POST(`mail/verifyOtp`, obj)
      .then((res) => {
        ShowSuccessAlert(res?.message);
        updateMethod(2);
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      )
      .finally(() => dispatch(fetchLoaderSuccess()));
  };
};

// function to ResetPassword
export const ResetPassword = (obj, updateMethod) => {
  return async (dispatch) => {
    dispatch(fetchLoaderRequest());
    await axios
      .post(`${REACT_APP_BASEURL}mail/updatePassword`, obj)
      // await POST(`/mail/updatePassword`, obj)
      .then((res) => {
        ShowSuccessAlert(res?.message);
        updateMethod("/");
      })
      .catch((err) =>
        ShowErrorAlert(JSON.stringify(err?.response?.data?.message))
      )
      .finally(() => dispatch(fetchLoaderSuccess()));
  };
};
